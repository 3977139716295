import React, {useState} from "react";
import {useSelector} from "react-redux";
// UI
import {Typography, SvgIcon, Collapse, Paper, Box} from "@material-ui/core";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {ReactComponent as PenIcon} from "assets/icons/pen-tool.svg";
import useExperienceActionsStyles from "styles/useExperienceActionsStyles";
// Custom
import PreviewBPPanel from "../PreviewBPPanel";
import CustomDialog from "core/dialogs/CustomDialog";
import PrimaryButton from "core/buttons/PrimaryButton";
import EditIconButton from "core/buttons/EditIconButton";
import FilledTextField from "core/inputs/FilledTextField";
import DeleteIconButton from "core/buttons/DeleteIconButton";
import LanguageSelector from "components/MultiOption/LanguageSelector";
// Utilities
import {contentTypeEnum} from "configuration/enums";
import clsx from "clsx";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  dataContainer: {
    backgroundColor: "#FBFBFC",
    border: "1px solid #E6E6E6",
    borderRadius: 10,
    padding: theme.spacing(3),
    "& .MuiAccordion-root": {backgroundColor: "transparent"},
    "&.noData": {
      backgroundColor: "transparent",
      border: "none",
      padding: 0,
    },
    "&.mt": {marginTop: theme.spacing(2)},
  },
  signIcon: {
    color: "#43535F",
    marginRight: theme.spacing(3),
  },
  cancelBtn: {
    color: "#A8A8A8",
    fontSize: 14,
    "&.-right": {},
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    "&.start": {justifyContent: "flex-start"},
    "&.gap": {gap: theme.spacing(2)},
  },
  icon: {
    width: 16,
    height: 16,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(2),
    gap: theme.spacing(3),
    "& .expand-btn": {margin: theme.spacing(2, 0)},
  },
}));

export default function AgreementPanel({
  mounted,
  agreement,
  editData,
  disableEdit,
  selectedGroup,
  onSave = () => {},
  getUpdatedContent = () => {},
}) {
  const classes = useStyles();
  const theme = useTheme();
  const experienceStyles = useExperienceActionsStyles();
  const currAgreement = React.useRef([]);
  const houses = useSelector((state) => state.defaultReducer.house_data_dict);
  const [langs, setLangs] = useState(["en"]);
  const [data, setAgreement] = useState(false);
  const [agreementOpen, setAgreementOpen] = useState(false);
  const [selectedLang, setSelectedLang] = useState("en");
  const AIListingData = houses[selectedGroup?.connected_to?.ids?.[0]];
  const [isTitleContentValid, setIsTitleContentValid] = React.useState(true);
  const [isContentValid, setIsContentValid] = React.useState(true);
  const [previewData, setPreviewData] = useState(null);
  let listingId = selectedGroup.connected_to.ids[0];

  React.useEffect(() => {
    if (!data) {
      return;
    }
    let newData = Object.assign({}, getUpdatedContent(true));
    newData.agreement = [getUpdatedAgreement(data)];
    if (!newData) {
      return;
    }
    setPreviewData((prev) => newData);
  }, [data]);

  React.useEffect(() => {
    if (mounted.current) {
      return;
    }
    currAgreement.current = agreement || [];
    resetAgreement();
    mounted.current = true;
  }, [agreement]);

  function resetAgreement() {
    const dataData = currAgreement.current;
    if (dataData.length > 0) {
      let newAgreement = {name: {}, message: {}};
      let newLangs = [];
      _.each(dataData[0].name, (s) => {
        newAgreement.name[s.language] = s.value;
        newLangs.push(s.language);
      });
      _.each(dataData[0].message, (h) => {
        newAgreement.message[h.language] = h.value;
      });
      setLangs(newLangs);
      setSelectedLang((prev) => newLangs[0]);
      setAgreement(newAgreement);
    } else {
      currAgreement.current = [];
      setAgreement(null);
      setSelectedLang((prev) => "en");
    }
  }

  const getUpdatedAgreement = (value) => {
    let newData = Object.assign({}, data);
    newData.name = Object.entries(value.name || {}).map((c) => ({
      language: c[0],
      value: c[1],
    }));
    newData.message = Object.entries(value.message || {}).map((c) => ({
      language: c[0],
      value: c[1],
    }));
    return newData;
  };

  const saveAgreement = () => {
    setAgreementOpen(false);
    setSelectedLang((prev) => langs[0]);
    currAgreement.current = agreement || [];
    let newAgreement = getUpdatedAgreement(data);
    if (!_.isEqual([newAgreement], agreement)) {
      editData({agreement: [newAgreement]}, true);
    } else {
      onSave();
    }
  };

  const handleContentChange = (value, lang) => {
    if (lang) {
      setSelectedLang((prev) => value);
      return;
    }
    if (!value || !Object.keys(value).length) {
      currAgreement.current = [];
      setAgreement(null);
      if (!!agreement && !!agreement.length) {
        editData({agreement: []}, true);
      }
      setLangs(["en"]);
      setSelectedLang("en");
      return;
    }
    let newAgreement = getUpdatedAgreement(value);
    if (!_.isEqual([newAgreement], agreement)) {
      editData({agreement: [newAgreement]});
    }
    setAgreement(value);
  };

  const addLang = (lang) => {
    setAgreement((prev) => ({...prev, [lang]: ""}));
    setLangs((prev) => [...prev, lang]);
    setSelectedLang((prev) => lang);
  };

  const removeLang = (lang) => {
    setLangs((prev) => prev.filter((l) => l !== lang));
    const newContent = {...data};
    delete newContent.name[lang];
    delete newContent.message[lang];
    setSelectedLang((prev) =>
      prev === lang ? Object.keys(newContent.name)[0] : prev,
    );
    setAgreement((prev) => newContent);
  };

  const handleFieldChange = (type) => (e) => {
    const newData = {
      ...data,
      [type]: {...data[type], [selectedLang]: e.target.value},
    };
    handleContentChange(newData, null);
  };

  const handleTitleContentValidity = (value) => {
    setIsTitleContentValid((prev) => value);
  };

  const handleContentValidity = (value) => {
    setIsContentValid((prev) => value);
  };

  const handleCancelDataChange = () => {
    resetAgreement();
    setAgreementOpen(false);
  };

  function getDialogContent() {
    return (
      <div className={classes.content}>
        <Box width={"100%"}>
          <FilledTextField
            fullWidth
            useCustomFields={!disableEdit}
            label="Title"
            placeholder="House Agreement"
            value={data?.name?.[selectedLang] || ""}
            onChange={handleFieldChange("name")}
            onValidityCheck={handleTitleContentValidity}
          />
        </Box>
        <Box width={"100%"}>
          <FilledTextField
            fullWidth
            multiline
            minRows={3}
            useCustomFields={!disableEdit}
            label="Agreement Text"
            placeholder={`* No Pets allowed\n* Please respect the check-in time`}
            value={data?.message?.[selectedLang] || ""}
            onChange={handleFieldChange("message")}
            AIprops={{
              type: contentTypeEnum.agreement,
              objects: {listing: AIListingData},
            }}
            onValidityCheck={handleContentValidity}
          />
        </Box>
      </div>
    );
  }

  function getPreviewContent() {
    if (!previewData) {
      return null;
    }
    return (
      <PreviewBPPanel
        type={"agreement"}
        content={previewData}
        listingId={listingId}
      />
    );
  }

  const agreementDialog = (
    <CustomDialog
      fullWidth
      disableExit
      maxWidth="lg"
      customHeight={"calc(100% - 20px)"}
      open={!!agreementOpen}
      title="Add agreement"
      onClose={handleCancelDataChange}
      headerAction={
        <LanguageSelector
          selectedLangs={langs}
          disableEdit={disableEdit}
          selectedLanguage={selectedLang}
          addLang={addLang}
          removeLang={removeLang}
          selectLang={setSelectedLang}
        />
      }
      content={getDialogContent()}
      labelConfirm="Save"
      disableConfirm={
        !!disableEdit ||
        !data?.name?.[selectedLang]?.trim() ||
        !isTitleContentValid ||
        !isContentValid
      }
      actionConfirm={saveAgreement}
      labelCancel={disableEdit ? "Close" : "Cancel"}
      actionCancel={handleCancelDataChange}
      previewContent={getPreviewContent()}
    />
  );

  return (
    <div>
      {agreementDialog}
      <Box className={clsx(classes.row, "start", "gap")}>
        <SvgIcon
          className={classes.icon}
          viewBox="0 0 16 16"
          component={PenIcon}
        />
        <Typography variant="h1">{"Agreements"}</Typography>
      </Box>
      <Box
        mt={2}
        mb={!agreementOpen && !data && !disableEdit ? 0 : 2}
        display="flex"
        flexDirection="row"
        alignItems="flex-end"
        style={{gap: theme.spacing(3)}}
      >
        <Box flex={1}>
          <Typography>
            {
              "An agreement will be presented to the guest following their verification and must be signed by them before preceding to the check-in steps."
            }
          </Typography>
        </Box>
        {!agreementOpen && !data && !disableEdit && (
          <PrimaryButton
            size="small"
            className="mr-2"
            id="addRemAgreement"
            label={"Add agreement"}
            onClick={() => {
              setAgreementOpen(true);
              handleContentChange({message: {en: ""}, name: {en: ""}}, null);
            }}
          />
        )}
      </Box>
      {!agreementOpen && !data && !disableEdit ? null : (
        <div
          className={clsx(classes.dataContainer, {
            noData: !data && !agreementOpen,
            mt: !!agreementOpen || (!!data && !agreementOpen),
          })}
        >
          <Collapse in timeout={0}>
            <Paper elevation={0} className={experienceStyles.paper}>
              {!!data && (
                <div
                  className={experienceStyles.selectedOption}
                  style={{cursor: "pointer"}}
                >
                  <Typography
                    className={clsx(
                      experienceStyles.title,
                      experienceStyles.flexGrow,
                      experienceStyles.row,
                    )}
                    onClick={() => setAgreementOpen(true)}
                    component="span"
                  >
                    {data.name[selectedLang]}
                  </Typography>
                  {!disableEdit && (
                    <>
                      <EditIconButton
                        disablePadding
                        spaceRight={3}
                        onClick={() => setAgreementOpen((prev) => true)}
                      />
                      <DeleteIconButton
                        disablePadding
                        onClick={() => handleContentChange(null, null)}
                      />
                    </>
                  )}
                </div>
              )}
            </Paper>
          </Collapse>
        </div>
      )}
    </div>
  );
}
