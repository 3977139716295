import React from "react";
import {useSelector} from "react-redux";
import {useRouteMatch} from "react-router-dom";
// UI
import {makeStyles, MenuItem, Typography} from "@material-ui/core";
import {Col, Row} from "reactstrap";
// Custom
import FilledTextField from "core/inputs/FilledTextField";
import CustomSwitch from "core/switches/CustomSwitch";
import FilledSelect from "core/selects/FilledSelect";
// Utils
import {experienceTypesLabels} from "configuration/constants";
import {THEME} from "configuration/settings.js";

const useStyles = makeStyles((theme) => ({
  title: {...THEME.customTypography.title2},
  toggleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    height: "100%",
  },
}));

export default function ContentDetails({
  experience,
  isNew,
  groupId,
  setData,
  disableEdit,
}) {
  const classes = useStyles();
  const matchCardSection = useRouteMatch(
    "/admin/listings/groups/:group_id/:step/:card",
  );
  const listingGroups = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );
  const [detailsInfo, setDetailsInfo] = React.useState({
    name: "",
    type: "",
    enabled: false,
  });
  // General
  const isLive = React.useMemo(() => {
    if (
      !!matchCardSection?.isExact &&
      !!groupId &&
      !!listingGroups[groupId]?.guest_journey
    ) {
      return !!listingGroups[groupId].guest_journey.enabled;
    } else {
      return true;
    }
  }, [experience]);

  React.useEffect(() => {
    setDetailsInfo((prev) => ({
      name: experience?.name || "",
      type: experience?.type || "msg",
      enabled: !!experience?.enabled,
    }));
  }, [experience]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setData({...detailsInfo});
    }, 150);
    return () => clearTimeout(timer);
  }, [detailsInfo]);

  const toggleEnabled = (e) =>
    setDetailsInfo((prev) => ({...prev, enabled: !prev.enabled}));

  const handleDetailsInfoChange = (field) => (e) => {
    const val = e.target.value;
    setDetailsInfo((prev) => ({...prev, [field]: val}));
  };

  return (
    <div className="mb-1">
      <Row className="mb-3">
        <Col className="mt-2" md={12} lg={6}>
          <FilledTextField
            id="name"
            fullWidth
            label="Name"
            disabled={disableEdit}
            placeholder="Experience Name"
            value={detailsInfo.name}
            onChange={handleDetailsInfoChange("name")}
          />
        </Col>
        {!!isNew && (
          <Col className="mt-2" md={12} lg={6}>
            <FilledSelect
              fullWidth
              label="Category"
              disabled={disableEdit}
              value={detailsInfo.type}
              onChange={handleDetailsInfoChange("type")}
            >
              {Object.keys(experienceTypesLabels).map((k) => (
                <MenuItem value={k} key={k}>
                  {experienceTypesLabels[k]}
                </MenuItem>
              ))}
            </FilledSelect>
          </Col>
        )}
        {!!isLive && (
          <Col className="mt-2" md={12} lg={6}>
            <div className={classes.toggleContainer}>
              <Typography variant="caption" color="textSecondary">
                Status
              </Typography>
              <div>
                <CustomSwitch
                  alignStart
                  disabled={disableEdit}
                  checked={detailsInfo.enabled}
                  onChange={toggleEnabled}
                />
                <Typography variant="body1" style={{display: "inline"}}>
                  This experience is{" "}
                  {detailsInfo.enabled ? "enabled" : "disabled"}
                </Typography>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
}
