import React from "react";
import {useFlags, withLDConsumer} from "launchdarkly-react-client-sdk";
// UI
import {Box, SvgIcon, Typography} from "@material-ui/core";
import useBookingPanelStyles from "styles/useBookingPanelStyles";
import {ReactComponent as BoardingPassIcon} from "assets/icons/boarding-pass.svg";
import {ReactComponent as WarningIcon} from "assets/icons/alert-triangle.svg";
// Custom
import WarningAlert from "core/alerts/WarningAlert";
import PrimaryButton from "core/buttons/PrimaryButton";
import AccordionItem from "core/listItems/AccordionItem";
import ButtonLabelLoader from "core/loaders/ButtonLabelLoader";
import EmptyContentText from "components/Misc/EmptyContentText";
import VerificationStepCard from "components/Cards/VerificationStepCard";
// Utils
import {isMobile} from "react-device-detect";
import {THEME} from "configuration/settings";
import usePrevious from "hooks/usePrevious";
import clsx from "clsx";

const BookingBoardingPassInfo = ({
  booking = {},
  guestMetadata,
  loading,
  handleAction,
  openAccessCodes,
}) => {
  const classes = useBookingPanelStyles();
  const buttonClicked = React.useRef(null);
  const bookingActionsLoaded = React.useRef(true);
  const flags = useFlags();
  const [expanded, setExpanded] = React.useState(false);
  const isVerified = !!booking.tags?.includes("Verified");
  const isRevoked = !!booking.tags?.includes("Revoked");
  const bpAlerts = booking?.actions?.bp_actions || [];
  const bpActions = booking?.actions?.bp_dropdown || [];
  const vfActions = booking?.actions?.vf_steps || [];
  const label = isRevoked ? "Revoked" : isVerified ? "Verified" : "";
  const prevBpAlerts = usePrevious(bpAlerts);
  const prevBooking = usePrevious(booking);
  let isActionInProgress = !!loading?.length;
  let bookingStatus = booking?.status || "inquiry";

  React.useEffect(() => {
    if (prevBooking?.booking_id !== booking.booking_id)
      bookingActionsLoaded.current = false;
  }, [booking]);

  React.useEffect(() => {
    if (!prevBpAlerts?.length && !!bpAlerts.length) {
      setExpanded((prev) => true);
      bookingActionsLoaded.current = true;
    } else if (!bookingActionsLoaded.current) {
      bookingActionsLoaded.current = true;
      setExpanded(
        (prev) =>
          !booking?.actions?.bp_fees?.length &&
          !booking?.actions?.bp_upsells?.length,
      );
    }
  }, [bpAlerts]);

  const handleActionClick = (props, type, ignoreType) => () => {
    buttonClicked.current = type;
    if (!ignoreType) {
      handleAction(props, type);
    } else {
      handleAction(props);
    }
  };

  return (
    <>
      <AccordionItem
        divider
        firstItem
        disableHover
        infoText={label}
        infoTextProps={{
          persistent: true,
          bold: true,
          filled: true,
        }}
        expanded={expanded}
        title={"Boarding Pass"}
        onClick={() => setExpanded((prev) => !prev)}
        icon={<SvgIcon viewBox="0 0 21 14" component={BoardingPassIcon} />}
      />
      {!!expanded &&
        (bookingStatus.includes("inquiry") ? (
          <EmptyContentText label="The boarding pass is not available for inquiries" />
        ) : (
          <div className={classes.expandedSection}>
            {/* Alerts */}
            {bpAlerts.map((props, index) => (
              <WarningAlert
                key={`bkg_alert_${index}`}
                hide={false}
                disableMargin
                action={handleActionClick(props, "confirm")}
                actionLoading={
                  loading.includes(props?.label) &&
                  buttonClicked.current === "confirm"
                }
                actionButtonLabel={props.confirm?.label}
                disableActionBtn={isActionInProgress}
                secondaryAction={handleActionClick(props, "deny")}
                secondaryActionLoading={
                  loading.includes(props?.label) &&
                  buttonClicked.current === "deny"
                }
                secondaryActionLabel={props.deny?.label}
                disableSecondaryActionBtn={isActionInProgress}
                title={props.label}
              />
            ))}
            <Box
              p={2}
              width="100%"
              display="flex"
              flexDirection="column"
              style={{gap: THEME.spacing.sm}}
            >
              <Box id={"verification-steps-section"}>
                <Typography
                  variant="subtitle2"
                  style={{marginBottom: THEME.spacing.sm}}
                >
                  {"Verification Steps"}
                </Typography>
                <div className={classes.stepsContainer}>
                  {vfActions.map((vfAction) => {
                    let buttonsArray = null;
                    if (!!vfAction.dropdown) {
                      buttonsArray = vfAction?.actions?.map((act) => {
                        let optionNames = act.options.map((opt) => opt.label);
                        return {
                          variant: act.variant,
                          label: act.label,
                          disabled: isActionInProgress,
                          loading: loading.some((l) => optionNames.includes(l)),
                          options: act.options.map((opt) => ({
                            label: opt.label,
                            onClick: handleActionClick(opt, act.label, true),
                          })),
                        };
                      });
                    } else {
                      buttonsArray = vfAction?.actions?.map((act) => ({
                        variant: act.variant,
                        label: act.label,
                        disabled: isActionInProgress,
                        loading: loading.includes(act?.label),
                        onClick: handleActionClick(act),
                      }));
                    }

                    return (
                      <VerificationStepCard
                        key={vfAction.label}
                        label={vfAction.label}
                        disabled={vfAction.disabled}
                        completed={vfAction.completed}
                        dropdown={vfAction.dropdown}
                        buttons={buttonsArray}
                      />
                    );
                  })}
                </div>
              </Box>
              {!!bpActions.length && (
                <Box mt={1}>
                  {bpActions.map((bpAction) => {
                    const isLoading = loading.includes(bpAction?.label);

                    if (
                      ["verify_guest", "unrevoke_guest"].includes(
                        bpAction.action_options.action_id,
                      )
                    ) {
                      const hasAccess =
                        bpAction.action_options.confirm === false;
                      return !flags.bookingPanelRedesign ? (
                        <Box
                          key="boarding_pass_access"
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          paddingY={2}
                        >
                          <Typography variant="subtitle2">
                            {"Boarding Pass Access"}
                          </Typography>
                          <PrimaryButton
                            id="grant-revoke-access-btn"
                            label={
                              isLoading ? (
                                <ButtonLabelLoader variant="white" />
                              ) : hasAccess ? (
                                "Revoke Access"
                              ) : (
                                "Grant Access"
                              )
                            }
                            variant="outlined"
                            disabled={isActionInProgress}
                            className={
                              hasAccess
                                ? classes.deleteButton
                                : classes.subActionButton
                            }
                            onClick={handleActionClick(bpAction)}
                          />
                        </Box>
                      ) : (
                        <Box key={"boarding_pass_access"}>
                          <div className={classes.greyContainer}>
                            <Typography variant="subtitle2">
                              {"Boarding pass access"}
                            </Typography>
                            <PrimaryButton
                              dark
                              id="grant-revoke-access-btn"
                              disabled={isActionInProgress}
                              label={
                                isLoading ? (
                                  <ButtonLabelLoader variant="white" />
                                ) : hasAccess ? (
                                  "Revoke Access"
                                ) : (
                                  "Grant Access"
                                )
                              }
                              onClick={handleActionClick(bpAction)}
                            />
                          </div>
                          {!!booking?.lock_codes?.length && !hasAccess && (
                            <Box className={classes.blueWarning} mt={3}>
                              <SvgIcon
                                className={classes.warningIcon}
                                component={WarningIcon}
                                viewBox="0 0 24 24"
                              />
                              <Typography
                                component="div"
                                variant="caption"
                                color="secondary"
                                style={{flex: 1}}
                              >
                                {"Revoking the boarding pass "}
                                <Typography
                                  component="span"
                                  variant="caption"
                                  style={{fontWeight: 600}}
                                >
                                  {"does not disable booking access codes. "}
                                </Typography>
                                {
                                  "Please revoke these manually to ensure the guest does not have access."
                                }
                              </Typography>
                              <PrimaryButton
                                color="secondary"
                                variant="outlined"
                                label={"Edit Codes"}
                                onClick={() => openAccessCodes()}
                              />
                            </Box>
                          )}
                        </Box>
                      );
                    } else if (
                      bpAction.action_options.action_id ===
                      "verification_report"
                    ) {
                      if (isMobile) {
                        return null;
                      }
                      const disabled =
                        !guestMetadata?.identity_docs?.length &&
                        !booking.signed_agreements?.length;
                      return !flags.bookingPanelRedesign ? (
                        <Box
                          key="verification_report"
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          paddingY={2}
                        >
                          <Typography variant="subtitle2">
                            {"Verification Report"}
                          </Typography>
                          <PrimaryButton
                            id="download-verification-report-btn"
                            label={
                              isLoading ? (
                                <ButtonLabelLoader variant="white" />
                              ) : (
                                "Download"
                              )
                            }
                            disabled={disabled || isActionInProgress}
                            className={clsx(classes.tertiaryButton, {
                              "-disabled": disabled || isActionInProgress,
                            })}
                            onClick={handleActionClick(bpAction)}
                          />
                        </Box>
                      ) : (
                        <div
                          className={clsx(classes.greyContainer, classes.mt3)}
                          key="verification_report"
                        >
                          <Box flex={1}>
                            <Typography variant="subtitle2">
                              {"Chargeback report"}
                            </Typography>
                            <Typography>
                              {
                                "Chargeback reports will provide a full details of any chargebacks done in your account."
                              }
                            </Typography>
                          </Box>
                          <PrimaryButton
                            dark
                            id="download-verification-report-btn"
                            label={
                              isLoading ? (
                                <ButtonLabelLoader variant="white" />
                              ) : (
                                "Download"
                              )
                            }
                            disabled={disabled || isActionInProgress}
                            className={clsx(classes.downloadBtn, {
                              [classes.disabledBtn]:
                                disabled || isActionInProgress,
                            })}
                            onClick={handleActionClick(bpAction)}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <Box
                          key={bpAction.action_options.action_id}
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          paddingY={2}
                        >
                          <PrimaryButton
                            dark
                            disabled={isActionInProgress}
                            label={
                              isLoading ? (
                                <ButtonLabelLoader variant="white" />
                              ) : (
                                bpAction.label
                              )
                            }
                            variant="outlined"
                            onClick={handleActionClick(bpAction)}
                          />
                        </Box>
                      );
                    }
                  })}
                </Box>
              )}
            </Box>
          </div>
        ))}
    </>
  );
};

export default withLDConsumer()(BookingBoardingPassInfo);
