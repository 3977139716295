import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
// UI
import {
  Box,
  List,
  ListItem,
  MenuList,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import useDashboardStyles from "styles/useDashboardStyles";
// Custom
import TimePeriodModal from "components/Dialogs/TimePeriodModal";
import ExpandButton from "core/buttons/ExpandButton";
import InfoText from "components/Misc/InfoText";
import CustomMenu from "core/menus/CustomMenu";
// Redux
import {getDashboardBookingStats} from "redux/actions/dashboardActions";
// Utils
import {
  BOOKING_STATUS_COLORS,
  FIXED_SIZES,
  THEME,
} from "configuration/settings";
import {endOfDay, startOfDay, subDays} from "date-fns";
import {capitalize} from "utilities/helperFunctions";
import {metricsParams} from "configuration/specs";
import infoTexts from "assets/infoTexts";
import clsx from "clsx";
const now = new Date();

const BookingStatsCard = ({
  loading,
  selectedGroups = [],
  row,
  refreshDasboard,
  onRefreshCompleted,
  mobile,
  saveSelectedRange = () => {},
}) => {
  const classes = useDashboardStyles();
  const dispatch = useDispatch();
  const didMount = useRef(false);
  const didRefresh = useRef(false);
  const data = useSelector((state) => state.dashboardReducer.bookingsData);
  const selectedViewItems = useSelector(
    (state) => state.defaultReducer.selected_view_items,
  );
  let savedProps = selectedViewItems.dashboard.props;
  const [property, setProperty] = useState("channel");
  const [propertyMenuAnchorEl, setPropertyMenuAnchorEl] = useState(null);
  const [localLoading, setLocalLoading] = useState(false);
  const [dateRange, setDateRange] = useState(
    !!savedProps.bookingStatsRange
      ? savedProps.bookingStatsRange
      : [
          new Date(startOfDay(subDays(now, 29))).getTime(),
          new Date(endOfDay(now)).getTime(),
        ],
  );
  let bigTotal = loading ? 0 : (data?.total_bookings ?? 0);
  let stats = data?.stats || [];

  function getData(range, stat) {
    dispatch(
      getDashboardBookingStats({
        start_date: range[0],
        end_date: range[1],
        listing_group_ids: !!selectedGroups.length ? selectedGroups : null,
        stat: stat,
        onSuccess: (res) => {
          if (!!didRefresh.current) {
            didRefresh.current = false;
            onRefreshCompleted();
          }
          setLocalLoading((prev) => false);
        },
        onError: () => {
          setLocalLoading((prev) => false);
        },
      }),
    );
  }

  useEffect(() => {
    if (!!refreshDasboard && !didRefresh.current) {
      didRefresh.current = true;
      getData(dateRange, property);
    }
  }, [refreshDasboard]);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      if (!!savedProps.bookingStatsRange) {
        didRefresh.current = true;
        getData(dateRange);
      }
      return;
    }
    setLocalLoading((prev) => true);
    getData(dateRange, property);
  }, [selectedGroups]);

  const openObjectTypeMenu = (e) => setPropertyMenuAnchorEl(e.currentTarget);
  const closePropertyMenu = (e) => setPropertyMenuAnchorEl(null);

  const handlePropertyChange = (newProperty) => {
    setProperty(newProperty);
    setLocalLoading((prev) => true);
    getData(dateRange, newProperty);
    closePropertyMenu();
  };

  const handleTimePeriodChange = (newRange, isRangeComplete) => {
    setDateRange((prev) => newRange);
    if (isRangeComplete) {
      saveSelectedRange(newRange);
      setLocalLoading((prev) => true);
      getData(newRange, property);
    }
  };

  const propertyMenu = (
    <CustomMenu
      open={!!propertyMenuAnchorEl}
      anchorEl={propertyMenuAnchorEl}
      onClose={closePropertyMenu}
      content={
        <MenuList>
          <ListItem
            button
            disableRipple
            className={clsx(classes.menuItemText, {
              selected: property === "channel",
            })}
            onClick={() => {
              handlePropertyChange("channel");
            }}
          >
            {"Channel"}
          </ListItem>
          <ListItem
            button
            disableRipple
            className={clsx(classes.menuItemText, {
              selected: property === "status",
            })}
            onClick={() => {
              handlePropertyChange("status");
            }}
          >
            {"Status"}
          </ListItem>
        </MenuList>
      }
    />
  );

  const timePeriodModal = (
    <TimePeriodModal
      past
      disableDateRestriction
      disableBtn={loading || localLoading}
      selectedRange={dateRange}
      onChange={handleTimePeriodChange}
    />
  );

  const objectTypeSelector = (
    <ExpandButton
      size="small"
      variant="contained"
      disabled={loading || localLoading}
      buttonClasses={{label: classes.lightSelectorLabel}}
      className={clsx(classes.lightSelector, {
        disabled: loading || localLoading,
      })}
      label={capitalize(property)}
      onClick={openObjectTypeMenu}
    />
  );

  const totalInfo = (
    <Box className={clsx(classes.row, classes.gap8)} alignItems="center">
      <InfoText disableGutters text={infoTexts.totalBookings} subdued />
      <Typography variant="h3" className={classes.bigTotal}>
        {bigTotal}
      </Typography>
      {!mobile && timePeriodModal}
    </Box>
  );

  return (
    <div
      className={clsx(classes.card, "padd", classes.flex1, "noPaddBottom", {
        lg: !!row,
        mobile: !!mobile,
      })}
      style={{height: FIXED_SIZES.dashboard_sm_card}}
    >
      {propertyMenu}
      <div
        className={clsx(classes.cardHeader, classes.gap8, {
          [classes.row]: !mobile,
          [classes.col]: !!mobile,
          middle: mobile,
        })}
      >
        <Box className={clsx(classes.row, classes.gap8)} alignItems="center">
          <Typography variant="h2" className={classes.cardTitle}>
            {"Total bookings"}
          </Typography>
          {!mobile && objectTypeSelector}
          {!!mobile && totalInfo}
        </Box>
        {!mobile && totalInfo}
        {!!mobile && (
          <Box
            width={"100%"}
            className={classes.row}
            justifyContent="space-between"
            mt={2}
          >
            {objectTypeSelector}
            {timePeriodModal}
          </Box>
        )}
      </div>
      {loading || localLoading ? (
        <Box mt={2} flex={1} pb={4} display={"flex"} flexDirection={"column"}>
          <Skeleton
            animation="wave"
            width="100%"
            height={20}
            className={classes.skeleton}
            style={{borderRadius: 999}}
          />
          <Box mt={2} flex={1}>
            <Skeleton
              animation="wave"
              width="100%"
              height={"100%"}
              className={classes.skeleton}
            />
          </Box>
        </Box>
      ) : (
        <Box overflow={"auto"} className={classes.hideScrollbar}>
          <Box className={classes.bookingsBar}>
            {!stats.length && (
              <Box
                width={"100%"}
                style={{
                  backgroundColor: THEME.subdued,
                  borderRadius: 360,
                  opacity: 0.3,
                }}
              ></Box>
            )}
            {stats.map((s, i) => {
              let bgColor =
                property === "channel"
                  ? (metricsParams.booking_channel.bucket_metadata[s.key]
                      ?.color ?? THEME.subdued)
                  : (BOOKING_STATUS_COLORS[s.key] ?? THEME.subdued);
              return (
                <Tooltip
                  arrow
                  key={s.key}
                  title={`${capitalize(s.key, "_")}: ${s.doc_count}`}
                >
                  <Box
                    width={`${(s.doc_count * 100) / data.total_bookings}%`}
                    style={{
                      backgroundColor: bgColor,
                      borderRadius:
                        stats.length === 1
                          ? 360
                          : i === 0
                            ? "360px 0 0 360px"
                            : i === stats.length - 1
                              ? "0 360px 360px 0"
                              : 0,
                    }}
                  ></Box>
                </Tooltip>
              );
            })}
          </Box>
          <Box
            pt={3}
            pb={4}
            className={classes.cardListContainer}
            overflow={"auto"}
          >
            {!stats.length && (
              <Box mt={5}>
                <Typography
                  variant="h2"
                  align="center"
                  className={classes.noDataTitle}
                >
                  {"No bookings data"}
                </Typography>
              </Box>
            )}
            <List>
              {stats.map((s) => {
                let bgColor =
                  property === "channel"
                    ? (metricsParams.booking_channel.bucket_metadata[s.key]
                        ?.color ?? THEME.subdued)
                    : (BOOKING_STATUS_COLORS[s.key] ?? THEME.subdued);
                return (
                  <ListItem
                    key={`rating-${s.key}-stars`}
                    className={classes.cardListItem}
                  >
                    <Box
                      width={20}
                      height={20}
                      borderRadius={5}
                      style={{backgroundColor: bgColor}}
                    />
                    <Typography variant="h1" style={{flex: 1}}>
                      {capitalize(s.key, "_")}
                    </Typography>
                    <Typography variant="h1">{s.doc_count}</Typography>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default BookingStatsCard;
