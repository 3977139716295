import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
// UI
import {
  Button,
  Typography,
  Checkbox,
  IconButton,
  FormGroup,
  FormControl,
  FormControlLabel,
  CircularProgress,
  Chip,
  Box,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import {useTranslation} from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import CustomCardHeader from "core/cards/CustomCardHeader";
import Country from "components/TextFields/Country";
import SearchBar from "core/bars/SearchBar";
import InputField from "core/inputs/InputField";
import {searchAvailablePhoneNumbers} from "redux/api/accountsAPI";
import qs from "qs";
import _ from "lodash";
import CurrencyText from "components/TextFields/CurrencyText";
import {
  deleteManagedPhone,
  purchasePhoneNumber,
  updateManagedPhone,
} from "redux/actions/accountsActions";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import DeleteIcon from "@material-ui/icons/Delete";
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import PhoneForwardedRoundedIcon from "@material-ui/icons/PhoneForwardedRounded";
import {THEME} from "configuration/settings";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5, 4),
    overflow: "auto",
    flex: 1,
  },
  cardHeader: {padding: theme.spacing(0)},
  row: {
    display: "flex",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    alignItems: "center",
  },
}));

let BOUNCE_TIMEOUT = 1;

export default function ConnectedPhonesAddEditCard({
  phoneState,
  handlePanel3Open,
}) {
  const classes = useStyles();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const [phone, setPhone] = phoneState;
  const [loading, setLoading] = useState({
    delete: false,
    availableNumberSearch: false,
    purchase: false,
    saveEdit: false,
  });
  const [addDisplayOnlyPhone, setAddDisplayOnlyPhone] = useState(false);
  const [switchPhoneMode, setSwitchPhoneMode] = useState(false);
  const [searchParams, setSearchParams] = useState({
    country: user_profile?.country || null,
    query: "",
    sms: false,
    call: false,
  });
  const [searchResults, setSearchResults] = useState([]);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [selectedManagedPhone, setSelectedManagedPhone] = useState(null);
  const [saveEditEnabled, setSaveEditEnabled] = useState(false);
  const [validRedirectPhone, setValidRedirectPhone] = useState(true);
  const [confirmPurchaseOpen, setConfirmPurchaseOpen] = useState(false);
  const [purchasePhoneIndex, setPurchasePhoneIndex] = useState(-1);

  const getAvailablePhoneNumbersToPurchase = async () => {
    try {
      setLoading({...loading, availableNumberSearch: true});
      const params = {...searchParams, voice: searchParams.call};
      const availableNumbers = await searchAvailablePhoneNumbers(
        qs.stringify(params),
      );
      setSearchResults(availableNumbers);
    } catch (err) {
      console.error("Error getting available phone numbers ->", err);
    } finally {
      setLoading({...loading, availableNumberSearch: false});
    }
  };

  React.useEffect(() => {
    if (!searchParams.country) {
      return;
    }

    const debounce = setTimeout(() => {
      getAvailablePhoneNumbersToPurchase();
    }, BOUNCE_TIMEOUT);

    BOUNCE_TIMEOUT = 500; // set to 500ms after first run
    return () => clearTimeout(debounce);
  }, [searchParams]);

  React.useEffect(() => {
    if (addDisplayOnlyPhone) return;

    setSelectedManagedPhone(
      user_profile?.managed_phones?.find((p) => p.phone === phone) || null,
    );
  }, [phone]);

  function isEditMode(phone) {
    return phone !== "" && selectedManagedPhone;
  }

  function handlePurchasePhoneNumber(newPhone) {
    if (!newPhone?.phone_number) return;

    setLoading({...loading, purchase: true});
    dispatch(
      purchasePhoneNumber({
        phone: newPhone.phone_number,
        phone_country: newPhone.country,
        switch_from_phone: phone,
      }),
    ).finally(() => {
      setPurchasePhoneIndex(-1);
      setPhone("");
      setLoading({...loading, purchase: false});
      setConfirmPurchaseOpen(false);
      handlePanel3Open(false);
    });
  }

  function handleAddDisplayOnlyPhone(phone) {
    dispatch(updateManagedPhone({phone: phone, capabilities: []})).finally(
      () => {
        setPhone("");
        handlePanel3Open(false);
      },
    );
  }

  function handleDelete(ph) {
    setLoading({...loading, delete: true});
    dispatch(deleteManagedPhone(ph)).finally(() => {
      setPhone("");
      setLoading({...loading, delete: false});
      setConfirmDeleteOpen(false);
      handlePanel3Open(false);
    });
  }

  function handleEditPhone() {
    setLoading({...loading, saveEdit: true});
    dispatch(updateManagedPhone(selectedManagedPhone)).finally(() => {
      setLoading({...loading, saveEdit: false});
    });
  }

  function editSelectedManagedPhoneCapabilities(capability, enabled) {
    setSaveEditEnabled(true);
    setSelectedManagedPhone((prev) => ({
      ...prev,
      capabilities: enabled
        ? _.uniq([...prev.capabilities, capability])
        : _.uniq(prev.capabilities.filter((c) => c !== capability)),
    }));
  }

  function addDisplayOnlyPhoneContent() {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <CustomCardHeader
            title={`Add display only phone number`}
            className={classes.cardHeader}
          />
          <div>
            <Button onClick={() => setAddDisplayOnlyPhone(false)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!phone}
              onClick={() => {
                handleAddDisplayOnlyPhone(phone);
              }}
            >
              Save
            </Button>
          </div>
        </div>
        <div>
          <b
            className="mt-1"
            style={{fontSize: "1rem", fontWeight: 600, lineHeight: "1.5rem"}}
          >
            Add display-only number
          </b>
          <Typography className="mt-2">
            {t("managed-phone-display-only-phone-info")}
          </Typography>
          <div style={{marginTop: "1rem"}}>
            <InputField
              editOnly
              fullWidth
              type="phone"
              label="Phone"
              value={phone}
              onChange={setPhone}
              onSave={() => {}}
              onCancel={() => {}}
              isInvalid={false}
              phoneInputProps={{
                allowEmpty: true,
                setValidPhone: () => {},
              }}
            />
          </div>
        </div>
      </>
    );
  }

  function addSwitchPhoneContent() {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <CustomCardHeader
            title={`${switchPhoneMode ? "Switch" : "Add"} phone number`}
            className={classes.cardHeader}
          />
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            style={{padding: "0"}}
            onClick={() => handlePanel3Open(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div>
          <b
            className="mt-1"
            style={{fontSize: "1rem", fontWeight: 600, lineHeight: "1.5rem"}}
          >
            Purchase a Phone Number
          </b>
          <Typography className="mt-2">
            {t("purchase-a-phone-number-info")}
          </Typography>
          <div style={{marginTop: "1rem", display: "flex"}}>
            <Country
              edit
              label="Country"
              value={searchParams.country || ""}
              onValueChange={(c) => {
                setSearchParams((prev) => ({
                  ...prev,
                  country: c,
                }));
              }}
            />
            <div style={{marginLeft: ".5rem"}}>
              <Typography>Capabilities</Typography>
              <div style={{display: "flex", justifyContent: "space-between"}}>
                <FormControl
                  sx={{m: 3}}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup
                    style={{flexWrap: "unset", flexDirection: "unset"}}
                  >
                    {["sms", "call"].map((key, index) => {
                      return (
                        <FormGroup
                          style={{flexWrap: "unset", flexDirection: "unset"}}
                          key={index}
                        >
                          <FormControlLabel
                            label={key.toUpperCase()}
                            control={
                              <Checkbox
                                checked={searchParams[key]}
                                onChange={(e) => {
                                  let newVal = e.target.checked;
                                  setSearchParams((prev) => ({
                                    ...prev,
                                    [key]: newVal,
                                  }));
                                  console.log(
                                    "phoneCapabilities",
                                    searchParams,
                                    newVal,
                                  );
                                }}
                                name={key}
                              />
                            }
                          />
                        </FormGroup>
                      );
                    })}
                  </FormGroup>
                </FormControl>
              </div>
            </div>
          </div>
          <SearchBar
            disableGutters
            placeholder={`Search phone numbers`}
            searchInput={searchParams.query}
            handleSearchInput={(val) => {
              setSearchParams((prev) => ({
                ...prev,
                query: val,
              }));
            }}
          />
          <div
            style={{
              background: "#F5F5F5",
              borderRadius: ".4rem",
              marginTop: "1rem",
              padding: ".8rem",
            }}
          >
            {loading.availableNumberSearch && (
              <div style={{display: "flex", justifyContent: "center"}}>
                <CircularProgress />
              </div>
            )}
            {!loading.availableNumberSearch && !searchResults.length && (
              <div>
                <Typography>No phone numbers available</Typography>
              </div>
            )}
            {!loading.availableNumberSearch &&
              !!searchResults.length &&
              searchResults.map((phoneNumber, index) => {
                return (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <div>
                          {phoneNumber?.friendly_name}{" "}
                          {phoneNumber.phone_type === "toll_free" && (
                            <span style={{fontSize: ".5rem"}}>(Toll-Free)</span>
                          )}
                        </div>
                        <div
                          style={{color: "#6D7175", fontSize: ".8rem"}}
                        >{`${phoneNumber.locality || ""} ${phoneNumber.region || ""} ${phoneNumber.country || ""}`}</div>
                      </div>
                      <div style={{display: "flex", alignItems: "center"}}>
                        <div style={{display: "flex"}}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "1.3rem",
                            }}
                          >
                            {phoneNumber?.capabilities?.SMS ? (
                              <CheckCircleIcon
                                fontSize="inherit"
                                style={{
                                  fontSize: "1rem",
                                  color: "rgba(154, 212, 214, 1)",
                                }}
                              />
                            ) : (
                              <RadioButtonUncheckedIcon
                                fontSize="inherit"
                                style={{
                                  fontSize: "1rem",
                                  color: "rgba(17, 20, 33, 0.25)",
                                }}
                              />
                            )}
                            <span
                              className="ml-2"
                              style={{fontSize: "1rem", lineHeight: "1.3rem"}}
                            >
                              SMS
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "1.3rem",
                            }}
                          >
                            {phoneNumber?.capabilities?.voice ? (
                              <CheckCircleIcon
                                fontSize="inherit"
                                style={{
                                  fontSize: "1rem",
                                  color: "rgba(154, 212, 214, 1)",
                                }}
                              />
                            ) : (
                              <RadioButtonUncheckedIcon
                                fontSize="inherit"
                                style={{
                                  fontSize: "1rem",
                                  color: "rgba(17, 20, 33, 0.25)",
                                }}
                              />
                            )}
                            <span
                              className="ml-2"
                              style={{fontSize: "1rem", lineHeight: "1.3rem"}}
                            >
                              Call
                            </span>
                          </div>
                        </div>
                        <div style={{marginLeft: "1.3rem", textAlign: "end"}}>
                          <CurrencyText
                            disableFixedDecimal
                            value={phoneNumber?.price}
                            currency={phoneNumber?.currency}
                            noSuffix
                          />
                          <div style={{color: "#6D7175", fontSize: ".8rem"}}>
                            per month
                          </div>
                        </div>
                        <Button
                          style={{marginLeft: "1.3rem", maxHeight: "1.8rem"}}
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setPurchasePhoneIndex(index);
                            setConfirmPurchaseOpen(true);
                          }}
                        >
                          Buy
                        </Button>
                      </div>
                    </div>
                    {searchResults.length - 1 !== index && (
                      <hr style={{margin: ".8rem"}} />
                    )}
                  </>
                );
              })}
            <ConfirmDialog
              disableDismiss
              open={confirmPurchaseOpen}
              onClose={() => {
                setPurchasePhoneIndex(-1);
                setConfirmPurchaseOpen(false);
              }}
              title={`Purchase${phone ? " and switch with existing" : ""} phone number?`}
              message={
                <span style={{whiteSpace: "pre-wrap"}}>
                  {phone
                    ? t("confirm-phone-purchase-and-switch-info", {
                        to_purchase:
                          searchResults[purchasePhoneIndex]?.phone_number,
                        switch_from_phone: phone,
                      })
                    : t("confirm-phone-purchase-info", {
                        to_purchase:
                          searchResults[purchasePhoneIndex]?.phone_number,
                      })}
                </span>
              }
              confirmLabel="Purchase"
              confirmAction={() =>
                handlePurchasePhoneNumber(searchResults[purchasePhoneIndex])
              }
              cancelLabel="Cancel"
              confirmDisabled={loading.purchase}
              cancelAction={() => {
                setPurchasePhoneIndex(-1);
                setConfirmPurchaseOpen(false);
              }}
            />
          </div>
          {!switchPhoneMode && (
            <>
              <hr />
              <b
                className="mt-1"
                style={{
                  fontSize: "1rem",
                  fontWeight: 600,
                  lineHeight: "1.5rem",
                }}
              >
                Other options
              </b>
              <div
                style={{
                  backgroundColor: "rgba(13, 86, 140, 0.10)",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: ".8rem 1rem",
                  borderRadius: "0.4rem",
                }}
              >
                <span>{t("managed-phone-add-display-only-phone-info")}</span>
                <Button
                  style={{maxHeight: "1.8rem", marginLeft: "1rem"}}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setAddDisplayOnlyPhone(true);
                  }}
                >
                  Select
                </Button>
              </div>
            </>
          )}
        </div>
      </>
    );
  }

  function editPhoneContent() {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <CustomCardHeader
            title={`Edit phone`}
            className={classes.cardHeader}
          />
          <div>
            <Button onClick={() => handlePanel3Open(false)}>Cancel</Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                !loading.saveEdit && !saveEditEnabled && validRedirectPhone
              }
              onClick={() => {
                handleEditPhone();
              }}
            >
              Save
            </Button>
          </div>
        </div>
        <div>
          <div>
            <Typography>{t("add-edit-phone-info")}</Typography>
            <div
              className={classes.outlinedContainer}
              style={{
                backgroundColor: "#F8F8F9",
                padding: ".5rem",
                borderRadius: ".5rem",
                marginTop: "1.5rem",
              }}
            >
              <InputField
                editOnly
                fullWidth
                disableEdit
                type="phone"
                label="Phone"
                value={phone}
              />
            </div>
            {selectedManagedPhone?.active && ( // only show redirect phone input if managed phone
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                style={{gap: THEME.spacing.sm}}
                mt={4}
              >
                <SubdirectoryArrowRightIcon />
                {/* <PhoneForwardedRoundedIcon /> */}
                <InputField
                  editOnly
                  fullWidth
                  type="phone"
                  label="Redirect calls to:"
                  disableEnterSave
                  value={selectedManagedPhone?.redirect_phone || ""}
                  onChange={(val) => {
                    setSaveEditEnabled(true);
                    setSelectedManagedPhone((prev) => ({
                      ...prev,
                      redirect_phone: val,
                    }));
                  }}
                  isInvalid={
                    !!selectedManagedPhone?.redirect_phone &&
                    !validRedirectPhone
                  }
                  phoneInputProps={{setValidPhone: setValidRedirectPhone}}
                />
              </Box>
            )}
            {!selectedManagedPhone?.active && (
              <div className={classes.row} style={{}}>
                <Typography style={{marginRight: "5px"}}>
                  {" "}
                  {"Icons to display in the Boarding Pass:"}{" "}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    checked={selectedManagedPhone?.capabilities?.includes(
                      "SMS",
                    )}
                    onChange={(e) => {
                      editSelectedManagedPhoneCapabilities(
                        "SMS",
                        e.target.checked,
                      );
                    }}
                  />
                  <Typography>SMS</Typography>
                  <Checkbox
                    checked={selectedManagedPhone?.capabilities?.includes(
                      "call",
                    )}
                    onChange={(e) => {
                      editSelectedManagedPhoneCapabilities(
                        "call",
                        e.target.checked,
                      );
                    }}
                  />
                  <Typography>Call</Typography>
                  <Checkbox
                    checked={selectedManagedPhone?.capabilities?.includes(
                      "whatsapp",
                    )}
                    onChange={(e) => {
                      editSelectedManagedPhoneCapabilities(
                        "whatsapp",
                        e.target.checked,
                      );
                    }}
                  />
                  <Typography>Whatsapp</Typography>
                </div>
              </div>
            )}
          </div>
          {selectedManagedPhone?.active && (
            <div
              style={{
                backgroundColor: "rgba(13, 86, 140, 0.10)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: ".8rem 1rem",
                borderRadius: "0.4rem",
                marginTop: "1.25rem",
              }}
            >
              <span>Want to use a different number?</span>
              <Button
                style={{maxHeight: "1.8rem", marginLeft: "1rem"}}
                variant="contained"
                color="primary"
                onClick={() => {
                  setSwitchPhoneMode(true);
                }}
              >
                Switch
              </Button>
            </div>
          )}
          <Chip
            onClick={() => setConfirmDeleteOpen(true)}
            style={{
              backgroundColor: "darkred",
              color: "white",
              marginTop: "1.5rem",
            }}
            clickable={true}
            avatar={<DeleteIcon style={{color: "white"}} />}
            size="small"
            label={"Permanently delete phone"}
          />
          <ConfirmDialog
            disableDismiss
            open={confirmDeleteOpen}
            onClose={() => setConfirmDeleteOpen(false)}
            title={`Permanently delete your managed phone?`}
            message={
              <span style={{whiteSpace: "pre-wrap"}}>
                "<b>{phone}</b>" {t("permanently-delete-phone-info")}
              </span>
            }
            confirmLabel="Permanently delete"
            confirmAction={() => handleDelete(phone)}
            cancelLabel="Cancel"
            confirmDisabled={loading.delete}
            cancelAction={() => setConfirmDeleteOpen(false)}
          />
        </div>
      </>
    );
  }

  function getContent() {
    if (!isEditMode(phone)) {
      if (addDisplayOnlyPhone) {
        return addDisplayOnlyPhoneContent();
      }

      return addSwitchPhoneContent();
    }

    if (switchPhoneMode) {
      return addSwitchPhoneContent();
    }

    return editPhoneContent();
  }

  return <div className={classes.root}>{getContent()}</div>;
}
