import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import {Redirect} from "react-router-dom";
// UI
import {CircularProgress, makeStyles} from "@material-ui/core";
// Custom
import Panel2 from "core/panels/Panel2";
import Panel3 from "core/panels/Panel3";
import StripeAccountCard from "components/Cards/StripeAccountCard";
import WhatsappAccountCard from "components/Cards/WhatsappAccountCard";
import AirbnbAccountCard from "components/Cards/AirbnbAccountCard";
import ServiceAccountCard from "components/Cards/ServiceAccountCard";
import ConnectedAccountCard from "components/Cards/ConnectedAccountCard.jsx";
import ConnectedAccountPanel from "components/Panels/ConnectedAccountPanel.jsx";
import CustomCardHeader from "core/cards/CustomCardHeader";
import PrimaryButton from "core/buttons/PrimaryButton";
import CloseIconButton from "core/buttons/CloseIconButton";
// REDUX
import {postServiceStripe} from "redux/api/servicesAPI";
import {getDevices} from "redux/actions/devicesActions";
import {postAccount, patchAccount} from "redux/api/accountsAPI";
import {getServiceAccounts} from "redux/actions/servicesActions";
import {setSelectedViewItem} from "redux/actions/accountsActions";
// Utils
import {
  serviceProviderEnum,
  channelIntegrationTypeEnum,
  integrationSpecs,
} from "configuration/enums";
import {pmsConfig} from "configuration/specs";
import clsx from "clsx";
import {useFlags, withLDConsumer} from "launchdarkly-react-client-sdk";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "row",
  },
  panel2Container: {
    padding: theme.spacing(0, 4, 5),
    overflow: "auto",
    flex: 1,
    "&.-loading": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
}));

const ConnectedAccounts = () => {
  const classes = useStyles();
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const serviceAccounts = useSelector(
    (state) => state.defaultReducer.service_accounts,
  );
  const isTabletView =
    useSelector((state) => state.defaultReducer.deviceType) === "tablet";
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const selectedViewItems = useSelector(
    (state) => state.defaultReducer.selected_view_items,
  );
  let savedItem = selectedViewItems.settings.item;
  let savedProps = selectedViewItems.settings.props.connectedAccount;
  const [selectedAccountType, setSelectedAccountType] = useState(
    savedProps.accountType,
  ); // THIS REFERS TO PROVIDER OR INTEGRATION TYPE! NOT PMS/DEVICE
  const [selectedIntegrationSpec, setSelectedIntegrationSpec] = useState(
    savedProps.integrationSpec ?? {},
  );
  const [selectedAccount, setSelectedAccount] = useState(
    savedProps.account ?? {},
  );
  const [accounts, setAccounts] = useState({});
  const {redirect, redirect_status} = useParams();
  const [redirectLoading, setRedirectLoading] = useState(false);
  const [redirectResolved, setRedirectResolved] = React.useState(null);
  const [redirectPage, setRedirectPage] = React.useState(null);
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const dispatch = useDispatch();
  const readOnly = user_profile.scopes?.smart_devices !== "write";
  let hasAbMsg = Object.keys(accounts).some((ca) => !!pmsConfig[ca]?.ab_msg);
  let flags = useFlags();

  useEffect(() => {
    let params = `enso_key=${current_user}`;
    if (!!current_user) {
      dispatch(
        getDevices(params, (newDevices) => {
          setDevices(newDevices);
        }),
      );
    }
  }, [current_user]);

  useEffect(() => {
    if (!Object.keys(serviceAccounts?.accounts ?? {}).length) {
      getAccounts();
    } else {
      setAccounts(serviceAccounts.accounts);
      setLoading((prev) => false);
    }
  }, []);

  const providers = Object.values(serviceProviderEnum);
  const integrationTypes = Object.values(channelIntegrationTypeEnum);

  const handleSelectAccount = (
    acc = null,
    accType = null,
    useIntegrationType = false,
  ) => {
    let defaultProps = {
      selectedDevice: null,
      selectedUser: null,
    };
    let newIntegrationSpec = integrationSpecs.find((f) =>
      useIntegrationType
        ? f.integration_type === accType
        : f.provider === accType,
    );
    if (!accType) {
      setSelectedAccountType(accType);
    } else {
      setSelectedAccount(acc);
      setSelectedIntegrationSpec(newIntegrationSpec);
      setSelectedAccountType(accType);
    }
    dispatch(
      setSelectedViewItem("settings", savedItem, {
        ...defaultProps,
        connectedAccount: {
          account: acc,
          accountType: accType,
          integrationSpec: !accType ? null : newIntegrationSpec,
        },
      }),
    );
  };

  function handleDeleteAccount(account) {
    let account_key = account.provider || account.integration_type;
    let newAccounts = Object.assign({}, accounts);
    newAccounts = {
      ...newAccounts,
      [account_key]: newAccounts[account_key].filter(
        (a) =>
          (!!a.service_id && a.service_id !== account.service_id) ||
          (!!a.integration_host_id &&
            a.integration_host_id !== account.integration_host_id),
      ),
    };
    console.log("GOT NEW ACCOUNTS", newAccounts);
    setAccounts(newAccounts);
  }

  const handleStripeRedirect = () => {
    if (redirect_status === "return" && !redirectResolved) {
      let params = new URLSearchParams(window.location.search);
      postServiceStripe({
        code: params.get("code"),
        new_account: false,
        integration_service_id: params.get("service_id"),
      }).then((r) => {
        console.log("GOT BILLING POST RESPONSE", r);
        if (r.success && r.service_account) {
          handleSelectAccount(r.service_account, "stripe");
          getAccounts();
          setRedirectResolved(true);
        }
      });
    }
  };

  const handleAirbnbRedirect = () => {
    let params = new URLSearchParams(window.location.search);
    setRedirectLoading(true);
    postAccount({
      body: {
        enso_key: current_user,
        data: {
          integration_type: "abnb",
          integration_data: {
            airbnb_authorization_code: params.get("code"),
          },
        },
      },
    })
      .then((r) => {
        window.location.href =
          "/admin/settings/accounts/:redirect/:redirect_status";
      })
      .finally(() => {
        setRedirectLoading(false);
      });
  };

  const handleHostfullyRedirect = () => {
    let params = new URLSearchParams(window.location.search);
    console.log(`GOT PARAMS`, params);
    setRedirectLoading(true);
    patchAccount({
      body: {
        enso_key: current_user,
        integration_type: "hfl",
        integration_host_id: params.get("state"),
        options: {
          hostfully_authorization_code: params.get("code"),
        },
      },
    })
      .then((r) => {
        window.location.href =
          "/admin/settings/accounts/:redirect/:redirect_status";
      })
      .finally(() => {
        setRedirectLoading(false);
      });
  };

  React.useEffect(() => {
    console.log("GOT INFO", redirect, redirect_status, redirectResolved);
    if (redirect === "stripe") {
      handleStripeRedirect();
    } else if (redirect == "abnb") {
      handleAirbnbRedirect();
    } else if (redirect == "hfl") {
      handleHostfullyRedirect();
    }
  }, [redirect]);

  function getAccounts() {
    setLoading(true);
    dispatch(
      getServiceAccounts({
        onSuccess: (r) => {
          setAccounts(r.accounts);
          setLoading(false);
        },
        onError: () => setLoading(false),
      }),
    );
  }

  function getPanel2() {
    if (loading) {
      return (
        <div className={clsx(classes.panel2Container, "-loading")}>
          <CircularProgress />
        </div>
      );
    } else {
      const accountEntries = Object.entries(accounts);

      return (
        <>
          <CustomCardHeader
            title="Connected Accounts"
            className={classes.header}
            action={
              <>
                {!readOnly && flags.connectPmsAccount && (
                  <PrimaryButton
                    size="small"
                    label="Connect Account"
                    className="mr-3"
                    onClick={() => setRedirectPage("connect")}
                  />
                )}
              </>
            }
          />
          <div className={classes.panel2Container}>
            {!Object.keys(accounts).includes("whatsapp") && (
              <WhatsappAccountCard />
            )}
            {!Object.keys(accounts).includes("stripe") && <StripeAccountCard />}
            {!Object.keys(accounts).includes("abnb") && !hasAbMsg && (
              <AirbnbAccountCard loading={redirectLoading} />
            )}

            {accountEntries.map((entry) => {
              if (!!entry[1].length && integrationTypes.includes(entry[0])) {
                return (
                  <ConnectedAccountCard
                    key={entry[0]}
                    integrationType={entry[0]}
                    channels={entry[1]}
                    selectedChannel={selectedAccount}
                    initialOpen={
                      savedProps.accountType === entry[0] && entry[1].length > 1
                    }
                    setSelectedChannel={(c) =>
                      handleSelectAccount(c, entry[0], true)
                    }
                    setLoading={setLoading}
                  />
                );
              } else if (!!entry[1].length && providers.includes(entry[0])) {
                return (
                  <ServiceAccountCard
                    key={entry[0]}
                    channels={entry[1]}
                    selectedChannel={selectedAccount}
                    initialOpen={
                      savedProps.accountType === entry[0] && entry[1].length > 1
                    }
                    setSelectedChannel={(c) => handleSelectAccount(c, entry[0])}
                    triggerRefresh={triggerRefresh}
                  />
                );
              }
            })}
          </div>
        </>
      );
    }
  }

  if (!!redirectPage) {
    return <Redirect push to={`/admin/${redirectPage}`} />;
  }

  const isDeviceAccount = !["whatsapp", "stripe"].includes(selectedAccountType);
  return (
    <div className={classes.root}>
      <Panel2
        flexAuto
        content={getPanel2()}
        isPanel3Open={isTabletView ? false : !!selectedAccountType}
        hidden={isTabletView && !!selectedAccountType}
      />
      {(!isTabletView || !!selectedAccountType) && (
        <Panel3
          content={
            !!selectedAccountType && (
              <ConnectedAccountPanel
                handleDeleteAccount={handleDeleteAccount}
                account={selectedAccount}
                integration_spec={selectedIntegrationSpec}
                onClose={() => handleSelectAccount()}
                setTriggerRefresh={setTriggerRefresh}
                devices={
                  isDeviceAccount &&
                  devices.filter(
                    (d) => d.service_id == selectedAccount.service_id,
                  )
                }
              />
            )
          }
          hidden={!selectedAccountType}
          maintainWidth={!isTabletView}
          disableMaxWidth={isTabletView}
        />
      )}
    </div>
  );
};

export default withLDConsumer()(ConnectedAccounts);
