import * as types from "../actionTypes";
import * as API from "../api/guestsAPI";
import {
  closeGeneralSuccessAlert,
  errorResponseAlert,
  openGeneralSuccessAlert,
  setApiError,
  setApiStart,
  setApiSuccess,
  setStartKeys,
  setTotals,
} from "./settingsActions";
import {addBooking} from "./bookingsActions";

export function setGuests(guests) {
  return {type: types.SET_GUESTS, guests: guests};
}
export function addGuests(guests, orderOptions) {
  return {type: types.ADD_GUESTS, guests, orderOptions};
}
export function editGuest(guest, orderOptions) {
  return {type: types.EDIT_GUEST, guest, orderOptions};
}
export function setInboxData(inboxData) {
  return {type: types.SET_INBOX_DATA, inboxData};
}

export function getGuests({
  params,
  add = false,
  skipLocalUpdate,
  onSuccess = () => {},
  onError = () => {},
}) {
  return (dispatch) => {
    dispatch(setApiStart("guests"));
    API.getGuests(params)
      .then((response) => {
        console.log("GOT GUESTS", response);
        const total =
          response.inbox_data?.all?.total_count ?? response.count ?? "";
        if (add) {
          dispatch(addGuests(response.hits || response));
        } else if (!skipLocalUpdate) {
          dispatch(setGuests(response.hits || [response]));
          dispatch(setTotals("guests", total));
          dispatch(setStartKeys("guests", response.start_key));
        }
        if (!!response.inbox_data) {
          dispatch(setInboxData(response.inbox_data));
        }
        onSuccess(response);
        dispatch(setApiSuccess("guests"));
      })
      .catch((err) => {
        console.log("ERROR - GET GUESTS", err);
        dispatch(setApiError("guests"));
        errorResponseAlert(dispatch, null, null, err);
        onError();
      });
  };
}

export function getGuest(guestId, onSuccess, onError) {
  return async (dispatch, getState) => {
    const prevGuest = getState().defaultReducer.guests_dict[guestId];
    try {
      dispatch(setApiStart("guest"));
      const response = await API.getGuest(guestId);
      const guest = response.guest;
      guest.metadata = response.guest_metadata;
      guest.bills = response.guest_bills;
      if (!!prevGuest && !prevGuest.unread_messages) {
        guest.unread_messages = false;
      }
      dispatch(addGuests(guest));
      dispatch(setApiSuccess("guests"));
      dispatch(addBooking(response.bookings || []));
      onSuccess && onSuccess(guest);
    } catch (err) {
      dispatch(setApiError("guest"));

      errorResponseAlert(dispatch, null, null, err);

      onError && onError();
    }
  };
}

export function archiveGuests({
  filterGroups,
  disableAlert = false,
  onSuccess = () => {},
  onError = () => {},
}) {
  return async (dispatch) => {
    try {
      let body = {};
      if (!!filterGroups) {
        body.filter_groups = filterGroups;
      }
      body.write_es = true;
      const response = await API.patchGuestsArchive({body: body});
      if (!disableAlert) {
        successResponseAlert(dispatch, "Conversations archived");
      }
      onSuccess(response);
    } catch (error) {
      if (!disableAlert) {
        errorResponseAlert(
          dispatch,
          "Failed to archive conversations",
          "Something went wrong, please try again later",
          error,
        );
      }
      onError();
    }
  };
}

// TO BE DEPRECATED
export function updateGuest({
  guest_id,
  field,
  val,
  onSuccess,
  onError,
  disableAlert,
  multipleFields,
}) {
  return async (dispatch, getState) => {
    const guests = getState().defaultReducer.guests_dict;
    const selectedGuest = guests[guest_id];
    try {
      let body = {};
      if (!!multipleFields) {
        body = {guest_id: guest_id, ...multipleFields};
        dispatch(editGuest({...selectedGuest, ...multipleFields}));
      } else {
        body = {guest_id: guest_id, [field]: val};
        dispatch(editGuest({...selectedGuest, [field]: val}));
      }
      body.write_es = true;
      const response = await API.patchGuest({body: body});
      dispatch(editGuest({...selectedGuest, ...response}));
      !disableAlert &&
        successResponseAlert(
          dispatch,
          "Changes saved",
          "Guest updated correctly",
        );
      onSuccess && onSuccess(response);
    } catch (err) {
      console.log("ERROR - UPDATE GUEST", err);
      dispatch(editGuest({...selectedGuest}));

      if (!disableAlert) {
        errorResponseAlert(
          dispatch,
          "Failed to save changes",
          "Something went wrong, please try again later",
          err,
        );
      }
      !!onError && onError();
    }
  };
}

// ALERTS
function handleSuccessAlertClose(dispatch) {
  dispatch(closeGeneralSuccessAlert());
}

function successResponseAlert(dispatch, message = "Success!", subtitle = null) {
  dispatch(
    openGeneralSuccessAlert({
      message,
      subtitle,
      open: true,
      onClose: () => handleSuccessAlertClose(dispatch),
    }),
  );
}
