import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
// UI
import {
  Button,
  Typography,
  Card,
  CardContent,
  IconButton,
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import EditIcon from "@material-ui/icons/Edit";
import PhoneForwardedRoundedIcon from "@material-ui/icons/PhoneForwardedRounded";
import InfoText from "components/Misc/InfoText";
import ServiceInfoDialog from "components/Dialogs/ServiceInfoContent";
import {addonServices} from "configuration/enums";
import {postChannels} from "redux/actions/accountsActions";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    alignItems: "center",
  },
  form: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
  content: {overflow: "auto"},
  list: {
    backgroundColor: "rgba(47, 122, 251, 0.05)",
    border: "solid 1px rgba(229, 229, 234, 0.75)",
    borderRadius: 10,
    margin: theme.spacing(0, 0, 5),
  },
  tag: {
    backgroundColor: "rgba(67,84,96,0.07)",
    color: "rgba(67,84,96,0.5)",
    fontSize: 12,
    height: 20,
    width: "fit-content",
  },
}));

export default function ConnectedPhonesCardNew({phoneState, handlePanel3Open}) {
  const classes = useStyles();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const [_, setPhone] = phoneState;
  const [subscribeDialogOpen, setSubscribeDialogOpen] = React.useState(false);
  const [subscriptionLoading, setSubscriptionLoading] = React.useState(false);

  const managedPhoneLabel = {
    whatsapp: {
      tag: "Whatsapp",
      info: "This number will appear in the boarding pass & Whatsapp messages will appear in the unified inbox",
    },
    managed: {
      tag: "Enso Phone",
      info: "This number will appear in the boarding pass & SMS messages will appear in the Enso unified inbox.",
    },
    display: {
      tag: "Display-Only",
      info: "This number will appear in the boarding pass but calls & messages will not show up in the Enso unified inbox",
    },
  };

  function managedPhoneType(ph) {
    let type = "display";

    if (ph.active) {
      type = "managed";
    }
    if (
      ph.capabilities.map((c) => c.toLowerCase()).includes("whatsapp") &&
      ph.active
    ) {
      type = "whatsapp";
    }

    return type;
  }

  const suscribe = () => {
    setSubscriptionLoading(true);
    dispatch(
      postChannels(
        {
          enso_key: user_profile?.enso_key,
          data: {
            service: "sms",
            provider: "twilio",
            options: {},
          },
        },
        (response) => {
          if (response.options.success) {
            if (!user_profile.connected_services) {
              user_profile.connected_services = [];
            }
            user_profile.connected_services.push("sms");
            console.log(user_profile);
          } else if (response.options.message == "pay") {
            window.location.href = "/admin/settings/billing/:redirect";
          }
          setSubscriptionLoading(false);
          setSubscribeDialogOpen(false);
        },
        (error) => {
          console.log(error);
          setSubscriptionLoading(false);
          setSubscribeDialogOpen(false);
        },
      ),
    );
  };

  const subscribeDialog = (
    <ServiceInfoDialog
      open={subscribeDialogOpen}
      service={addonServices.twilio[0]}
      headerIcon={addonServices.twilio[0].icon_url}
      dialogBGImage={addonServices.twilio[0].cover_photo_url}
      handleEnable={suscribe}
      loading={subscriptionLoading}
      onClose={() => setSubscribeDialogOpen((prev) => false)}
      btnLabel={"Suscribe"}
    />
  );

  return (
    <Card>
      <CardContent className={classes.content}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h2">Phone Numbers</Typography>
          {user_profile?.connected_services?.includes("sms") ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setPhone("");
                handlePanel3Open(true);
              }}
            >
              Add Phone
            </Button>
          ) : (
            <Button
              id="enable-sms"
              onClick={() => setSubscribeDialogOpen(true)}
              variant="outlined"
              color="primary"
            >
              Enable SMS
            </Button>
          )}
        </div>
        <Typography className="mt-4">
          {t("managed-phone-business-setting-info")}
        </Typography>
        <div style={{padding: "1rem"}}>
          {(user_profile?.managed_phones || []).map((mp, index) => {
            const phoneType = managedPhoneType(mp);
            return (
              <div
                key={mp.phone}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: index ? ".8rem" : 0,
                }}
              >
                <div>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <Typography variant="h2">{mp.phone}</Typography>
                    <Button
                      variant="contained"
                      className="ml-3"
                      style={{
                        borderRadius: "5px",
                        background: "#DCE5EC",
                        color: "#275588",
                        fontSize: "12px",
                        fontWeight: 400,
                        lineHeight: "1rem",
                        maxHeight: "1.5rem",
                      }}
                    >
                      {managedPhoneLabel[managedPhoneType(mp)].tag}
                    </Button>
                    <InfoText
                      text={managedPhoneLabel[managedPhoneType(mp)].info}
                    />
                  </div>
                  {phoneType !== "whatsapp" && (
                    <div style={{display: "flex", marginTop: ".5rem"}}>
                      <div style={{display: "flex", alignItems: "center"}}>
                        {mp.capabilities
                          .map((c) => c.toLowerCase())
                          .includes("sms") ? (
                          <CheckCircleIcon
                            fontSize="inherit"
                            style={{
                              fontSize: "1rem",
                              color: "rgba(154, 212, 214, 1)",
                            }}
                          />
                        ) : (
                          <RadioButtonUncheckedIcon
                            fontSize="inherit"
                            style={{
                              fontSize: "1rem",
                              color: "rgba(17, 20, 33, 0.25)",
                            }}
                          />
                        )}
                        <span
                          className="ml-2"
                          style={{fontSize: "1rem", lineHeight: "1.3rem"}}
                        >
                          SMS
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "1.3rem",
                        }}
                      >
                        {mp.capabilities
                          .map((c) => c.toLowerCase())
                          .includes("call") ? (
                          <CheckCircleIcon
                            fontSize="inherit"
                            style={{
                              fontSize: "1rem",
                              color: "rgba(154, 212, 214, 1)",
                            }}
                          />
                        ) : (
                          <RadioButtonUncheckedIcon
                            fontSize="inherit"
                            style={{
                              fontSize: "1rem",
                              color: "rgba(17, 20, 33, 0.25)",
                            }}
                          />
                        )}
                        <span
                          className="ml-2"
                          style={{fontSize: "1rem", lineHeight: "1.3rem"}}
                        >
                          Call
                        </span>
                      </div>
                    </div>
                  )}
                  {mp.redirect_phone && phoneType !== "whatsapp" && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: ".5rem",
                        backgroundColor: "rgba(13, 86, 140, 0.1)",
                        padding: ".4rem .6rem",
                        borderRadius: ".4rem",
                        width: "fit-content",
                      }}
                    >
                      <PhoneForwardedRoundedIcon fontSize="small" />{" "}
                      <span
                        style={{
                          fontSize: ".8rem",
                          lineHeight: "1.3rem",
                          marginLeft: ".5rem",
                        }}
                      >
                        Redirect to:{" "}
                      </span>{" "}
                      <span
                        style={{
                          fontSize: ".8rem",
                          lineHeight: "1.3rem",
                          color: "rgba(17, 20, 33, 0.5)",
                          marginLeft: ".5rem",
                        }}
                      >
                        {mp.redirect_phone}
                      </span>
                    </div>
                  )}
                </div>
                {phoneType !== "whatsapp" && (
                  <IconButton
                    onClick={() => {
                      setPhone(mp.phone);
                      handlePanel3Open(true);
                    }}
                    style={{padding: "0 .5rem"}}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </div>
            );
          })}
        </div>
      </CardContent>
      {subscribeDialog}
    </Card>
  );
}
