import React from "react";
import AddFileIcon from "@material-ui/icons/NoteAddOutlined";
import {
  FormControl,
  InputLabel,
  TextField,
  makeStyles,
} from "@material-ui/core";
import WarningIcon from "assets/icons/WarningIcon";
import {useImageCompressor} from "utilities/fileUtilities";

const useStyles = makeStyles((theme) => ({
  label: {
    backgroundColor: theme.palette.primary.contrast,
    zIndex: 1,
    padding: theme.spacing(0, 1),
    top: -7,
    left: 10,
    color: (props) =>
      props.hasErrors ? theme.palette.error.main : theme.palette.primary.main,
  },
  input: {
    "& > div": {
      border: "2px solid",
      borderRadius: 12,
      borderColor: (props) =>
        props.hasErrors ? theme.palette.error.main : theme.palette.primary.main,
      paddingRight: 8,
      "& > fieldset": {border: "none"},
    },
  },
  divider: {backgroundColor: theme.palette.primary.dark},
  required: {
    paddingLeft: 3,
    fontSize: 21,
  },
  adornment: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    fontSize: 26,
  },
}));

export default function KeycardUploadImageField({field, index, onChange}) {
  const hasErrors = !!field.error;
  const classes = useStyles({hasErrors});
  const imgInputRef = React.useRef();
  const [imgToUpload, setImgToUpload] = React.useState(null);
  const [compressEnabled, setCompressEnabled] = React.useState(false);

  useImageCompressor({
    srcImgs: !!compressEnabled && !!imgToUpload ? [imgToUpload.file] : [],
    onSuccess: (outputImg) => onCompressSuccess(outputImg),
  });

  const browseFile = () => {
    if (imgInputRef.current) imgInputRef.current.click();
  };

  const onCompressSuccess = (outputImg) => {
    setCompressEnabled((prev) => false);
    handleSelectImage(outputImg[0], true);
  };

  const handleSelectImage = (file, compressed) => {
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (event) {
      let result = event.target.result;
      let newFile = {index, file, result};
      if (!!compressed) {
        onChange(newFile);
      } else {
        setImgToUpload(newFile);
        setCompressEnabled((prev) => true);
      }
    };
  };

  return (
    <>
      <input
        ref={imgInputRef}
        style={{display: "none"}}
        type="file"
        accept="image/*"
        onChange={(e) => handleSelectImage(e.target.files[0])}
      />
      <FormControl fullWidth>
        <InputLabel shrink className={classes.label} htmlFor="image-uploader">
          {field.name}
          {!!field.required ? (
            <span className={classes.required}>{"*"}</span>
          ) : (
            ""
          )}
        </InputLabel>
        <TextField
          fullWidth
          id="image-uploader"
          value={imgToUpload?.file?.name ?? ""}
          placeholder="Browse"
          variant="outlined"
          classes={{root: classes.input}}
          onClick={browseFile}
          helperText={
            hasErrors && (
              <>
                <WarningIcon style={{marginBottom: -12}} />
                {field.name} {"is required"}
              </>
            )
          }
          error={hasErrors}
          InputProps={{
            endAdornment: <AddFileIcon className={classes.adornment} />,
          }}
        />
      </FormControl>
    </>
  );
}
