import React, {useState} from "react";
import {Typography, Link, Box, Modal, makeStyles} from "@material-ui/core";
// components
import ProductPage from "./ProductPage";
import VerifyItem from "./VerifyItem";
import BrandingFooter from "./BrandingFooter";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 2.5),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
}));

export default function VerifyPage({components, onItemClick}) {
  const classes = useStyles();
  const [selectedProduct, setSelectedProduct] = useState(null);

  function handlePurchaseRedirect(product) {
    setSelectedProduct(product);
  }

  const handleCloseProduct = (replace) => {
    setSelectedProduct((prev) => null);
  };

  return (
    <main>
      <div className={classes.container}>
        {!!selectedProduct && (
          <Modal open>
            <div>
              <ProductPage
                product_id={selectedProduct.product_id}
                closePage={handleCloseProduct}
              />
            </div>
          </Modal>
        )}
        <Box mt={5}>
          <Typography variant="h3">
            {"Let's get your booking verified"}
          </Typography>
        </Box>
        {Object.entries(components?.completed_verification || [])?.map((v) => (
          <div key={v[0]}>
            <VerifyItem
              setView={() => onItemClick(v[0])}
              component_type={v[0]}
              verified={v[1]}
              components={components}
            />
          </div>
        ))}
        {(components?.fees || []).map((f) => (
          <div>
            <VerifyItem
              onClick={() => handlePurchaseRedirect(f)}
              component_type={
                ["deposit", "pet_fee"].includes(f.product_id)
                  ? f.product_id
                  : "custom_fee"
              }
              verified={f?.purchased}
              name={
                !!f?.error
                  ? "Failed to Load Fee - Please Contact Host"
                  : `${f?.name}${f?.status === "requires_action" ? " - Additional verification required" : ""}`
              }
              error={f?.error}
            />
          </div>
        ))}
        <Typography variant="body2">
          {"By verifying, you agree to the"}{" "}
          <Link
            variant="body2"
            href="https://ensoconnect.com/privacy-policy/"
            target="_blank"
          >
            {"Terms & Privacy Policy"}.
          </Link>
        </Typography>
      </div>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        mt={5}
      >
        <BrandingFooter metadata={components?.metadata} />
      </Box>
    </main>
  );
}
