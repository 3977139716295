import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useRouteMatch} from "react-router-dom";
// UI
import {makeStyles} from "@material-ui/core/styles";
// Custom
import AvailabilityRules from "components/Panels/Listing/AvailabilityRules.jsx";
import PricingRules from "components/Panels/Listing/PricingRules.jsx";
import FeesTaxes from "components/Panels/Listing/FeesTaxes.jsx";
import GeneralInformationPanel from "components/Panels/ListingContent/GeneralInformationPanel";
import AmenitiesPanel from "components/Panels/ListingContent/AmenitiesPanel";
import PicturesPanel from "components/Panels/ListingContent/PicturesPanel";
import ListingPoliciesPanel from "components/Panels/ListingContent/ListingPoliciesPanel";
import ListingAccessPanel from "components/Panels/ListingContent/ListingAccessPanel";
import KnowledgePanel from "components/Panels/ListingContent/KnowledgePanel";
import AddressPanel from "components/Panels/ListingContent/AddressPanel";
import CalendarDetails from "components/Panels/Listing/CalendarDetails";
import ExperiencePanel from "components/Panels/Experience/ExperiencePanel";
import BookingInfoDrawer from "components/Panels/Booking/BookingInfoDrawer";
import ManageDevicesPanel from "components/Panels/Device/ManageDevicesPanel";
import GuidebooksPanel from "./GuidebooksPanel";
import ListingProductsPanel from "./ListingProductsPanel";
// Actions
import {getListingGroupDetails} from "redux/actions/listingsActions";
// Utilities
import {ALLOWED_INTEGRATIONS_FOR_CALENDAR_EDITING} from "configuration/settings.js";

const useStyles = makeStyles((theme) => ({
  listingContentSection: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",
    padding: 0,
  },
}));

const ListingContentDetailsPanel = ({
  outsideView,
  contentId,
  listingId,
  setContentId,
  openDialog,
  calendarProps,
  selectedExperience,
  onCalendarPropChange,
  goBack,
  disableEdit,
  onExperienceClose,
  groupId,
  selectExperience,
  isLive = true,
  closeDetailsPanel,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const matchCardSection = useRouteMatch(
    "/admin/listings/groups/:group_id/:step/:card",
  );
  const listings = useSelector((state) => state.defaultReducer.house_data_dict);
  const listing = React.useMemo(() => {
    return listings[listingId];
  }, [listingId, listings]);
  const listing_content = listing?.listing_content || false;
  const allowCalendarEdit = listing?.connected_channels?.some((cc) =>
    ALLOWED_INTEGRATIONS_FOR_CALENDAR_EDITING.includes(cc.integration_type),
  );

  const handleListingContentClose = () => {
    setContentId(null);
    history.replace(`/admin/listings/${listingId}`);
  };

  const getUpdatedGuestJouney = (section, card, onSuccess) => {
    dispatch(getListingGroupDetails({groupId, section, card, onSuccess}));
  };

  const handleExperienceClose = (refresh) => {
    if (!!refresh && !!groupId && !!matchCardSection?.isExact) {
      getUpdatedGuestJouney(
        matchCardSection.params.step,
        matchCardSection.params.card,
      );
    }
    onExperienceClose();
  };

  const handleExistingExperienceSelect = (exp) => {
    selectExperience(exp);
    setContentId((prev) => "config_exp");
  };

  const getPanelContent = () => {
    switch (contentId) {
      case "av":
        return (
          <AvailabilityRules
            disableEdit={!allowCalendarEdit}
            listing={listing}
            setDialog={openDialog}
          />
        );
      case "pr":
        return <PricingRules listing={listing} />;
      case "fe":
        return (
          <FeesTaxes
            disableEdit={!allowCalendarEdit}
            listing={listing}
            setDialog={openDialog}
          />
        );
      case "pictures": // Listing Content
        return (
          <PicturesPanel
            goBack={goBack}
            listing={listing}
            isHouseContentView
            disableEdit
            // disableEdit={disableEdit}
            outsideView={outsideView}
            isParentLoading={!listing}
            pictures={listing?.listing_content?.pictures || []}
            onClose={handleListingContentClose}
          />
        );
      case "address": // Listing Content
        return (
          <div className={classes.listingContentSection}>
            <AddressPanel
              goBack={goBack}
              listingId={listingId}
              disableEdit={disableEdit}
              outsideView={outsideView}
              isParentLoading={!listing}
              onClose={handleListingContentClose}
            />
          </div>
        );
      case "info": // Listing Content
        return (
          <div className={classes.listingContentSection}>
            <GeneralInformationPanel
              goBack={goBack}
              listing={listing}
              disableEdit={disableEdit}
              outsideView={outsideView}
              isParentLoading={!listing}
              onClose={handleListingContentClose}
            />
          </div>
        );
      case "access": // Listing Content
        return (
          <div className={classes.listingContentSection}>
            <ListingAccessPanel
              goBack={goBack}
              listing={listing}
              outsideView={outsideView}
              disableEdit={disableEdit}
              isParentLoading={!listing}
              onClose={handleListingContentClose}
            />
          </div>
        );
      case "policies": // Listing Content
        return (
          <div className={classes.listingContentSection}>
            <ListingPoliciesPanel
              goBack={goBack}
              listing={listing}
              disableEdit={disableEdit}
              outsideView={outsideView}
              isParentLoading={!listing}
              onClose={handleListingContentClose}
            />
          </div>
        );
      case "amenities": // Listing Content
        return (
          <AmenitiesPanel
            goBack={goBack}
            listing={listing}
            outsideView={outsideView}
            onClose={handleListingContentClose}
            selectedAmenities={listing_content.amenities}
          />
        );
      case "knowledge": // Listing Content
        return (
          <KnowledgePanel
            goBack={goBack}
            listing={listing}
            disableEdit={disableEdit}
            outsideView={outsideView}
            isParentLoading={!listing}
            onClose={handleListingContentClose}
          />
        );
      case "guidebooks": // Listing Content
        return (
          <GuidebooksPanel
            goBack={goBack}
            listing={listing}
            disableEdit={disableEdit}
            outsideView={outsideView}
            isParentLoading={contentId !== "gje_guidebooks" && !listing}
            updateGJ={getUpdatedGuestJouney}
            onClose={
              contentId === "gje_guidebooks"
                ? () => closeDetailsPanel(true)
                : handleListingContentClose
            }
          />
        );
      case "fees": // Listing Content
      case "upsells": // Listing Content
        return (
          <ListingProductsPanel
            goBack={goBack}
            type={
              contentId.startsWith("gje_") ? contentId.substring(4) : contentId
            }
            listingId={listing?.listing_id}
            disableEdit={disableEdit}
            outsideView={outsideView}
            isParentLoading={!listing && !contentId.startsWith("gje_")}
            onClose={
              contentId.startsWith("gje_")
                ? () => closeDetailsPanel(true)
                : handleListingContentClose
            }
          />
        );
      case "cd":
        return (
          <CalendarDetails
            disableEdit={!allowCalendarEdit}
            listing={listing}
            onClose={() => setContentId(null)}
            formattedRanges={calendarProps.formattedRanges}
            selectedRange={[calendarProps.startDate, calendarProps.endDate]}
            setStartRange={(date) => onCalendarPropChange("startDate", date)}
            setEndRange={(date) => onCalendarPropChange("endDate", date)}
            selectEndDate={(val) =>
              onCalendarPropChange("useEndDateFocus", val)
            }
          />
        );
      case "devices":
        return (
          <ManageDevicesPanel
            listingId={listingId}
            goBack={goBack}
            onClose={handleListingContentClose}
          />
        );
      case "config_exp":
      case "copy_existing_exp":
        return (
          <ExperiencePanel
            listing={listing}
            groupId={groupId}
            disableEdit={disableEdit}
            closeDetails={handleExperienceClose}
            isGroupLive={isLive}
            experience={selectedExperience}
            selectExperience={handleExistingExperienceSelect}
            copyExisting={contentId === "copy_existing_exp"}
          />
        );
      case "bk":
        return (
          <BookingInfoDrawer
            hideListingLink
            onClose={() => setContentId(null)}
          />
        );

      default:
        return null;
    }
  };

  return getPanelContent();
};

export default ListingContentDetailsPanel;
