import React, {useContext} from "react";
// UI
import {
  Box,
  IconButton,
  SvgIcon,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {ScrollMenu, VisibilityContext} from "react-horizontal-scrolling-menu";
import {ReactComponent as LeftArrowIcon} from "assets/icons/chevron-left.svg";
import {ReactComponent as RightArrowIcon} from "assets/icons/chevron-right.svg";
// Custom
import VerticalCard from "./VerticalCard";
import SimpleCard from "./SimpleCard";
import RowCard from "./RowCard";

const previewItems = 3;

const useStyles = makeStyles((theme) => ({
  container: {
    margin: (props) => (!!props.preview ? theme.spacing(0, -2.5) : 0),
  },
  list: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  iconBtn: {
    position: "absolute !important",
    top: "50%",
    zIndex: 2,
    backgroundColor: `${theme.palette.primary.main + "a6"} !important`,
    transform: "translate(0,-50%)",
    "&.left": {left: "5px !important"},
    "&.right": {right: "5px !important"},
    "&.disabled": {visibility: "hidden !important"},
  },
  arrowIcon: {color: theme.palette.primary.contrast},
  scrollWrapper: {
    position: "relative",
    "& .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar": {
      height: "0!important",
    },
  },
}));

function Arrow({left = false, disabled, onClick}) {
  const classes = useStyles();

  return (
    <IconButton
      disabled={disabled}
      className={[
        classes.iconBtn,
        left ? "left" : "right",
        disabled ? "disabled" : "",
      ].join(" ")}
      onClick={onClick}
    >
      <SvgIcon
        viewBox="0 0 24 24"
        className={classes.arrowIcon}
        component={!!left ? LeftArrowIcon : RightArrowIcon}
      />
    </IconButton>
  );
}

function LeftArrow() {
  const {isFirstItemVisible, initComplete, scrollPrev} =
    useContext(VisibilityContext);
  return (
    <Arrow
      left
      disabled={!initComplete || (initComplete && isFirstItemVisible)}
      onClick={() => scrollPrev()}
    />
  );
}

function RightArrow() {
  const {isLastItemVisible, initComplete, scrollNext} =
    useContext(VisibilityContext);
  return (
    <Arrow
      disabled={!initComplete || (initComplete && isLastItemVisible)}
      onClick={() => scrollNext()}
    />
  );
}

const UpgradesGrid = ({preview, upgrades, upsellsRef, onClick}) => {
  const classes = useStyles({preview});

  const goToUpsells = () => {};

  return (
    <div className={classes.container}>
      <Box py={1.5} ref={upsellsRef}>
        <Typography variant="body2">
          {"Customize your stay".toUpperCase()}
        </Typography>
      </Box>
      <ScrollMenu
        LeftArrow={LeftArrow}
        RightArrow={RightArrow}
        wrapperClassName={classes.scrollWrapper}
      >
        {upgrades.map((up, i) => {
          if (i >= previewItems) {
            return null;
          }
          return (
            <VerticalCard
              key={up.product_id}
              itemId={up.product_id}
              resource={up}
              firstItem={i === 0}
              lastItem={i === upgrades.length - 1 || i === previewItems - 1}
              onClick={() => onClick(up.product_id)}
            />
          );
        })}
      </ScrollMenu>
      {!preview && upgrades.length > previewItems && (
        <Box>
          <Box py={1.5}>
            <Typography variant="body2">
              {"All upgrades".toUpperCase()}
            </Typography>
          </Box>
          <div className={classes.list}>
            {upgrades.map((up, i) => {
              if (i < previewItems) {
                return null;
              }
              return (
                <RowCard
                  key={up.product_id}
                  disableGutters
                  resource={up}
                  onClick={() => onClick(up.product_id)}
                />
              );
            })}
          </div>
        </Box>
      )}
      {!!preview && (
        <Box mt={1.5}>
          <SimpleCard
            disableGutters
            onClick={goToUpsells}
            label={`View ${
              upgrades.length - previewItems > 0
                ? upgrades.length - previewItems + " "
                : ""
            }more`}
          />
        </Box>
      )}
    </div>
  );
};

export default UpgradesGrid;
