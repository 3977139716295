import React from "react";
// Utils
import WarningIcon from "assets/icons/WarningIcon.jsx";
import {isValid} from "date-fns";
// MUI
import {
  TextField,
  FormControlLabel,
  Checkbox,
  makeStyles,
} from "@material-ui/core";
// Components
import KeycardUploadImageField from "./KeycardUploadImageField";
import KeycardMultiOptionSelector from "./KeycardMultiOptionSelector";
import KeycardLanguageSelector from "./KeycardLanguageSelector";
import KeycardAddressAutocomplete from "./KeycardAddressAutocomplete";
import KeycardPhoneComponent from "./KeycardPhoneComponent";
// Consts

const useStyles = makeStyles((theme) => ({
  checkbox: {color: theme.palette.primary.main},
  timePicker: {
    border: "2px solid",
    borderColor: theme.palette.primary.main,
    borderRadius: 12,
    "& > label": {
      padding: theme.spacing(0, 1),
      backgroundColor: theme.palette.primary.contrast,
    },
    "& fieldset": {border: "none"},
  },
  timePickerDialog: {
    "& .MuiDialogContent-root .PrivatePickersToolbar-root": {
      "& > span": {color: theme.palette.primary.main + "!important"},
      "& > .PrivatePickersToolbar-dateTitleContainer": {
        "& .PrivateTimePickerToolbar-hourMinuteLabel": {
          "& > span": {color: theme.palette.primary.main + "!important"},
          "& > button > span": {
            color: theme.palette.primary.main + "!important",
          },
        },
        "& .PrivateTimePickerToolbar-ampmSelection": {
          "& > button > span": {
            color: theme.palette.primary.main + "!important",
          },
        },
      },
    },
  },
  textField: {borderRadius: "12px !important"},
}));

export default function KeycardCollectDetailsInput({
  field,
  index,
  phoneProps,
  components,
  setDetails,
  imagesToUpload,
  setImages,
}) {
  const {phone, initialPhoneCode, initialCountry, setPhone} = phoneProps;
  const classes = useStyles();

  function handleChangeValue(i, value, isDate, hasErrors = null) {
    let fields = Object.assign([], components.details);
    fields[i].value = isDate ? value?.toLocaleTimeString() : value;
    fields[i].error =
      hasErrors !== null
        ? hasErrors && !!fields[i].required
        : isDate
          ? !isValid(value) && fields[i].required
          : Array.isArray(value)
            ? !value.length
            : false;
    setDetails(fields);
  }

  const onPhoneChange = (i, newPhone) => {
    setPhone(newPhone);
    const fields = Object.assign([], components.details);
    fields[i].value = `+${newPhone}`;
    fields[i].error = false;
    setDetails(fields);
  };

  const handleImageChange = (i, newfile) => {
    let newImages = {...imagesToUpload, [index]: newfile};
    const fields = Object.assign([], components.details);
    fields[i].error = false;
    setImages(newImages);
  };

  const getFieldTranslation = () => {
    switch (field.field) {
      case "guest_travel_reason":
        return "Travel reason";
      case "guest_first_name":
        return "First name";
      case "guest_last_name":
        return "Last name";
      case "guest_dob":
        return "Date of birth";
      case "num_pets":
        return "Number of pets";
      case "guest_email":
        return "Email";

      default:
        return field.name;
    }
  };

  function getFieldByType() {
    switch (field.field_type) {
      case "image":
        return (
          <KeycardUploadImageField
            field={field}
            index={index}
            onChange={(newFile) => handleImageChange(index, newFile)}
          />
        );
      case "checkbox":
        return (
          <FormControlLabel
            label={getFieldTranslation()}
            control={
              <Checkbox
                className={classes.checkbox}
                checked={field.value}
                onChange={(e) => handleChangeValue(index, e.target.checked)}
              />
            }
          />
        );
      case "multi_select":
      case "single_select":
        return (
          <KeycardMultiOptionSelector
            field={field}
            index={index}
            onChange={handleChangeValue}
            singleSelection={field.field_type === "single_select"}
          />
        );
      case "number":
        let numberLabel = getFieldTranslation();
        return (
          <TextField
            id={`detail${index}`}
            variant="outlined"
            InputProps={{classes: {root: classes.textField}}}
            focused
            required={field.required}
            helperText={
              field.error && (
                <>
                  <WarningIcon style={{marginBottom: -12}} />
                  {numberLabel} {"is required"}
                </>
              )
            }
            error={field.error}
            type="number"
            inputProps={{
              inputMode: "numeric",
              min: 0,
              max: field.field === "num_pets" ? 20 : 1000,
            }}
            fullWidth
            label={numberLabel}
            onChange={(e) => handleChangeValue(index, e.target.value)}
          />
        );
      case "date":
        let dateVal =
          typeof field.value === "string" ? new Date(field.value) : field.value;
        let dateLabel = getFieldTranslation();
        return (
          <TextField
            fullWidth
            label={dateLabel}
            type="date"
            variant="outlined"
            value={dateVal || null}
            className={classes.timePicker}
            onChange={(e) => handleChangeValue(index, e?.toDateString())}
            InputLabelProps={{shrink: true}}
          />
        );
      case "time":
        let timeVal = !!field.value
          ? typeof field.value === "string"
            ? new Date(`${new Date().toDateString()} ${field.value}`)
            : field.value
          : null;
        return (
          <TextField
            fullWidth
            id={`detail${index}`}
            label={field.name ?? field.field}
            type="time"
            variant="outlined"
            value={timeVal || null}
            className={classes.timePicker}
            InputLabelProps={{shrink: true}}
            inputProps={{step: 60}}
            onChange={(newTime) => {
              handleChangeValue(index, newTime, true);
            }}
          />
        );

      case "text":
      default:
        return (
          <TextField
            id={`detail${index}`}
            variant="outlined"
            InputProps={{classes: {root: classes.textField}}}
            focused
            required={field.required}
            helperText={
              field.error && (
                <>
                  <WarningIcon style={{marginBottom: -12}} />
                  {getFieldTranslation()} {"is required"}
                </>
              )
            }
            error={field.error}
            fullWidth
            label={getFieldTranslation()}
            value={field.value}
            onChange={(e) => handleChangeValue(index, e.target.value)}
          />
        );
    }
  }

  function getField() {
    switch (field.field) {
      case "guest_language":
        return (
          <KeycardLanguageSelector
            f={field}
            ind={index}
            onChange={handleChangeValue}
          />
        );
      case "guest_address":
        return (
          <KeycardAddressAutocomplete
            address={field.value || {}}
            onChange={(e, hasErrors) =>
              handleChangeValue(index, e, false, hasErrors)
            }
            textFieldProps={{
              id: `detail${index}`,
              focused: true,
              required: field.required,
              helperText: field.error && (
                <>
                  <WarningIcon style={{marginBottom: -12}} />
                  {"Please select an address from the dropdown"}
                </>
              ),
              error: field.error,
              fullWidth: true,
              label: "Address",
            }}
          />
        );
      case "guest_phone":
        return (
          <KeycardPhoneComponent
            phone={phone}
            country={initialCountry}
            required={field.required}
            initialCode={initialPhoneCode}
            error={field.error}
            label={"Mobile phone number"}
            onChange={(val) => onPhoneChange(index, val)}
          />
        );

      default:
        return getFieldByType();
    }
  }

  return getField();
}
