import {makeStyles} from "@material-ui/core";
import {THEME} from "configuration/settings.js";

const useExperienceActionsStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "transparent",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  selectedOption: {
    backgroundColor: (props) => (!!props.filled ? "#FBFBFC" : "transparent"),
    border: (props) => (!!props.filled ? "1px solid #E6E6E6" : "none"),
    borderRadius: 10,
    boxSizing: "border-box",
    padding: (props) => (!!props.filled ? theme.spacing(3) : 0),
    display: "flex",
    alignItems: "center",
    opacity: (props) => (!!props.viewOnly && !props.filled ? 0.5 : 1),
  },
  iconBtn: {
    padding: theme.spacing(2),
    "&.-disabled": {cursor: "not-allowed"},
  },
  icon: {
    fontSize: 18,
    color: theme.palette.action.disabled,
  },
  titleRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(4),
    "& > .-icon": {fontSize: 20},
    "& > .-iconSmall": {fontSize: 18},
    "& > .-iconLarge": {fontSize: 22},
  },
  title: {...THEME.customTypography.title2},
  flexGrow: {flexGrow: 1},
  capitalize: {textTransform: "capitalize"},
  medium: {
    color: "#979797",
    fontWeight: 400,
  },
  ellipsis: {
    display: (props) => (!!props.viewOnly ? "block" : "-webkit-box"),
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-all",
    "& > svg": {marginRight: 8},
  },
  input: {marginTop: theme.spacing(3)},
  listItemIcon: {minWidth: 32},
  checkbox: {padding: 2},
  phoneContainer: {marginBottom: theme.spacing(2)},
  card: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid rgba(229, 229, 234, 0.5)",
    borderRadius: 10,
    padding: theme.spacing(3, 4),
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(4),
  },
  cancelBtn: {color: theme.palette.grey[500]},
  mr2: {marginRight: theme.spacing(1)},
  desc: {
    fontSize: 12,
    marginTop: -theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  inlineInput: {
    marginRight: 1,
    "&.-bbrr0 div, input": {borderBottomRightRadius: "0 !important"},
    "&.-btrr0 div, input": {borderTopRightRadius: "0 !important"},
  },
  inputNumber: {
    maxWidth: 60,
    [theme.breakpoints.up("lg")]: {maxWidth: 44},
  },
  selected: {fontWeight: 700},
  itemSelected: {
    opacity: "1 !important",
    fontWeight: 700,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > svg": {marginRight: theme.spacing(1)},
  },
  content: {
    flexWrap: "wrap",
    gap: 2,
    "& > .selector": {
      flex: 1,
      minWidth: "fit-content",
      alignSelf: "stretch",
    },
    "& > .field": {alignSelf: "stretch"},
  },
  select: {height: "100%"},
  triggerRow: {padding: theme.spacing(0, 5)},
  triggerCol: {
    padding: theme.spacing(0, 1),
    [theme.breakpoints.only("lg")]: {
      WebkitFlex: "0 0 33.333333%",
      flex: "0 0 33.333333%",
      maxWidth: "33.333333%",
      "&.-offset-col": {
        WebkitFlex: "0 0 40%",
        flex: "0 0 40%",
        maxWidth: "40%",
      },
      "&.-with-offset": {
        WebkitFlex: "0 0 30%",
        flex: "0 0 30%",
        maxWidth: "30%",
      },
      "&.-trigger-value.-with-offset": {
        WebkitFlex: "0 0 100%",
        flex: "0 0 100%",
        maxWidth: "100%",
      },
    },
    [theme.breakpoints.up("xl")]: {
      WebkitFlex: "0 0 33.333333%",
      flex: "0 0 33.333333%",
      maxWidth: "33.333333%",
      "&.-offset-col": {
        WebkitFlex: "0 0 30%",
        flex: "0 0 30%",
        maxWidth: "30%",
      },
      "&.-with-offset": {
        WebkitFlex: "0 0 22%",
        flex: "0 0 22%",
        maxWidth: "22%",
      },
      "&.-trigger-value.-with-offset": {
        WebkitFlex: "0 0 26%",
        flex: "0 0 26%",
        maxWidth: "26%",
      },
    },
  },
  listSubheader: {
    lineHeight: "21px",
    margin: theme.spacing(4, 0, 1),
  },
  languageSelectorContainer: {
    width: "100%",
    textAlign: "right",
    padding: theme.spacing(4, 0, 1),
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(2),
    gap: theme.spacing(3),
    "& .expand-btn": {margin: theme.spacing(2, 0)},
  },
}));

export default useExperienceActionsStyles;
