import React, {useContext} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useHistory, useRouteMatch} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {withLDConsumer} from "launchdarkly-react-client-sdk";
// UI
import {Waypoint} from "react-waypoint";
import {
  Box,
  Button,
  CardActionArea,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import useThreadListStyles from "styles/useThreadListStyles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import RightArrowIcon from "@material-ui/icons/ArrowForwardIos";
import RefreshIcon from "@material-ui/icons/Refresh";
// Custom
import MobileInboxes from "components/MultiOption/MobileInboxes";
import {EmptyListPanel} from "components/Helpers/EmptyPanels";
import VirtualizedList from "components/Lists/VirtualizedList";
import CustomCardHeader from "core/cards/CustomCardHeader";
import GuestDot from "components/Misc/GuestDot";
import CustomMenu from "core/menus/CustomMenu";
import SearchBar from "core/bars/SearchBar";
import WarningAlert from "core/alerts/WarningAlert";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
// Actions
import {
  getGuests,
  setInboxData,
  updateGuest as updGuest,
} from "redux/actions/guestsActions";
import {setSelectedViewItem} from "redux/actions/accountsActions";
import {setStartKeys, setTotals} from "redux/actions/settingsActions";
// Helpers & Utilities
import {
  guestFilters,
  guestTypes,
  inboxesWithNoCount,
} from "configuration/constants";
import {
  getAffectedInboxArray,
  getEncodedFilters,
  sleep,
  timeSince,
} from "utilities/helperFunctions.js";
import {FIXED_SIZES, THEME} from "configuration/settings.js";
import {MessagesContext} from "routes/Messages";
import usePrevious from "hooks/usePrevious";
import clsx from "clsx";
import _ from "lodash";
import qs from "qs";

const GuestList = ({
  isMobile,
  refreshing,
  setRefreshing,
  tab,
  setTab,
  flags,
  loadingInbox,
  setLoadingInbox,
  onGuestClick,
  archiveChats,
}) => {
  const classes = useThreadListStyles();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch("/admin/messages/:guest_id");
  const mobileChatMatch = useRouteMatch("/admin/messages/chat/:guest_id");
  const mobileProfileMatch = useRouteMatch("/admin/messages/profile/:guest_id");
  const {modifiedData, setModifiedData} = useContext(MessagesContext);
  const isLoadingMore = React.useRef(false);
  const initialInboxLoading = React.useRef(true);
  const listRef = React.useRef(null);
  const refreshCont = React.useRef(0);
  const scrollPos = React.useRef(0);
  const tabRef = React.useRef(0);
  const updatedSearchText = React.useRef("");
  const didLoadScrollPos = React.useRef(false);
  const contentRef = React.useRef(null);
  const didRefresh = React.useRef(false);
  // Selectors
  const guests = useSelector((state) => state.defaultReducer.guests);
  const guests_dict = useSelector((state) => state.defaultReducer.guests_dict);
  const start_keys = useSelector((state) => state.defaultReducer.start_keys);
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const totals = useSelector((state) => state.defaultReducer.totals);
  const loadingGuests = useSelector(
    (state) => state.defaultReducer.loading,
  ).guests;
  const hasError = useSelector((state) => state.defaultReducer.errors).guests
    .hasError;
  const isMobileView =
    useSelector((state) => state.defaultReducer.deviceType) === "mobile";
  const listingGroups = useSelector(
    (state) => state.defaultReducer.listing_groups,
  );
  const serviceAccounts = useSelector(
    (state) => state.defaultReducer.service_accounts,
  );
  const selectedViewItems = useSelector(
    (state) => state.defaultReducer.selected_view_items,
  );
  const inboxes = useSelector((state) => state.defaultReducer.inbox_data);
  let paramsGuestId =
    mobileChatMatch?.params?.guest_id ??
    mobileProfileMatch?.params?.guest_id ??
    match?.params.guest_id ??
    selectedViewItems.messages.item;
  // State
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [data, setData] = React.useState(guests);
  const [filters, setFilters] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [startPoint, setStartPoint] = React.useState(0);
  const [pullChange, setPullChange] = React.useState();
  const [scrollToIndex, setScrollToIndex] = React.useState(0);
  const [totalData, setTotalData] = React.useState(totals.guests);
  const [selectedGuestId, setSelectedGuestId] = React.useState(
    paramsGuestId ?? null,
  );
  const [hasNextPage, setHasNextPage] = React.useState(
    start_keys.guests === null ? true : start_keys.guests,
  );
  const [searchLoading, setSearchLoading] = React.useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = React.useState(false);
  // General
  const prevTab = usePrevious(tab);
  const hiddenInboxFilters = React.useMemo(() => {
    let inboxesKeys = Object.keys(inboxes);
    let newHiddenInboxFilters = {};
    if (!inboxesKeys.length) {
      return newHiddenInboxFilters;
    } else {
      _.each(inboxesKeys, (ik) => {
        newHiddenInboxFilters[ik] =
          inboxes[ik].filters.map((f) => f.path) ?? [];
      });
      return newHiddenInboxFilters;
    }
  }, [inboxes]);
  let syncInProgress =
    serviceAccounts.sync_in_progress?.action === "SYNC_BOOKINGS";
  const allFilters = React.useMemo(() => {
    let newFilters = [...guestFilters.filterKeys];
    if (listingGroups.length < 2) {
      newFilters = newFilters.filter((f) => f !== "guest_group_id");
    }
    if (tab !== "all") {
      newFilters = newFilters.filter(
        (f) =>
          !!inboxes[tab] &&
          !inboxes[tab].filters.find((inboxFilt) => inboxFilt.path === f),
      );
    }
    return newFilters;
  }, [listingGroups, tab]);

  const filterArchivedGuests = () => {
    let newGuests = guests.map((d) => guests_dict[d.guest_id] ?? d);
    return newGuests.filter((g) => !g.tags?.includes("archived"));
  };

  React.useEffect(() => {
    if (didLoadScrollPos.current) {
      return;
    }
    const scrollPosition = Number(
      sessionStorage.getItem("messagingScrollPosition"),
    );
    const scrollIndex = Number(sessionStorage.getItem("messagingScrollIndex"));
    if (!!listRef.current && !!scrollIndex && !didRefresh.current) {
      didLoadScrollPos.current = true;
      scrollPos.current = scrollPosition;
      setScrollToIndex((prev) => scrollIndex);
      sessionStorage.removeItem("messagingScrollPosition");
      sessionStorage.removeItem("messagingScrollIndex");
    }
  });

  React.useEffect(() => {
    updateGuestListWithModifiedData(guests_dict[modifiedData.guest]);
  }, [modifiedData]);

  React.useEffect(() => {
    let timer = null;
    if (!selectedGuestId || isMobileView) {
      return;
    }
    const newGuest = guests_dict[selectedGuestId];
    if (!newGuest) {
      return;
    }
    timer = setTimeout(() => {
      history.replace(`/admin/messages/${selectedGuestId}`);
    });

    return () => clearTimeout(timer);
  }, [selectedGuestId]);

  React.useEffect(() => {
    if (!searchText && !filters.length && tab === "all") {
      setData((prev) => filterArchivedGuests());
      setTotalData((prev) => totals.guests);
    } else {
      setData((prev) => prev.map((g) => guests_dict[g.guest_id] ?? g));
    }
    if (!selectedGuestId && !!guests.length) {
      setSelectedGuestId(guests[0].guest_id);
    }
  }, [guests, totals]);

  React.useEffect(() => {
    let timer = null;
    let newTab = prevTab !== tab ? tab : undefined;
    if (!!newTab && !initialInboxLoading.current) {
      isLoadingMore.current = true;
    }
    if (newTab === "all") {
      tabRef.current = "all";
      setLoadingInbox((prev) => false);
      setFilters((prev) => []);
      setData((prev) => filterArchivedGuests());
      return;
    } else if (!!newTab && newTab !== "all") {
      tabRef.current = newTab;
      setLoadingInbox((prev) => true);
      setFilters((prev) => inboxes[tab]?.filters ?? []);
      return;
    } else if (!searchText && !filters.length && tab === "all") {
      setTotalData((prev) => totals.guests);
      setData((prev) => filterArchivedGuests());
      setHasNextPage((prev) =>
        start_keys.guests === null ? true : start_keys.guests,
      );
      scrollToTop();
    } else {
      if (prevTab !== tab) {
        timer = setTimeout(() => {
          loadData(tab !== "all");
        }, 300);
      } else {
        loadData(tab !== "all");
      }
    }

    return () => clearTimeout(timer);
  }, [searchText, filters, tab]);

  React.useEffect(() => {
    if (!contentRef.current) {
      return;
    }
    let el = contentRef.current;
    el.addEventListener("touchstart", pullStart);
    el.addEventListener("touchmove", pull);
    el.addEventListener("touchend", pullEnd);
    return () => {
      el.removeEventListener("touchstart", pullStart);
      el.removeEventListener("touchmove", pull);
      el.removeEventListener("touchend", pullEnd);
    };
  });

  const refreshList = () => {
    const params = `enso_key=${current_user}`;
    didRefresh.current = true;
    setSelectedGuestId((prev) => null);
    setScrollToIndex((prev) => 0);
    dispatch(
      getGuests({
        params,
        onSuccess: () => setRefreshing(false),
        onError: () => setRefreshing(false),
      }),
    );
  };

  const initLoading = () => {
    refreshCont.current.classList.add("loading");
    setRefreshing(true);
    refreshList();
  };

  const pullStart = (e) => {
    if (scrollPos.current > 0) {
      return;
    }
    const {screenY} = e.targetTouches[0];
    setStartPoint(screenY);
  };

  const pull = (e) => {
    if (scrollPos.current > 0) {
      if (pullChange > 0) {
        setPullChange(0);
      }
      return;
    }
    const touch = e.targetTouches[0];
    const {screenY} = touch;
    let pullLength = startPoint < screenY ? Math.abs(screenY - startPoint) : 0;
    setPullChange(pullLength);
  };

  const pullEnd = (e) => {
    if (scrollPos.current > 0) {
      return;
    }
    setStartPoint(0);
    setPullChange(0);
    if (pullChange > 220) {
      initLoading();
    }
  };

  const onListScroll = ({clientHeight, scrollHeight, scrollTop}) => {
    scrollPos.current = scrollTop;
    handleMenuClose();
  };

  function scrollToTop() {
    if (!!listRef.current) {
      listRef.current.scrollToRow(0);
    }
  }

  const loadData = (isInboxSelected) => {
    let params = `enso_key=${current_user}&start=0`;
    let queryFilters = isInboxSelected
      ? filters
      : [...filters, {path: "guest_tags", value: "archived", operator: "!="}];
    if (!!searchText) {
      params += `&${qs.stringify({query: searchText})}`;
    }
    if (!!filters.length) {
      params += `&filters=${getEncodedFilters(queryFilters)}`;
    }

    dispatch(
      getGuests({
        params,
        add: true,
        onSuccess: (response) => {
          if (searchText !== updatedSearchText.current) {
            return;
          } else if (!!searchLoading) {
            setSearchLoading((prev) => false);
          }
          let updateResults =
            (isInboxSelected && tabRef.current === tab) ||
            (!isInboxSelected && tabRef.current === "all");
          if (updateResults) {
            setTotalData((prev) => response.count);
            setHasNextPage((prev) => response.start_key);
            setData((prev) => response.hits);
            isLoadingMore.current = false;
            if (initialInboxLoading.current) {
              initialInboxLoading.current = false;
            }
          }
          if (isInboxSelected) {
            setLoadingInbox((prev) => false);
          }
        },
        onError: () => {
          setTotalData((prev) => 0);
          setHasNextPage((prev) => false);
          setData((prev) => []);
          if (loadingInbox) {
            setLoadingInbox((prev) => false);
          }
        },
      }),
    );
  };

  const loadNextPage = () => {
    isLoadingMore.current = true;
    let params = `enso_key=${current_user}&start=${data.length}`;
    if (!!searchText) {
      params += `&${qs.stringify({query: searchText})}`;
    }
    if (!!filters.length) {
      params += `&filters=${getEncodedFilters(filters)}`;
    }

    dispatch(
      getGuests({
        params,
        add: true,
        onSuccess: (response) => {
          setHasNextPage((prev) => response.start_key);
          if (!searchText && !filters.length && tab === "all") {
            dispatch(setStartKeys("guests", response.start_key));
          } else {
            setData((prev) => prev.concat(response.hits));
          }
          isLoadingMore.current = false;
        },
        onError: () => {
          isLoadingMore.current = false;
        },
      }),
    );
  };

  const handleGuestClick = (guest, ind) => () => {
    const newGuest = guests_dict[guest.guest_id];
    if (!!anchorEl && selectedGuestId !== guest.guest_id) {
      handleMenuClose();
    }
    setSelectedGuestId((prev) => guest.guest_id);
    dispatch(setSelectedViewItem("messages", guest.guest_id));

    if (newGuest.unread_messages) {
      if (isMobileView) {
        sleep(THEME.transitions.mobileScreens + 100).then(() => {
          updateGuest(newGuest, "unread_messages", false);
        });
      } else {
        updateGuest(newGuest, "unread_messages", false);
      }
    }
    if (isMobile) {
      sessionStorage.setItem("messagingScrollPosition", scrollPos.current);
      sessionStorage.setItem("messagingScrollIndex", ind);
    }
    onGuestClick(guest.guest_id);
  };

  const updateGuest = (guest, field, val) => {
    dispatch(
      updGuest({guest_id: guest.guest_id, field, val, disableAlert: true}),
    );
  };

  const updateGuestMultipleFields = (guestId, multipleFields) => {
    dispatch(updGuest({guest_id: guestId, multipleFields, disableAlert: true}));
  };

  const handleDeleteFilter = (filter, isDateRange) => {
    setFilters((prevFilters) => {
      if (isDateRange) {
        return prevFilters.filter(
          (f) => !["checkin_date", "checkout_date"].includes(f.path),
        );
      } else {
        const newFilters = [...prevFilters];
        const filterInd = prevFilters.findIndex(
          (f) => f.path === filter.path && f.value === filter.value,
        );
        if (filterInd !== -1) {
          newFilters.splice(filterInd, 1);
        }
        return newFilters;
      }
    });
  };

  const handleMenuClose = () => setAnchorEl(null);
  const handleMenuOpen = (e) => setAnchorEl(e.currentTarget);

  const markGuest = (guestId, field, value) => () => {
    if (!guestId) {
      handleMenuClose();
      return;
    }

    if (value) {
      if (
        !guests_dict[guestId].unread_messages &&
        !guests_dict[guestId].unanswered_messages
      ) {
        // If current tab is NOT "all" or "archived"
        if (!inboxesWithNoCount.includes(tab)) {
          let newInbox = inboxes[tab];
          newInbox.total_count++;
          dispatch(setInboxData({...inboxes, [tab]: newInbox}));
        }
        // Increment affected inbox totals
        setModifiedData({
          forceInboxesUpdate: true,
          addToInbox: getAffectedInboxArray(guests_dict[guestId], tab),
        });
      }
    } else {
      let updatedGuest =
        field === "unanswered_messages"
          ? {
              ...guests_dict[guestId],
              unanswered_messages: value,
              unread_messages: value,
            }
          : {...guests_dict[guestId], [field]: value};
      if (!updatedGuest.unread_messages && !updatedGuest.unanswered_messages) {
        // If current tab is NOT "all" or "archived"
        if (!inboxesWithNoCount.includes(tab)) {
          let newInbox = inboxes[tab];
          newInbox.total_count--;
          dispatch(setInboxData({...inboxes, [tab]: newInbox}));
        }
        // Decrement affected inbox totals
        setModifiedData({
          forceInboxesUpdate: true,
          subtractFromInbox: getAffectedInboxArray(updatedGuest, tab),
        });
      }
    }

    if (!!isMobileView) {
      sleep(THEME.transitions.mobileScreens + 100).then(() => {
        if (field === "unread_messages") {
          updateGuestMultipleFields(guestId, {
            unread_messages: true,
            unanswered_messages: true,
          });
        } else {
          updateGuest(guests_dict[guestId], field, value);
        }
      });
    } else {
      if (field === "unread_messages") {
        updateGuestMultipleFields(guestId, {
          unread_messages: true,
          unanswered_messages: true,
        });
      } else {
        updateGuest(guests_dict[guestId], field, value);
      }
    }
    handleMenuClose();
  };

  const updateInboxCount = (newInbox, newInboxes, add) => {
    let inb = inboxes[newInbox];
    if (!!inb) {
      if (add) {
        inb.total_count++;
      } else {
        inb.total_count--;
      }
      newInboxes[newInbox] = inb;
    }
  };

  function updateInboxesTotals() {
    let newInboxes = {...inboxes};

    // Add
    if (Array.isArray(modifiedData.addToInbox)) {
      modifiedData.addToInbox.forEach((inb) => {
        updateInboxCount(inb, newInboxes, true);
      });
    } else {
      updateInboxCount(modifiedData.addToInbox, newInboxes, true);
    }

    // Subtract
    if (Array.isArray(modifiedData.subtractFromInbox)) {
      modifiedData.subtractFromInbox.forEach((inb) => {
        updateInboxCount(inb, newInboxes);
      });
    } else {
      updateInboxCount(modifiedData.subtractFromInbox, newInboxes);
    }

    // Update inboxes
    dispatch(setInboxData(newInboxes));
  }

  // Updates guest list items, searchbar total & inbox tab total for all inboxes
  const updateGuestListWithModifiedData = (selectedGuest) => {
    if (!!selectedGuest?.guest_id) {
      let newDataArray =
        tab === "all" ? Object.assign([], guests) : Object.assign([], data);
      let newData = [...newDataArray];
      let isAddInboxAnArray = Array.isArray(modifiedData.addToInbox);
      let isSubtractInboxAnArray = Array.isArray(
        modifiedData.subtractFromInbox,
      );

      // Update inbox totals if guest has unread/unanswered messages
      if (
        isAddInboxAnArray
          ? modifiedData.addToInbox.includes(tab)
          : tab === modifiedData.addToInbox
      ) {
        newData.unshift(selectedGuest);
      } else if (
        isSubtractInboxAnArray
          ? modifiedData.subtractFromInbox.includes(tab)
          : tab === modifiedData.subtractFromInbox
      ) {
        newData = newDataArray.filter(
          (g) => g.guest_id !== selectedGuest.guest_id,
        );
      }

      // Update "all" tab total if affected
      if (
        isSubtractInboxAnArray
          ? modifiedData.subtractFromInbox.includes("all")
          : modifiedData.subtractFromInbox === "all"
      ) {
        dispatch(setTotals("guests", totals.guests - 1));
      } else if (
        isAddInboxAnArray
          ? modifiedData.addToInbox.includes("all")
          : modifiedData.addToInbox === "all"
      ) {
        dispatch(setTotals("guests", totals.guests + 1));
      }

      // Update current tab total and guest list if affected
      let isPriorityGuest =
        !!selectedGuest.unanswered_messages || !!selectedGuest.unread_messages;
      if (isPriorityGuest) {
        updateInboxesTotals();
      }
      setTotalData((prev) => prev - 1);
      setData((prev) => newData);
    } else if (modifiedData.forceInboxesUpdate) {
      updateInboxesTotals();
    }
  };

  const handleArchive = (guestId) => {
    setModifiedData({
      addToInbox: "archived",
      guest: guestId,
      subtractFromInbox: getAffectedInboxArray(
        guests_dict[guestId],
        tab,
        "all",
        true,
      ),
    });
    archiveChats(guestId);
    handleMenuClose();
  };

  const handleUnarchive = (guestId, guestTags) => {
    setModifiedData({
      addToInbox: getAffectedInboxArray(guests_dict[guestId], tab, "all", true),
      guest: guestId,
      subtractFromInbox: "archived",
    });
    updateGuest(
      guests_dict[guestId],
      "tags",
      guestTags.filter((gt) => gt !== "archived"),
    );
    handleMenuClose();
  };

  const handleCloseArchiveModal = () => {
    setArchiveModalOpen((prev) => false);
  };

  const handleConfirmArchive = () => {
    handleCloseArchiveModal();
    archiveChats();
  };

  const icons = {
    VRBO: (
      <img
        key="vrbo"
        alt="vrbo logo"
        className={classes.icon}
        src={require("assets/img/vrbo-V-logo.png")}
      />
    ),
    Airbnb: (
      <img
        key="airbnb"
        alt="airbnb logo"
        className={classes.icon}
        src={require("assets/img/airbnb_stock.png")}
      />
    ),
    Expedia: (
      <img
        key="expedia"
        alt="expedia logo"
        className={classes.icon}
        src={require("assets/img/expedia.png")}
      />
    ),
    Google: (
      <img
        key="google"
        alt="google logo"
        className={classes.icon}
        src={require("assets/img/google.png")}
      />
    ),
    "Booking.com": (
      <img
        key="bookingcom"
        alt="bookingcom logo"
        className={classes.icon}
        src={require("assets/img/bookingcom.png")}
      />
    ),
  };

  function getDropdownItem(label, onClick, isArchive) {
    return (
      <ListItem
        button
        disableRipple
        className={classes.dropdownListItem}
        onClick={onClick}
      >
        <ListItemText
          className={classes.itemText}
          primary={label}
          primaryTypographyProps={{
            className: clsx(classes.itemTitle, {
              [classes.archiveMenuItem]: isArchive,
            }),
          }}
        />
      </ListItem>
    );
  }

  function getItemMoreMenu(guestId, selected) {
    let unanswered = guests_dict[guestId]?.unanswered_messages === true;
    let isArchived = guests_dict[guestId]?.tags?.includes("archived");
    let guestType = guests_dict[guestId]?.guest_type || "guest";
    let guestTags = guests_dict[guestId]?.tags || [];
    let availableGuestTypes = guestTypes.filter(
      (gt) => !flags.inboxes.includes(gt) && gt !== guestType,
    );
    return (
      <>
        <IconButton size="small" edge="end" onClick={handleMenuOpen}>
          <MoreVertIcon
            className={clsx(classes.moreIcon, {selected: !!selected})}
            color="action"
          />
        </IconButton>
        {!!anchorEl && (
          <CustomMenu
            open={!!anchorEl}
            anchorEl={anchorEl}
            content={
              <List className={classes.dropdownList}>
                {getDropdownItem(
                  t("mark_unread"),
                  markGuest(guestId, "unread_messages", true),
                )}
                {getDropdownItem(
                  unanswered ? t("mark_answered") : t("mark_unanswered"),
                  markGuest(
                    guestId,
                    "unanswered_messages",
                    unanswered ? false : true,
                  ),
                )}
                <Divider className={classes.divider} />
                {availableGuestTypes.map((avalGT) => {
                  return (
                    <React.Fragment key={avalGT}>
                      {getDropdownItem(t(`switch_${avalGT}`), () => {
                        setModifiedData({
                          addToInbox: avalGT,
                          guest: guestId,
                          subtractFromInbox: guestType,
                        });
                        updateGuest(guests_dict[guestId], "guest_type", avalGT);
                        handleMenuClose();
                      })}
                    </React.Fragment>
                  );
                })}
                <Divider className={classes.divider} />
                {!isArchived &&
                  getDropdownItem(
                    t("archive_conversation"),
                    () => handleArchive(guestId),
                    true,
                  )}
                {!!isArchived &&
                  getDropdownItem(t("unarchive_conversation"), () =>
                    handleUnarchive(guestId, guestTags),
                  )}
              </List>
            }
            onClose={handleMenuClose}
          />
        )}
      </>
    );
  }

  function getItem({index}) {
    const guest = data[index];
    const selected = selectedGuestId === guest.guest_id;

    return (
      <>
        {hasNextPage && index === data.length - 10 && (
          <Waypoint onEnter={() => loadNextPage()} />
        )}
        <Box className={classes.listItemContainer}>
          <ListItem
            button
            disableRipple
            selected={isMobileView ? false : selected}
            className={clsx(classes.listItem, {selected: selected})}
            id={`thread-item-${index}`}
          >
            <CardActionArea
              disableRipple
              className={classes.cardActionArea}
              onClick={handleGuestClick(guest, index)}
            >
              <div className={classes.dotContainer}>
                {(guest.unread_messages || guest.unanswered_messages) && (
                  <Tooltip
                    title={
                      !!guest.unanswered_messages && !guest.unread_messages
                        ? "Unanswered messages"
                        : "Unread messages"
                    }
                  >
                    <span>
                      <GuestDot
                        white={selected}
                        isUnread={guest.unread_messages}
                        isUnanswered={guest.unanswered_messages}
                      />
                    </span>
                  </Tooltip>
                )}
              </div>
              <div className={classes.container}>
                <div className={classes.row}>
                  <Typography
                    variant="h1"
                    className={clsx(classes.ellipsis, classes.name, {
                      selected: selected,
                    })}
                  >
                    {guest.name}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={clsx(classes.timeSince, classes.subdued, {
                      selected: selected,
                    })}
                  >
                    {timeSince(guest.updated_at, true)}
                  </Typography>
                </div>
                <div className={clsx(classes.row, classes.secondaryText)}>
                  <Typography
                    variant="body1"
                    className={clsx(
                      classes.ellipsis,
                      classes.subdued,
                      classes.previewMsg,
                      {selected: selected},
                    )}
                  >
                    {guest.message_preview}
                  </Typography>
                </div>
              </div>
            </CardActionArea>
            {isMobileView ? (
              <div className={classes.iconsColumn}>
                {getItemMoreMenu(guest.guest_id, selected)}
                <div className={classes.iconContainer}>
                  {_.uniq(guest.channels).map((c) => icons[c])}
                </div>
              </div>
            ) : (
              <div className={classes.iconsColumn}>
                {selected ? (
                  getItemMoreMenu(guest.guest_id, selected)
                ) : (
                  <IconButton
                    size="small"
                    style={{padding: 0}}
                    onClick={handleGuestClick(guest, index)}
                  >
                    <RightArrowIcon className={classes.arrow} />
                  </IconButton>
                )}
                <div className={classes.iconContainer}>
                  {_.uniq(guest.channels).map((c) => icons[c])}
                </div>
              </div>
            )}
          </ListItem>
        </Box>
      </>
    );
  }

  const searchbar = (
    <div className={classes.searchContainer}>
      <SearchBar
        enableFilters
        disableGutters
        filters={filters}
        placeholder={
          loadingInbox ? "Loading inbox..." : `Search ${totalData} guests`
        }
        filterKeys={allFilters}
        searchInput={searchText}
        preferredFilters={guestFilters.preferredFilters}
        hiddenFilters={
          tab !== tabRef.current
            ? filters.map((f) => f.path)
            : tab !== "all"
              ? hiddenInboxFilters[tab]
              : undefined
        }
        handleDeleteFilter={handleDeleteFilter}
        handleSearchInput={(val) => {
          updatedSearchText.current = val;
          setSearchLoading((prev) => true);
          setSearchText((prev) => val);
        }}
        setFilters={(newFilters) => setFilters((prev) => newFilters)}
      />
    </div>
  );

  const getThreadList = () => {
    if (start_keys.guests === null) {
      return null;
    }
    if (loadingInbox) {
      return (
        <EmptyListPanel
          disableTopSpacing
          loading
          list="messages"
          noTitle
          noSearchbar
        />
      );
    }
    return (loadingGuests || searchLoading) &&
      !isLoadingMore.current &&
      (!!searchText || !!filters.length || tab !== "all") ? (
      <EmptyListPanel
        disableTopSpacing
        loading
        list="messages"
        noTitle
        noSearchbar
      />
    ) : (
      <VirtualizedList
        hideScrollbar
        listRef={listRef}
        getRowItem={getItem}
        listClass={classes.list}
        rowHeight={FIXED_SIZES.guests}
        totalRows={data.length}
        scrollToAlignment="start"
        onScroll={onListScroll}
        scrollToIndex={
          isMobileView && !!scrollToIndex ? scrollToIndex : undefined
        }
      />
    );
  };

  const confirmArchiveModal = (
    <ConfirmDialog
      disableDismiss
      open={archiveModalOpen}
      onClose={handleCloseArchiveModal}
      title={t("archive_confirmation_title")}
      message={t("archive_confirmation_text")}
      confirmLabel={t("archive_messages")}
      confirmAction={handleConfirmArchive}
      cancelLabel={t("cancel")}
      cancelAction={handleCloseArchiveModal}
    />
  );

  return start_keys.guests === null || refreshing ? (
    <Box pt={2} ml={-3} mr={-3}>
      <EmptyListPanel
        noTitle
        disableTopSpacing
        disableBottomSpacing
        customListPadding={0}
        loading={!hasError}
        list="messages"
      />
    </Box>
  ) : (
    <div className={classes.root}>
      {confirmArchiveModal}
      <CustomCardHeader
        type="header"
        className={classes.header}
        title={isMobileView && t("messages")}
        action={
          isMobileView && <MobileInboxes selected={tab} onClick={setTab} />
        }
      />
      {searchbar}
      {syncInProgress && (
        <Box px={4} pb={2}>
          <WarningAlert
            sync
            small
            disableMargin
            title={t("booking_sync_progress")}
            subtitle={t("updating_from_pms")}
          />
        </Box>
      )}
      <div ref={contentRef} className={classes.content}>
        <div
          ref={refreshCont}
          className={classes.refreshSection}
          style={{top: pullChange / 3.5 || ""}}
        >
          {refreshing ? (
            <CircularProgress size={24} color="secondary" />
          ) : (
            <RefreshIcon
              className="refresh-icon"
              style={{transform: `rotate(${pullChange}deg)`}}
            />
          )}
        </div>
        {getThreadList()}
      </div>
      {tab === "all" && (
        <Box className={classes.archiveBtnContainer}>
          <Button
            disableRipple
            className={classes.archiveBtn}
            onClick={() => setArchiveModalOpen((prev) => true)}
          >
            {t("archive_all")}
          </Button>
        </Box>
      )}
    </div>
  );
};

export default withLDConsumer()(GuestList);
