import React from "react";
import {MenuItem} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import FilledSelect from "core/selects/FilledSelect";
import {metricsParams} from "configuration/specs";
import {bookingChannelLabels} from "configuration/constants";

const availableChannels = bookingChannelLabels;

const useStyles = makeStyles((theme) => ({
  selectorContainer: {
    width: "100%",
    height: "100%",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  icon: {
    width: 18,
    height: 18,
    objectFit: "contain",
    margin: 4,
    marginLeft: 0,
  },
}));

export default function Channels({
  channels = [],
  customFieldData = {},
  edit,
  value,
  onValueChange,
}) {
  const classes = useStyles();
  const [selectedChannel, setSelectedChannel] = React.useState(value);

  React.useEffect(() => {
    if (edit && value !== selectedChannel) {
      setSelectedChannel((prev) => value);
    }
  }, [value]);

  React.useEffect(() => {
    if (edit) {
      onValueChange(selectedChannel);
    }
  }, [selectedChannel]);

  const icons = React.useMemo(() => {
    return {
      VRBO: (
        <img
          key="vrbo"
          className={classes.icon}
          src={require("assets/img/vrbo-V-logo.png")}
        />
      ),
      Airbnb: (
        <img
          key="airbnb"
          className={classes.icon}
          src={require("assets/img/airbnb_stock.png")}
        />
      ),
      Expedia: (
        <img
          key="expedia"
          className={classes.icon}
          src={require("assets/img/expedia.png")}
        />
      ),
      Google: (
        <img
          key="google"
          className={classes.icon}
          src={require("assets/img/google.png")}
        />
      ),
      "Booking.com": (
        <img
          key="bookingcom"
          className={classes.icon}
          src={require("assets/img/bookingcom.png")}
        />
      ),
    };
  }, []);

  const handleChannelChange = (e) => {
    const val = e.target.value;
    setSelectedChannel((prev) => val);
  };
  let customChannels =
    customFieldData["Booking"]?.find((c) => c.field == "booking_channel")
      ?.data || [];
  if (edit) {
    return (
      <div className={classes.selectorContainer}>
        <FilledSelect
          fullWidth
          label="Channel"
          value={selectedChannel}
          onChange={handleChannelChange}
        >
          <MenuItem value="" disabled>
            Select a channel
          </MenuItem>
          {Object.keys(availableChannels)
            .concat(customChannels)
            .map((ch) => (
              <MenuItem value={ch} key={ch}>
                {availableChannels[ch] || ch}
              </MenuItem>
            ))}
        </FilledSelect>
      </div>
    );
  } else {
    return (
      <div className={classes.iconContainer}>
        {channels.map((c) => icons[c] ?? (availableChannels[c] || c))}
      </div>
    );
  }
}
