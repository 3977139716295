import React from "react";
import {Box, Link} from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";
import CustomCardHeader from "../../../../core/cards/CustomCardHeader";
import PrimaryButton from "../../../../core/buttons/PrimaryButton";
import SendIcon from "@material-ui/icons/Send";

export default function CrmCardHeader({view, setView, handleActionClick}) {
  const theme = useTheme();

  const linkStyles = (isSelected) => ({
    color: isSelected
      ? theme.palette.primary.main
      : theme.palette.primary.light,
    fontWeight: isSelected ? "bold" : "normal",
    textDecoration: "none",
    cursor: "pointer",
  });

  return (
    <CustomCardHeader
      sx={{
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingBottom: 0,
        marginBottom: -10,
      }}
      title={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(2),
          }}
        >
          <Link
            onClick={() => setView("guests")}
            style={linkStyles(view === "guests")}
            color="inherit"
            underline="none"
          >
            Guests
          </Link>
          <Link
            onClick={() => setView("campaigns")}
            style={linkStyles(view === "campaigns")}
            color="inherit"
            underline="none"
          >
            Campaigns
          </Link>
        </Box>
      }
      action={
        <PrimaryButton
          rounded={true}
          color={"secondary"}
          label="New Campaign"
          variant="contained"
          onClick={() => handleActionClick("inst")}
        />
      }
    />
  );
}
