import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
// UI
import {
  Box,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import useDashboardStyles from "styles/useDashboardStyles";
import ArrowIcon from "@material-ui/icons/NavigateNext";
import DoneIcon from "@material-ui/icons/Done";
// Custom
import TimePeriodModal from "components/Dialogs/TimePeriodModal";
import BookingCard from "components/Cards/Dashboard/BookingCard";
import EmptyContentText from "components/Misc/EmptyContentText";
import ExpandButton from "core/buttons/ExpandButton";
import GroupsList from "components/Lists/GroupsList";
import CustomMenu from "core/menus/CustomMenu";
// Redux
import {
  getDashboardSummary,
  setDashboardData,
  triggerDashboardRefresh,
} from "redux/actions/dashboardActions";
import {createAction} from "redux/actions/experiencesActions";
import {editBooking} from "redux/actions/bookingsActions";
import {editGuest} from "redux/actions/guestsActions";
// Utils
import {
  addDays,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  endOfDay,
  startOfDay,
} from "date-fns";
import {capitalize, sleep} from "utilities/helperFunctions";
import clsx from "clsx";
import _ from "lodash";
const pageLimit = 50;
const now = Date.now();

function getBucket(bckt, isDataBucket) {
  switch (bckt) {
    case "checkins":
      return isDataBucket ? "checkins" : "checkin";
    case "guests-in-house":
      return "inhouse";
    case "checking-out":
      return isDataBucket ? "checkouts" : "checkout";
    case "up-checkins":
      return isDataBucket ? "checkins" : "checkin";
    case "up-checking-out":
      return isDataBucket ? "checkouts" : "checkout";

    default:
      return null;
  }
}

function getFilterLabel(filter, lowerCase) {
  let label = filter;
  switch (filter) {
    case "all":
      label = "All";
      break;
    case "verify_guest":
      label = "Verifications";
      break;
    case "pending_upsell":
      label = "Upsells";
      break;
    case "message":
      label = "Messages";
      break;
    default:
      break;
  }
  if (!!lowerCase) {
    return label.toLowerCase();
  } else {
    return label;
  }
}

function ListItemEndElement({loading, isMessage, total = 0}) {
  const classes = useDashboardStyles();
  let complete = total === 0;

  return loading ? (
    <Skeleton
      animation="wave"
      className={clsx(classes.skeleton, classes.mark, classes.loadingMark)}
    />
  ) : (
    <Tooltip title={isMessage ? "Messages" : "Pending actions"}>
      <div
        className={clsx(classes.row, classes.centered, classes.mark, {
          complete: complete,
          missing: !complete,
          message: !!isMessage && !complete,
        })}
      >
        {complete ? (
          <DoneIcon className={classes.doneIcon} />
        ) : (
          <Typography variant="subtitle2" className={classes.listItemTotal}>
            {total > 99 ? "99+" : total}
          </Typography>
        )}
      </div>
    </Tooltip>
  );
}

function Filter({
  id,
  mobile,
  selectedFilter,
  disabled,
  onClick,
  section,
  data,
}) {
  const classes = useDashboardStyles();
  let isSelected = selectedFilter === id;
  let dataSection = section.startsWith("up-") ? "upcoming" : "today";
  let bucket = getBucket(section, true);
  let count =
    (data[dataSection]?.[bucket]?.actions?.[id] ?? 0) > 99
      ? "99+"
      : data[dataSection]?.[bucket]?.actions?.[id];
  let filterLabel = getFilterLabel(id);

  let label = {
    all: `${filterLabel}${!!count ? ` (${count})` : ""}`,
    verify_guest: `${filterLabel}${!!count ? ` (${count})` : ""}`,
    pending_upsell: `${filterLabel}${!!count ? ` (${count})` : ""}`,
    message: `${filterLabel}${!!count ? ` (${count})` : ""}`,
  }[id];

  return (
    <Chip
      label={label}
      disableRipple
      disabled={disabled}
      onClick={() => onClick(id)}
      classes={{
        root: clsx(classes.chip, {
          selected: isSelected,
          mobile: mobile && !isSelected,
        }),
        label: clsx(classes.chipLabel, {selected: isSelected}),
      }}
    />
  );
}

const DashboardSummaryPanel = ({
  loading,
  setLoading,
  updateSelectedGroups,
  noData,
  fullRounded,
  openMessagingPanel,
  refreshDasboard,
  onRefreshCompleted,
  mobile,
  selectedGuest,
  initialMobileBucket,
  setInitialMobileBucket,
  initialFilter,
  setInitialFilter,
  unansweredUpdate,
  archiveChat,
  saveSelectedItems = () => {},
}) => {
  const classes = useDashboardStyles();
  const dispatch = useDispatch();
  const didLoad = useRef(!!initialMobileBucket);
  const isChangingGroup = useRef(false);
  const isClosingGroups = useRef(false);
  const prevGroups = useRef([]);
  const buttonClicked = useRef(null);
  const currentSectionLoading = useRef(null);
  const temporarySummaryData = useRef({upcoming: null, today: null});
  const triggerRefresh = useRef({today: false, upcoming: false});
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const groupsArr = useSelector((state) => state.defaultReducer.listing_groups);
  const groupsDict = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );
  const summaryData = useSelector(
    (state) => state.dashboardReducer.summaryData,
  );
  const guests_dict = useSelector((state) => state.defaultReducer.guests_dict);
  const bookings_dict = useSelector(
    (state) => state.defaultReducer.bookings_dict,
  );
  const selectedViewItems = useSelector(
    (state) => state.defaultReducer.selected_view_items,
  );
  let savedItem = selectedViewItems.dashboard.item;
  let savedProps = selectedViewItems.dashboard.props;
  const [groupsMenuAnchorEl, setGroupsMenuAnchorEl] = useState(null);
  const [selectedGroups, setSelectedGroups] = useState(savedProps.groups ?? []);
  const [expanded, setExpanded] = useState(savedItem ?? initialMobileBucket);
  const [loadingUpcoming, setLoadingUpcoming] = useState(
    !initialMobileBucket && !savedItem,
  );
  const [loadingSection, setLoadingSection] = useState(!!savedItem);
  const [actionsData, setActionsData] = useState(
    !!initialMobileBucket || !!savedItem
      ? summaryData[
          (savedItem ?? initialMobileBucket).startsWith("up-")
            ? "upcoming"
            : "today"
        ]?.bookings || []
      : null,
  );
  const [dateRange, setDateRange] = useState(
    !!savedItem && savedItem.startsWith("up-") && !!savedProps.upcomingRange
      ? savedProps.upcomingRange
      : [
          startOfDay(new Date(now)).getTime(),
          endOfDay(new Date(addDays(now, 6))).getTime(),
        ],
  );
  const [selectedFilter, setSelectedFilter] = useState(
    initialFilter ?? savedProps.filter ?? "all",
  );
  const [actionsLoading, setActionsLoading] = useState([]);
  const [page, setPage] = useState(1);
  const [removedActions, setRemovedActions] = useState([]);
  let users = user_profile.enso_users;
  let currentUser = users?.find(
    (u) => u.enso_user_id === user_profile.enso_user_id,
  );
  let userName = !!loading ? "" : (currentUser?.name?.split(" ")?.[0] ?? "");
  let listingGroups = !!groupsMenuAnchorEl
    ? prevGroups.current
    : selectedGroups;
  let btnLabel = !listingGroups.length
    ? "All listings"
    : listingGroups.length === 1
      ? groupsDict[listingGroups[0]].name
      : listingGroups.length === groupsArr.length - 1
        ? "All groups"
        : `${listingGroups.length} groups`;
  let currData = actionsData || [];

  const saveTemporaryResponse = (res, isUpcoming, newPage) => {
    let when = isUpcoming ? "upcoming" : "today";
    temporarySummaryData.current[when] = {...summaryData[when]};
    if (!!newPage && newPage > 1) {
      // If has pagination
      temporarySummaryData.current[when] = {
        ...res,
        bookings: [...(summaryData[when]?.bookings || []), ...res.bookings],
      };
    } else {
      temporarySummaryData.current[when] = res;
    }
  };

  const getTodayInitialData = ({actionProps = {}, onSuccess = () => {}}) => {
    let now = Date.now();
    let startDateToday = new Date(startOfDay(now)).getTime();
    let endDateToday = new Date(endOfDay(now)).getTime();
    dispatch(
      getDashboardSummary({
        start_date: startDateToday,
        end_date: endDateToday,
        ...actionProps,
        onSuccess: onSuccess,
        onError: () => setLoading(false),
      }),
    );
  };

  const getData = ({
    bckt,
    filter,
    initialUpcomingLoad,
    upcoming,
    latestDateRange = dateRange,
    newPage = page,
    disableUpdate = false,
  }) => {
    let isUpcoming =
      !!initialUpcomingLoad ||
      !!bckt?.startsWith("up-") ||
      (!triggerRefresh.current.today && !!triggerRefresh.current.upcoming) ||
      upcoming;
    let startDate = isUpcoming
      ? latestDateRange[0]
      : new Date(startOfDay(now)).getTime();
    let endDate = isUpcoming
      ? latestDateRange[1]
      : new Date(endOfDay(now)).getTime();
    dispatch(
      getDashboardSummary({
        start_date: startDate,
        end_date: endDate,
        isUpcoming: isUpcoming,
        bucket: getBucket(bckt),
        pending_action: filter,
        listing_group_ids: !!selectedGroups.length ? selectedGroups : null,
        page: newPage,
        disableUpdate:
          !!disableUpdate ||
          (!!triggerRefresh.current.upcoming && !!triggerRefresh.current.today),
        limit: pageLimit,
        onSuccess: (res) => {
          if (
            !!triggerRefresh.current.upcoming ||
            !!triggerRefresh.current.today
          ) {
            if (isUpcoming) {
              triggerRefresh.current = {
                ...triggerRefresh.current,
                upcoming: false,
              };
            } else {
              triggerRefresh.current = {
                ...triggerRefresh.current,
                today: false,
              };
            }

            if (!!expanded && expanded === bckt) {
              setActionsData((prev) =>
                newPage > 1
                  ? [...prev, ...(res.bookings || [])]
                  : (res.bookings ?? null),
              );
            }

            if (
              !triggerRefresh.current.upcoming &&
              !triggerRefresh.current.today
            ) {
              saveTemporaryResponse(res, isUpcoming, newPage);
              dispatch(
                setDashboardData("summaryData", temporarySummaryData.current),
              );
              setLoadingSection((prev) => false);
              onRefreshCompleted();
            } else {
              // Save response in a temporary variable before updating redux store
              saveTemporaryResponse(res, isUpcoming, newPage);
              // If it is upcoming, call getData for 'today' section; else, call getData for 'upcoming' section
              getData({
                bckt: !isUpcoming ? "up-checkins" : null,
                filter: "all",
                disableUpdate: true,
              });
            }
          } else {
            if (
              !!currentSectionLoading.current &&
              currentSectionLoading.current === bckt
            ) {
              currentSectionLoading.current = null;
              setActionsData((prev) =>
                newPage > 1
                  ? [...prev, ...(res.bookings || [])]
                  : (res.bookings ?? null),
              );
              setLoadingSection((prev) => false);
              setLoadingUpcoming((prev) => false);
            } else if (!currentSectionLoading.current) {
              setLoadingSection((prev) => false);
              setLoadingUpcoming((prev) => false);
            }
            if (isChangingGroup.current) {
              isChangingGroup.current = false;
              setLoading(false);
            }
          }
        },
        onError: () => {
          setLoadingSection((prev) => false);
          setLoadingUpcoming((prev) => false);
        },
      }),
    );
  };

  useEffect(() => {
    if (!!refreshDasboard && !triggerRefresh.current.today) {
      triggerRefresh.current = {today: true, upcoming: true};
      getData({bckt: expanded, filter: selectedFilter});
    }
  }, [refreshDasboard]);

  useEffect(() => {
    if (!summaryData.today || !didLoad.current) {
      didLoad.current = true;
      if (!!savedItem) {
        triggerRefresh.current = {today: true, upcoming: true};
        getData({bckt: expanded, filter: selectedFilter});
        return;
      }
      getTodayInitialData({
        onSuccess: () => {
          setLoading(false);
          getData({bckt: null, filter: "all", initialUpcomingLoad: true});
        },
      });
    }
  }, [summaryData]);

  useEffect(() => {
    if (page > 1) {
      getData({bckt: expanded, filter: selectedFilter});
    }
  }, [page]);

  useEffect(() => {
    if (!!setInitialMobileBucket) {
      setInitialMobileBucket(expanded);
    }
  }, [expanded]);

  useEffect(() => {
    if (!!setInitialFilter) {
      setInitialFilter(selectedFilter);
    }
  }, [selectedFilter]);

  useEffect(() => {
    if (!!unansweredUpdate && !!selectedGuest) {
      setActionsData((prev) =>
        prev.map((prevActData) => {
          if (selectedGuest === prevActData.guest.guest_id) {
            return {
              ...prevActData,
              guest: {
                ...prevActData.guest,
                unanswered_messages: unansweredUpdate.value,
              },
            };
          } else {
            return prevActData;
          }
        }),
      );
    }
  }, [unansweredUpdate]);

  const openGroupsMenu = (e) => {
    prevGroups.current = selectedGroups;
    setGroupsMenuAnchorEl(e.currentTarget);
  };

  const closeGroupsMenu = (e) => {
    setGroupsMenuAnchorEl(null);
    if (
      !_.isEqual(prevGroups.current, selectedGroups) &&
      !isClosingGroups.current
    ) {
      isClosingGroups.current = true;
      setLoading(true);
      updateSelectedGroups(selectedGroups);
      let isUpcoming = !!expanded?.startsWith("up-");
      getTodayInitialData({
        actionProps: {
          pending_action: isUpcoming ? "all" : selectedFilter,
          bucket: isUpcoming ? null : getBucket(expanded),
          listing_group_ids: !!selectedGroups.length ? selectedGroups : null,
        },
        onSuccess: () => {
          isClosingGroups.current = false;
          getData({
            bckt: expanded,
            filter: isUpcoming ? selectedFilter : "all",
            upcoming: true,
          });
        },
      });
    }
  };

  const handleGroupChange = (newGroups) => {
    isChangingGroup.current = true;
    setSelectedGroups(newGroups);
    saveSelectedItems(expanded, {
      filter: selectedFilter,
      groups: newGroups,
      upcomingRange: dateRange,
    });
  };

  const handleTimePeriodChange = (newRange, isRangeComplete) => {
    setDateRange((prev) => newRange);
    if (isRangeComplete) {
      saveSelectedItems(expanded, {
        filter: selectedFilter,
        upcomingRange: newRange,
        groups: selectedGroups,
      });
      setLoadingUpcoming((prev) => true);
      setLoadingSection((prev) => true);
      getData({
        bckt: expanded,
        filter: selectedFilter,
        latestDateRange: newRange,
        upcoming: true,
      });
    }
  };

  const handleOpenChat = (bkg) => {
    let hasUnreadMessages = !!bkg?.guest?.unread_messages;
    if (hasUnreadMessages) {
      setActionsData((prev) =>
        prev.map((prevActData) => {
          if (bkg.booking_id === prevActData.booking_id) {
            return {
              ...prevActData,
              guest: {...prevActData.guest, unread_messages: false},
            };
          } else {
            return prevActData;
          }
        }),
      );
    }
    openMessagingPanel(bkg.guest);
  };

  const handleDismiss = (bucket, bkg) => {
    let section = getBucket(bucket, true);
    let isUnreadOrUnanswered =
      !!bkg?.guest?.unread_messages || !!bkg?.guest?.unanswered_messages;
    if (isUnreadOrUnanswered) {
      setActionsData((prev) =>
        prev.map((prevActData) => {
          if (bkg.booking_id === prevActData.booking_id) {
            return {
              ...prevActData,
              guest: {
                ...prevActData.guest,
                unanswered_messages: false,
                unread_messages: false,
              },
            };
          } else {
            return prevActData;
          }
        }),
      );
      let newData = {...summaryData};
      let isUpcoming = bucket.startsWith("up-");
      let newBucket = newData[isUpcoming ? "upcoming" : "today"][section];
      newBucket.total_actions = newBucket.total_actions - 1;
      newBucket.actions.message = newBucket.actions.message - 1;
      dispatch(setDashboardData("summaryData", newData));
    }
    archiveChat(bkg.guest);
  };

  const handleListItemClick = (id) => {
    if (loading) {
      return;
    }
    let isExpanded = expanded === id;
    setSelectedFilter((prev) => "all");
    if (isExpanded) {
      setExpanded((prev) => null);
      setActionsData((prev) => null);
      setPage((prev) => 1);
      saveSelectedItems();
    } else {
      saveSelectedItems(id, {
        filter: selectedFilter,
        upcomingRange: dateRange,
        groups: selectedGroups,
      });
      setExpanded((prev) => id);
      setLoadingSection((prev) => true);
      currentSectionLoading.current = id;
      getData({bckt: id, filter: selectedFilter});
    }
  };

  const handleFilterClick = (filter) => {
    if (selectedFilter === filter) {
      return;
    }
    currentSectionLoading.current = expanded;
    saveSelectedItems(expanded, {
      filter,
      upcomingRange: dateRange,
      groups: selectedGroups,
    });
    setLoadingSection((prev) => true);
    setSelectedFilter((prev) => filter);
    getData({bckt: expanded, filter: filter, newPage: 1});
    setPage((prev) => 1);
  };

  const loadNextPage = () => {
    setPage((prev) => prev + 1);
  };
  const removeLoading = (label) => {
    setActionsLoading((prev) => prev.filter((l) => l !== label));
  };
  const removeAction = (label, bkgId) => {
    setRemovedActions((prev) => [...prev, {label, bkgId}]);
  };
  const onError = (label) => {
    removeLoading(label);
  };

  const onSuccess = (label, bkgId) => {
    removeLoading(label);
    removeAction(label, bkgId);
  };

  function handleBookingAction(bkg, props, action_type = null) {
    setActionsLoading((prev) => [...prev, props.label]);
    let action = null;
    if (!action_type) {
      action = props.action_options;
    } else {
      action = props[action_type].action_options;
    }
    dispatch(
      createAction({
        bookingId: bkg.booking_id,
        guestId: bkg.guest_id,
        action,
        onSuccess: (r) => {
          const currGuest = guests_dict[bkg.guest_id];
          if (!!currGuest) {
            if (!!r.guest_bills) {
              dispatch(editGuest({...currGuest, bills: r.guest_bills}));
            }
            if (!!r.guest_bill) {
              const newBills = (currGuest?.bills || []).map((b) =>
                b.id === r.guest_bill.id ? r.guest_bill : b,
              );
              dispatch(editGuest({...currGuest, bills: newBills}));
            }
          }
          if (
            !!r.bkg?.booking_id &&
            !!bookings_dict.booking_id[r.bkg.booking_id]
          ) {
            dispatch(editBooking(r.bkg));
          }
          sleep(1000).then(() => {
            onSuccess(props.label, bkg.booking_id);
            dispatch(triggerDashboardRefresh(true));
          });
        },
        onError: () => onError(props.label),
      }),
    );
  }

  const handleActionClick = (bkg, props, type) => () => {
    buttonClicked.current = `${bkg.booking_id}-${type}`;
    handleBookingAction(bkg, props, type);
  };

  function getEmptyLabel() {
    switch (expanded) {
      case "checkins":
        return `No check-ins${selectedFilter !== "all" ? ` with ${getFilterLabel(selectedFilter, true)}` : ""} today`;
      case "guests-in-house":
        return `No guests in-house${selectedFilter !== "all" ? ` with ${getFilterLabel(selectedFilter, true)}` : ""} today`;
      case "checking-out":
        return `No check-outs${selectedFilter !== "all" ? ` with ${getFilterLabel(selectedFilter, true)}` : ""} today`;
      case "up-checkins":
        return `No upcoming check-ins${selectedFilter !== "all" ? ` with ${getFilterLabel(selectedFilter, true)}` : ""}`;
      case "up-checking-out":
        return `No upcoming check-outs${selectedFilter !== "all" ? ` ${getFilterLabel(selectedFilter, true)}` : ""}`;

      default:
        return "No data available";
    }
  }

  function getListItem(id, label, bucketData = {}) {
    let isExpanded = expanded === id;
    let isUpcoming = id.startsWith("up-");
    let totalMessages = bucketData.actions?.message ?? 0;
    let totalGuests = bucketData.total_guests ?? 0;
    let totalPendingActions =
      (bucketData.actions?.pending_upsell ?? 0) +
      (bucketData.actions?.verify_guest ?? 0);
    if (!!expanded && !isExpanded) {
      return null;
    }
    return (
      <ListItem
        button
        disableRipple
        disabled={noData || (isUpcoming && loadingUpcoming)}
        divider={!isExpanded}
        classes={{
          root: clsx({[classes.mobileListItem]: !!mobile}),
          divider: classes.listItemDivider,
        }}
        onClick={() => handleListItemClick(id)}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <ArrowIcon className={clsx(classes.arrow, {expanded: isExpanded})} />
        </ListItemIcon>
        <ListItemText
          primary={`${!!totalGuests ? `${totalGuests} ` : ""}${label}`}
          primaryTypographyProps={{
            variant: "h1",
            className: classes.listItemText,
          }}
        />
        {!!totalMessages && (
          <Box mr={1}>
            <ListItemEndElement
              isMessage
              loading={loading}
              total={totalMessages}
            />
          </Box>
        )}
        <ListItemEndElement loading={loading} total={totalPendingActions} />
      </ListItem>
    );
  }

  function getDataSummary() {
    let checkins = (
      <Box mx={1} display={"inline"}>
        <Typography component="span" color="secondary">
          {loading ? "0" : (summaryData?.today?.checkins?.total_bookings ?? 0)}
        </Typography>
      </Box>
    );
    let checkouts = (
      <Box mx={1} display={"inline"}>
        <Typography component="span" color="secondary">
          {loading ? "0" : (summaryData?.today?.checkouts?.total_bookings ?? 0)}
        </Typography>
      </Box>
    );
    let inhouse = (
      <Box mx={1} display={"inline"}>
        <Typography component="span" color="secondary">
          {loading ? "0" : (summaryData?.today?.inhouse?.total_bookings ?? 0)}
        </Typography>
      </Box>
    );
    return (
      <Typography component="div">
        {"You have"}
        {checkins}
        {"check-ins,"}
        {inhouse}
        {"guests in-house, and"}
        {checkouts}
        {"guests checking-out today."}
      </Typography>
    );
  }

  function getDatesDifference(date1, date2, type) {
    let isPast = false;
    let timeBefore = "";
    let untilLabel = `until ${type}`;
    let daysDifference = differenceInDays(date1, date2);
    let hoursDifference = differenceInHours(date1, date2);
    let minutesDifference = differenceInMinutes(date1, date2);
    if (!!daysDifference) {
      if (daysDifference < 0) {
        isPast = true;
        timeBefore = `${daysDifference * -1} Days `;
        untilLabel = " ago";
      } else {
        timeBefore = `${daysDifference} Days`;
      }
    } else if (!!hoursDifference) {
      if (hoursDifference < 0) {
        isPast = true;
        timeBefore = `${hoursDifference * -1} Hr `;
        untilLabel = " ago";
      } else {
        timeBefore = `${hoursDifference} Hr`;
      }
    } else if (!!minutesDifference) {
      if (minutesDifference < 0) {
        isPast = true;
        timeBefore = `${minutesDifference * -1} Min `;
        untilLabel = " ago";
      } else {
        timeBefore = `${minutesDifference} Min`;
      }
    } else {
      timeBefore = capitalize(type);
      untilLabel = "now";
    }
    return (
      <Typography
        component="div"
        variant="caption"
        color="primary"
        className={clsx(classes.row, classes.remainingTimeLabel)}
        style={{alignItems: "center"}}
      >
        {!!isPast ? capitalize(type) : null}
        {!!timeBefore && (
          <Typography className={classes.captionStrong}>
            {timeBefore}
          </Typography>
        )}
        {untilLabel}
      </Typography>
    );
  }

  const groupsMenu = (
    <CustomMenu
      open={!!groupsMenuAnchorEl}
      anchorEl={groupsMenuAnchorEl}
      placement="bottom-start"
      transformOrigin="left top"
      onClose={closeGroupsMenu}
      content={
        <div className={classes.groupsModalContent}>
          <GroupsList
            selectedListings={selectedGroups}
            setSelectedListings={handleGroupChange}
            onClose={closeGroupsMenu}
          />
        </div>
      }
    />
  );

  return (
    <div
      className={clsx(
        classes.card,
        classes.col,
        classes.summaryPanel,
        "disableMaxHeight",
        {
          fullRounded: fullRounded,
          mobile: mobile,
        },
      )}
    >
      {groupsMenu}
      <div
        className={clsx(classes.row, classes.summaryHeader, {
          fullRounded: fullRounded,
          mobile: mobile,
        })}
      >
        <Box className={classes.col} flex={1}>
          <Typography variant="subtitle2" style={{opacity: 0.5}}>
            {`Welcome back, ${userName}`}
          </Typography>
          {getDataSummary()}
        </Box>
        <ExpandButton
          size="small"
          variant="contained"
          disabled={loading}
          label={btnLabel}
          onClick={openGroupsMenu}
        />
      </div>
      <div className={clsx(classes.summaryContent, classes.col)}>
        {(!expanded || (!!expanded && !expanded.startsWith("up-"))) && (
          <Box px={4} pt={4} pb={1}>
            <Typography variant="caption" className={classes.summarySubtitle}>
              {"TODAY"}
            </Typography>
          </Box>
        )}
        <List>
          {getListItem("checkins", "Check-ins", summaryData.today?.checkins)}
          {getListItem(
            "guests-in-house",
            "Guests in-house",
            summaryData.today?.inhouse,
          )}
          {getListItem(
            "checking-out",
            "Checking-out",
            summaryData.today?.checkouts,
          )}
        </List>
        {(!expanded || (!!expanded && expanded.startsWith("up-"))) && (
          <Box
            className={classes.row}
            alignItems="center"
            justifyContent="space-between"
            px={4}
            pt={4}
            pb={1}
          >
            <Typography variant="caption" className={classes.summarySubtitle}>
              {"UPCOMING"}
            </Typography>
            <TimePeriodModal
              rightPosition
              defaultTime={6}
              disableBtn={loading}
              selectedRange={dateRange}
              onChange={handleTimePeriodChange}
            />
          </Box>
        )}
        <List>
          {getListItem(
            "up-checkins",
            "Check-ins",
            summaryData.upcoming?.checkins,
          )}
          {getListItem(
            "up-checking-out",
            "Checking-out",
            summaryData.upcoming?.checkouts,
          )}
        </List>
        {!!expanded && (
          <div
            className={clsx(classes.summaryExpanded, {
              fullRounded: fullRounded,
              mobile: !!mobile,
            })}
          >
            <Box
              className={clsx(
                classes.row,
                classes.gap4,
                classes.chipsContainer,
              )}
              alignItems="center"
              mb={2}
            >
              <Filter
                id={"all"}
                mobile={mobile}
                section={expanded}
                data={summaryData}
                disabled={loadingSection}
                selectedFilter={selectedFilter}
                onClick={handleFilterClick}
              />
              <Filter
                id={"verify_guest"}
                mobile={mobile}
                section={expanded}
                data={summaryData}
                disabled={loadingSection}
                selectedFilter={selectedFilter}
                onClick={handleFilterClick}
              />
              <Filter
                id={"pending_upsell"}
                mobile={mobile}
                section={expanded}
                data={summaryData}
                disabled={loadingSection}
                selectedFilter={selectedFilter}
                onClick={handleFilterClick}
              />
              <Filter
                id={"message"}
                mobile={mobile}
                section={expanded}
                data={summaryData}
                disabled={loadingSection}
                selectedFilter={selectedFilter}
                onClick={handleFilterClick}
              />
            </Box>
            <div className={clsx(classes.summaryList, classes.hideScrollbar)}>
              {loadingSection ? (
                <List
                  className={clsx(classes.col, classes.summarySkeletonsList)}
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((sk) => {
                    return (
                      <Skeleton
                        key={sk}
                        width="100%"
                        height={"120px"}
                        animation="wave"
                        className={clsx(
                          classes.skeleton,
                          classes.summarySkeletons,
                        )}
                      />
                    );
                  })}
                </List>
              ) : (
                <Box>
                  {!currData.length && !loadingUpcoming && (
                    <Box>
                      <EmptyContentText label={getEmptyLabel()} />
                    </Box>
                  )}
                  <List className={clsx(classes.col, classes.gap8)}>
                    {currData.map((bkg, index) => {
                      let bucket = getBucket(expanded);
                      if (bucket !== "inhouse") {
                        bucket = `${bucket}s`;
                      }
                      let timeSection = expanded.startsWith("up-")
                        ? "upcoming"
                        : "today";
                      let hasNextPage =
                        index === currData.length - 2 &&
                        currData.length >= 45 &&
                        currData.length <
                          (summaryData?.[timeSection]?.[bucket]
                            ?.total_bookings ?? 0);
                      let actions = [];
                      let untilLabel = ["checkins", "up-checkins"].includes(
                        expanded,
                      )
                        ? getDatesDifference(
                            bkg.checkin_raw,
                            new Date(),
                            "check-in",
                          )
                        : getDatesDifference(
                            bkg.checkout_raw,
                            new Date(),
                            "check-out",
                          );

                      let actionsArr = [];
                      if (
                        !!bkg?.actions?.booking_actions?.length ||
                        !!bkg?.actions?.bp_actions?.length
                      ) {
                        actionsArr = [
                          ...(bkg.actions?.booking_actions || []),
                          ...(bkg.actions?.bp_actions || []),
                        ];
                        actionsArr.forEach((act) => {
                          actions.push({
                            type: "pending",
                            message: act?.label,
                            buttons: [
                              {
                                type: "secondary",
                                label: act?.deny?.label,
                                action: handleActionClick(bkg, act, "deny"),
                                loading:
                                  actionsLoading.includes(act?.label) &&
                                  buttonClicked.current ===
                                    `${bkg.booking_id}-deny`,
                                disabled:
                                  actionsLoading.includes(act?.label) &&
                                  buttonClicked.current?.startsWith(
                                    `${bkg.booking_id}-`,
                                  ),
                              },
                              {
                                type: "primary",
                                label: act.confirm?.label,
                                action: handleActionClick(bkg, act, "confirm"),
                                loading:
                                  actionsLoading.includes(act?.label) &&
                                  buttonClicked.current ===
                                    `${bkg.booking_id}-confirm`,
                                disabled:
                                  actionsLoading.includes(act?.label) &&
                                  buttonClicked.current?.startsWith(
                                    `${bkg.booking_id}-`,
                                  ),
                              },
                            ],
                          });
                        });
                      }

                      if (
                        !!bkg.actions?.bp_upsells?.length ||
                        !!bkg.actions?.bp_fees?.length
                      ) {
                        let productActions = [
                          ...(bkg.actions?.bp_upsells || []),
                          ...(bkg.actions?.bp_fees || []),
                        ];
                        productActions.forEach((act) => {
                          actions.push({
                            type: "request",
                            message: act?.label,
                            buttons: [
                              {
                                type: "secondary",
                                label: act?.deny?.label,
                                action: handleActionClick(bkg, act, "deny"),
                                loading:
                                  actionsLoading.includes(act?.label) &&
                                  buttonClicked.current ===
                                    `${bkg.booking_id}-deny`,
                                disabled:
                                  actionsLoading.includes(act?.label) &&
                                  buttonClicked.current?.startsWith(
                                    `${bkg.booking_id}-`,
                                  ),
                              },
                              {
                                type: "primary",
                                label: act.confirm?.label,
                                action: handleActionClick(bkg, act, "confirm"),
                                loading:
                                  actionsLoading.includes(act?.label) &&
                                  buttonClicked.current ===
                                    `${bkg.booking_id}-confirm`,
                                disabled:
                                  actionsLoading.includes(act?.label) &&
                                  buttonClicked.current?.startsWith(
                                    `${bkg.booking_id}-`,
                                  ),
                              },
                            ],
                          });
                        });
                      }

                      if (!!bkg.guest?.unread_messages) {
                        actions.push({
                          type: "message",
                          message: `New message: ${bkg.guest.message_preview}`,
                          buttons: [
                            {
                              type: "secondary",
                              label: "Dismiss",
                              action: () => handleDismiss(expanded, bkg),
                            },
                            {
                              type: "primary",
                              label: "Respond",
                              disabled: selectedGuest === bkg.guest.guest_id,
                              action: () => handleOpenChat(bkg),
                            },
                          ],
                        });
                      }

                      if (
                        !bkg.guest?.unread_messages &&
                        !!bkg.guest?.unanswered_messages
                      ) {
                        actions.push({
                          type: "unansweredMessage",
                          message: `Unanswered message: ${bkg.guest.message_preview}`,
                          buttons: [
                            {
                              type: "secondary",
                              label: "Dismiss",
                              action: () => handleDismiss(expanded, bkg),
                            },
                            {
                              type: "primary",
                              label: "Respond",
                              disabled: selectedGuest === bkg.guest.guest_id,
                              action: () => handleOpenChat(bkg),
                            },
                          ],
                        });
                      }

                      let hasVerifyGuestAO = !!actionsArr.find(
                        (act) =>
                          act.confirm?.action_options?.action_id ===
                          "verify_guest",
                      );
                      if (
                        bkg.tags &&
                        !bkg.tags
                          .map((t) => t.toUpperCase())
                          .includes("VERIFIED") &&
                        !hasVerifyGuestAO
                      ) {
                        actions.push({
                          type: "pending",
                          message: "Unverified guest",
                          buttons: [
                            {
                              type: "primary",
                              label: "Follow-up",
                              disabled: selectedGuest === bkg.guest.guest_id,
                              action: () => handleOpenChat(bkg),
                            },
                          ],
                        });
                      }

                      actions = actions.filter(
                        (act) =>
                          !removedActions.find(
                            (ra) =>
                              ra.label === act.message &&
                              ra.bkgId === bkg.booking_id,
                          ),
                      );

                      return (
                        <BookingCard
                          key={bkg.booking_id}
                          booking={bkg}
                          mobile={mobile}
                          selected={selectedGuest === bkg.guest.guest_id}
                          openGuest={() => handleOpenChat(bkg)}
                          loadNextPage={hasNextPage ? loadNextPage : null}
                          actions={actions}
                          untilLabel={untilLabel}
                        />
                      );
                    })}
                  </List>
                </Box>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardSummaryPanel;
