import React from "react";
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {languages as langs} from "configuration/constants";
import WarningIcon from "assets/icons/WarningIcon";

const languages = Object.keys(langs);
const userLanguage = (navigator.language || navigator.userLanguage || "en")
  .toLowerCase()
  .substring(0, 2);

const useStyles = makeStyles((theme) => ({
  label: {
    backgroundColor: theme.palette.primary.contrast,
    zIndex: 1,
    position: "absolute",
    top: -7,
    left: 10,
    padding: theme.spacing(0, 1),
    color: (props) =>
      props.hasErrors ? theme.palette.error.main : theme.palette.primary.main,
  },
  select: {
    border: "2px solid",
    borderRadius: 12,
    borderColor: (props) =>
      props.hasErrors ? theme.palette.error.main : theme.palette.primary.main,
    "& fieldset": {border: "none"},
  },
  selectIcon: {color: theme.palette.primary.main + "!important"},
  menu: {
    maxHeight: 300,
    backgroundColor: theme.palette.primary.contrast,
    zoom: 0.7,
  },
  divider: {backgroundColor: theme.palette.primary.dark},
  required: {
    paddingLeft: 3,
    fontSize: 21,
  },
  caption: {
    margin: "-3px 14px 0px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

const TOP_LANGUAGES = ["en", "es", "fr"];

export default function KeycardLanguageSelector({f, ind, onChange}) {
  const hasErrors = !!f.error;
  const classes = useStyles({hasErrors});

  return (
    <>
      <FormControl fullWidth>
        <InputLabel
          shrink
          className={classes.label}
          htmlFor="language-selector"
        >
          {"Language"}
          {!!f.required ? <span className={classes.required}>{"*"}</span> : ""}
        </InputLabel>
        <Select
          disabled
          displayEmpty
          value={f.value}
          variant="outlined"
          id="language-selector"
          className={classes.select}
          classes={{icon: classes.selectIcon}}
          inputProps={{autoFocus: true}}
          MenuProps={{classes: {paper: classes.menu}}}
          onChange={(e) => onChange(ind, e.target.value)}
        >
          <MenuItem disabled value={""}>
            {"Select language"}
          </MenuItem>
          <Divider
            component="li"
            className={classes.divider}
            variant="middle"
          />
          {!TOP_LANGUAGES.includes(userLanguage) && (
            <MenuItem value={userLanguage}>{langs[userLanguage]}</MenuItem>
          )}
          {TOP_LANGUAGES.map((l) => (
            <MenuItem key={l} value={l}>
              {langs[l]}
            </MenuItem>
          ))}
          <Divider
            component="li"
            className={classes.divider}
            variant="middle"
          />
          {languages.map((l) => {
            if (l === userLanguage || TOP_LANGUAGES.includes(l)) {
              return null;
            }
            return (
              <MenuItem key={l} value={l}>
                {langs[l]}
              </MenuItem>
            );
          })}
        </Select>
        {hasErrors && (
          <div className={classes.caption}>
            <WarningIcon color="error" style={{marginTop: 8}} />
            <Typography
              variant="caption"
              color={"error"}
            >{`Language is required`}</Typography>
          </div>
        )}
      </FormControl>
    </>
  );
}
