import React from "react";
import {Button, Typography, Box, makeStyles} from "@material-ui/core";
import ArrowBackIosNewIcon from "@material-ui/icons/ArrowBackIos";
import SignaturePad from "react-signature-canvas";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
  },
  signature_box: {
    width: "100%",
    maxWidth: "500px",
    height: "100px",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "10px",
    backgroundColor: "white",
    marginBottom: 10,
  },
  content: {
    padding: theme.spacing(5, 2.5, 0),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
    flex: 1,
    marginBottom: 20,
    maxWidth: 800,
    width: "100%",
  },
  signaturePadContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    paddingBottom: 40,
    width: "calc(100% - 100px)",
    maxWidth: 502,
  },
  submitBtn: {
    borderRadius: "15px",
    fontSize: 15,
    color: theme.palette.primary.contrast + "!important",
    backgroundColor: theme.palette.primary.main + "!important",
    boxShadow: "none !important",
    textTransform: "none",
  },
}));

export default function KeycardAgreement({components = {}, closePage}) {
  const [sigPad, setSigpad] = React.useState(null);
  const classes = useStyles();
  const [disabled, setDisabled] = React.useState(true);
  let selectedAgreement = 0;

  const handleSubmit = () => {};

  if (!components.agreement) {
    return null;
  }
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Button
          style={{padding: 0, minWidth: "fit-content", alignSelf: "flex-start"}}
          onClick={closePage}
          startIcon={<ArrowBackIosNewIcon style={{marginTop: -8}} />}
        >
          {"Back"}
        </Button>
        <Typography variant="h3">
          {components.agreement[selectedAgreement].name}
        </Typography>
        <Box mt={1} mb={2}>
          {components.agreement[selectedAgreement].message
            .split("\n")
            .map((i, key) => (
              <Typography key={key}>
                {i}
                <br />
              </Typography>
            ))}
        </Box>
      </div>
      <div className={["footer", classes.signaturePadContainer].join(" ")}>
        <div style={{display: "flex", justifyContent: "center"}}>
          <SignaturePad
            canvasProps={{className: classes.signature_box, id: "signaturePad"}}
            ref={(ref) => setSigpad(ref)}
            onEnd={() => setDisabled(sigPad.isEmpty())}
          />
        </div>
        <Button
          id="acceptButton"
          className={classes.submitBtn}
          onClick={() => handleSubmit()}
          fullWidth
          size="large"
          variant="contained"
          disabled={disabled}
        >
          {"Submit Signature"}
        </Button>
      </div>
    </div>
  );
}
