import React from "react";
// UI
import {
  Box,
  Button,
  Divider,
  MenuItem,
  MenuList,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ArrowDownIcon from "@material-ui/icons/ExpandMore";
import {ReactComponent as FaceGrinHearts} from "assets/icons/face-grin-hearts.svg";
import {ReactComponent as FaceSmile} from "assets/icons/face-smile.svg";
import {ReactComponent as FaceMeh} from "assets/icons/face-meh.svg";
import {ReactComponent as FaceFrown} from "assets/icons/face-frown.svg";
import {ReactComponent as FaceAngry} from "assets/icons/face-angry.svg";
// Custom
import CustomMenu from "core/menus/CustomMenu";
// Utilities
import {satisfaction} from "configuration/constants";
import {THEME} from "configuration/settings.js";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  button: {
    padding: theme.spacing(0, 3),
    paddingTop: 2,
    paddingBottom: 2,
    minWidth: "fit-content",
    minHeight: 24,
    height: "fit-content",
  },
  btnLabel: {
    textTransform: "none",
    lineHeight: "16px",
    fontSize: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(2),
  },
  text: {
    fontSize: 12,
    lineHeight: "16px",
    borderRadius: 15,
    padding: theme.spacing(0, 3),
    paddingTop: 2,
    paddingBottom: 2,
    height: 24,
  },
  menuItem: {fontWeight: 500},
  divider: {
    opacity: 0.3,
    margin: "-2px 0",
  },
  endIcon: {
    margin: 0,
    marginRight: -6,
  },
  chevron: {
    width: 16,
    height: 16,
  },
  faceIcon: {
    width: 24,
    height: 24,
    "&.sm": {
      width: 20,
      height: 20,
    },
  },
  filled: {
    borderRadius: 20,
    padding: "2px 8px",
  },
  // Button
  defaultButton: {
    backgroundColor: THEME.satisfaction.neutral.bg,
    border: `1px solid ${THEME.satisfaction.neutral.color}`,
  },
  unselectedButton: {
    backgroundColor: THEME.satisfaction.neutral.bg,
    border: `1px solid ${THEME.satisfaction.neutral.color}`,
  },
  unsatisfiedButton: {
    backgroundColor: THEME.satisfaction.unsatisfied.bg,
    border: `1px solid ${THEME.satisfaction.unsatisfied.color}`,
  },
  somewhat_satisfiedButton: {
    backgroundColor: THEME.satisfaction.somewhat_satisfied.bg,
    border: `1px solid ${THEME.satisfaction.somewhat_satisfied.color}`,
  },
  neutralButton: {
    backgroundColor: THEME.satisfaction.neutral.bg,
    border: `1px solid ${THEME.satisfaction.neutral.color}`,
  },
  satisfiedButton: {
    backgroundColor: THEME.satisfaction.satisfied.bg,
    border: `1px solid ${THEME.satisfaction.satisfied.color}`,
  },
  highly_satisfiedButton: {
    backgroundColor: THEME.satisfaction.highly_satisfied.bg,
    border: `1px solid ${THEME.satisfaction.highly_satisfied.color}`,
  },
  // Divider
  defaultDivider: {backgroundColor: THEME.satisfaction.neutral.color},
  unselectedDivider: {backgroundColor: THEME.satisfaction.neutral.color},
  unsatisfiedDivider: {backgroundColor: THEME.satisfaction.unsatisfied.color},
  somewhat_satisfiedDivider: {
    backgroundColor: THEME.satisfaction.somewhat_satisfied.color,
  },
  neutralDivider: {backgroundColor: THEME.satisfaction.neutral.color},
  satisfiedDivider: {backgroundColor: THEME.satisfaction.satisfied.color},
  highly_satisfiedDivider: {
    backgroundColor: THEME.satisfaction.highly_satisfied.color,
  },
  // Label
  default: {color: THEME.satisfaction.neutral.color},
  unselected: {color: THEME.satisfaction.neutral.color},
  unsatisfied: {color: THEME.satisfaction.unsatisfied.color},
  somewhat_satisfied: {color: THEME.satisfaction.somewhat_satisfied.color},
  neutral: {color: THEME.satisfaction.neutral.color},
  satisfied: {color: THEME.satisfaction.satisfied.color},
  highly_satisfied: {color: THEME.satisfaction.highly_satisfied.color},
}));

export default function Rating({
  value = null,
  onClick,
  edit,
  useIcon,
  filled,
  showNumber,
  onValueChange,
}) {
  const classes = useStyles();
  const [selectedRating, setSelectedRating] = React.useState(value);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const {label, satisfactionType} = React.useMemo(() => {
    if (value === null) {
      return {label: "Unknown sentiment", satisfactionType: "default"};
    } else if (value === "") {
      return {
        label: "Click to select a rating",
        satisfactionType: "unselected",
      };
    } else if (value <= 1.5) {
      return {
        label: satisfaction.unsatisfied.label,
        satisfactionType: "unsatisfied",
      };
    } else if (value <= 2.5) {
      return {
        label: satisfaction.somewhat_satisfied.label,
        satisfactionType: "somewhat_satisfied",
      };
    } else if (value < 3.5) {
      return {label: satisfaction.neutral.label, satisfactionType: "neutral"};
    } else if (value < 4.5) {
      return {
        label: satisfaction.satisfied.label,
        satisfactionType: "satisfied",
      };
    } else if (value <= 5) {
      return {
        label: satisfaction.highly_satisfied.label,
        satisfactionType: "highly_satisfied",
      };
    } else {
      return {label: "", satisfactionType: "default"};
    }
  }, [value]);

  const faceIcon = React.useMemo(() => {
    if (value === null) {
      return FaceMeh;
    } else if (value <= 1.5) {
      return FaceAngry;
    } else if (value <= 2.5) {
      return FaceFrown;
    } else if (value < 3.5) {
      return FaceMeh;
    } else if (value < 4.5) {
      return FaceSmile;
    } else if (value <= 5) {
      return FaceGrinHearts;
    } else {
      return null;
    }
  }, [value]);

  React.useEffect(() => {
    if (edit && value !== selectedRating) {
      setSelectedRating((prev) => value);
    }
  }, [value]);

  React.useEffect(() => {
    if (edit && value !== selectedRating) {
      onValueChange(selectedRating);
    }
  }, [selectedRating]);

  const openMenu = (e) => setAnchorEl(e.currentTarget);
  const closeMenu = () => setAnchorEl((prev) => null);

  const onRatingChange = (newRating) => () => {
    setSelectedRating((prev) => newRating);
    closeMenu();
  };

  function getMenuContent() {
    return (
      <MenuList id="rating-menu">
        <MenuItem disabled value="">
          Select a rating
        </MenuItem>
        {Object.keys(satisfaction).map((r) => (
          <MenuItem
            key={r}
            selected={selectedRating === r}
            className={clsx(classes.menuItem, classes[r])}
            onClick={onRatingChange(satisfaction[r].value)}
          >
            {satisfaction[r].label}
          </MenuItem>
        ))}
      </MenuList>
    );
  }

  function getRatingButton(onPress) {
    if (useIcon) {
      return (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          onClick={onPress ?? onClick}
          className={clsx({
            [classes.filled]: !!filled,
            [classes[`${satisfactionType}Button`]]: !!filled,
          })}
          style={{cursor: !!onPress || !!onClick ? "pointer" : "default"}}
        >
          <Box mr={2} display={"flex"}>
            <SvgIcon
              viewBox="0 0 24 24"
              component={faceIcon}
              className={clsx(
                classes.faceIcon,
                "sm",
                classes[satisfactionType],
              )}
            />
          </Box>
          <Typography
            variant={filled ? "body1" : "h1"}
            className={clsx(classes[satisfactionType])}
          >
            {showNumber ? value : label}
          </Typography>
          {!!onPress && (
            <Box ml={1} display="flex">
              <ArrowDownIcon
                className={clsx(classes.chevron, classes[satisfactionType])}
              />
            </Box>
          )}
        </Box>
      );
    } else {
      return (
        <Button
          disableRipple
          disableElevation
          endIcon={<ArrowDownIcon className={classes.chevron} />}
          classes={{
            root: clsx(classes.button, classes[`${satisfactionType}Button`]),
            label: clsx(classes.btnLabel, classes[satisfactionType]),
            endIcon: classes.endIcon,
          }}
          onClick={onPress ?? onClick}
        >
          {label}
          <Divider
            flexItem
            orientation="vertical"
            className={clsx(
              classes.divider,
              classes[`${satisfactionType}Divider`],
            )}
          />
        </Button>
      );
    }
  }

  if (edit) {
    return (
      <div className={classes.root}>
        <CustomMenu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          zIndex={1400}
          onClose={closeMenu}
          content={getMenuContent()}
        />
        {getRatingButton(openMenu)}
      </div>
    );
  } else {
    return !!onClick || !!useIcon ? (
      getRatingButton()
    ) : (
      <Typography
        variant="button"
        className={clsx(
          classes.text,
          classes[`${satisfactionType}Button`],
          classes[satisfactionType],
        )}
      >
        {label || "-"}
      </Typography>
    );
  }
}
