import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Box, MenuItem, MenuList, SvgIcon} from "@material-ui/core";
import {ReactComponent as DownloadIcon} from "assets/icons/download.svg";
import {useTheme} from "@material-ui/core/styles";
import CampaignPanel from "ui/component/crm/panel/CampaignPanel";
import BookingInfoDrawer from "components/Panels/Booking/BookingInfoDrawer";
import CampaignListTable from "ui/component/crm/table/CampaignListTable";
import GuestListTable from "ui/component/crm/table/GuestListTable";
import ExpandButton from "core/buttons/ExpandButton";
import CustomMenu from "core/menus/CustomMenu";
import Panel2 from "core/panels/Panel2";
import Panel3 from "core/panels/Panel3";
import {
  closeGeneralSuccessAlert,
  openGeneralSuccessAlert,
} from "redux/actions/settingsActions";
import {getCRMPreview, getExportCRM} from "redux/actions/crmActions";
import {getExperiences} from "redux/actions/experiencesActions";
import {guestListExportTypes, segmentsCRM} from "configuration/constants";
import {getEncodedFilters, newUuid} from "utilities/helperFunctions";
import _ from "lodash";
import PanelContainer from "../base/panel/PanelContainer";
import CrmCardHeader from "../component/crm/panel/CrmCardHeader";

export default function CRMV2() {
  const dispatch = useDispatch(),
    theme = useTheme(),
    apiCallId = React.useRef(null),
    user_profile = useSelector((state) => state.defaultReducer.user_profile),
    current_user = useSelector((state) => state.defaultReducer.current_user),
    isTabletView =
      useSelector((state) => state.defaultReducer.deviceType) === "tablet",
    [view, setView] = React.useState("guests"),
    [exportMenuAnchorEl, setExportMenuAnchorEl] = React.useState(null),
    [detailsPanel, setDetailsPanel] = React.useState(null),
    [selectedGuest, setSelectedGuest] = React.useState(null),
    [connectedGuests, setConnectedGuests] = React.useState([]),
    [selectedSegment, setSelectedSegment] = React.useState(null),
    [filters, setFilters] = React.useState([]),
    [searchText, setSearchText] = React.useState(""),
    [previewData, setPreviewData] = React.useState([]),
    [campaigns, setCampaigns] = React.useState([]),
    [selectedCampaign, setSelectedCampaign] = React.useState(null);

  React.useEffect(() => {
    if (!current_user) {
      return;
    }
    getCampaigns();
  }, [current_user]);

  React.useEffect(() => {
    if (!current_user) {
      return;
    }
    loadData();
  }, [current_user, filters, searchText]);

  React.useEffect(() => {
    if (!selectedSegment) {
      return;
    }
    let missingFilters = !_.isEqual(
      segmentsCRM[selectedSegment.segment_id].filters,
      filters,
    );
    if (missingFilters) {
      setSelectedSegment((prev) => null);
    }
  }, [filters, selectedSegment]);

  const getCampaigns = () => {
    dispatch(
      getExperiences({
        type: "cmp",
        onSuccess: (response) => {
          const newCampaigns = response?.experience ?? [];
          setCampaigns((prev) => newCampaigns);
        },
      }),
    );
  };

  const handleReportPreviewError = () => setPreviewData((prev) => []);

  const handleReportPreviewSuccess = (response, add, responseId) => {
    if (apiCallId.current !== responseId) {
      return;
    }
    if (add) {
      setPreviewData((prev) => {
        const newData = {
          ...response[0],
          hits: [...prev[0].hits, ...response[0].hits],
        };
        return [newData];
      });
    } else {
      setPreviewData((prev) => response);
    }
  };

  const loadData = () => {
    let newApiCallId = newUuid();
    apiCallId.current = newApiCallId;
    dispatch(
      getCRMPreview(
        0,
        "line_chart",
        "all",
        getEncodedFilters(filters, false),
        searchText,
        (response) => handleReportPreviewSuccess(response, false, newApiCallId),
        handleReportPreviewError,
        null,
      ),
    );
  };

  const loadNextHits = () => {
    apiCallId.current = newUuid();

    dispatch(
      getCRMPreview(
        0,
        "line_chart",
        "all",
        getEncodedFilters(filters, false),
        searchText,
        (response) => handleReportPreviewSuccess(response, true),
        handleReportPreviewError,
        previewData[0].hits.length,
      ),
    );
  };

  const openCampaign = (campaign) => {
    setDetailsPanel((prev) => "inst");
    setSelectedCampaign((prev) => campaign);
  };

  const handleActionClick = (option, e) => {
    switch (option) {
      case "export":
        const el = e.currentTarget;
        setExportMenuAnchorEl((prev) => el);
        break;
      default:
        if (option === "inst") {
          setSelectedCampaign((prev) => null);
        }
        setDetailsPanel((prev) => option);
        break;
    }
  };

  const exportReport = (exportType) => {
    setExportMenuAnchorEl((prev) => null);
    dispatch(
      getExportCRM({
        interval: "all",
        contentType: exportType,
        query: searchText,
        crmView: "guests",
        filters: getEncodedFilters(filters, false),
        onSuccess: (r) => {
          dispatch(
            openGeneralSuccessAlert({
              message: "The exported data will be sent to your email shortly",
              open: true,
              onClose: () => dispatch(closeGeneralSuccessAlert()),
            }),
          );
        },
      }),
    );
  };

  function getExportMenuContent() {
    return (
      <MenuList id="export-options-menu">
        {Object.keys(guestListExportTypes).map((exportType) => (
          <MenuItem key={exportType} onClick={() => exportReport(exportType)}>
            {`Export as .${exportType}`}
          </MenuItem>
        ))}
      </MenuList>
    );
  }

  function getPanel3Content() {
    if (!detailsPanel) {
      return null;
    }
    switch (detailsPanel) {
      case "inst":
        return (
          <CampaignPanel
            hideContent
            hideTriggers
            hideConnection
            getCampaigns={getCampaigns}
            addCampaign={(campaign) => {
              setCampaigns((prev) => [...prev, campaign]);
            }}
            closeDetails={() => {
              setDetailsPanel((prev) => null);
              setSelectedSegment((prev) => null);
            }}
            selectedCampaign={selectedCampaign}
            connectedIds={
              !selectedCampaign
                ? connectedGuests
                : (selectedCampaign?.connected_to?.ids ?? [])
            }
          />
        );
      case "guest_details":
        return (
          <BookingInfoDrawer
            guestId={selectedGuest.guest_id}
            onClose={() => {
              setDetailsPanel((prev) => null);
              setSelectedSegment((prev) => null);
            }}
          />
        );

      default:
        return null;
    }
  }

  function getPanel2Content() {
    return (
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <CustomMenu
          open={Boolean(exportMenuAnchorEl)}
          anchorEl={exportMenuAnchorEl}
          onClose={() => {
            setExportMenuAnchorEl((prev) => null);
          }}
          content={getExportMenuContent()}
        />
        <CrmCardHeader
          view={view}
          setView={setView}
          handleActionClick={handleActionClick}
        />{" "}
        {view === "guests" ? (
          <GuestListTable
            allInterval
            metric={"guests"}
            filters={filters}
            selectedReport="new"
            searchText={searchText}
            previewData={previewData}
            exportButton={
              <ExpandButton
                size="small"
                label="Export"
                variant="contained"
                startIcon={
                  <SvgIcon component={DownloadIcon} viewBox="0 0 24 24" />
                }
                style={{height: "fit-content", padding: theme.spacing(1, 3)}}
                onClick={(e) => handleActionClick("export", e)}
              />
            }
            hideCheckboxColumn={detailsPanel !== "inst"}
            setMetric={null}
            setFilters={setFilters}
            loadNextPage={loadNextHits}
            openGuestPanel={(guest) => {
              setDetailsPanel((prev) => "guest_details");
              setSelectedGuest((prev) => guest);
            }}
            setSearchText={(newSearch) => {
              setSearchText((prev) => newSearch);
            }}
            setSelectedIds={(newIds) => {
              setConnectedGuests((prev) => newIds);
            }}
          />
        ) : (
          <Box mt={4} flex={1} display="flex" flexDirection="column">
            <CampaignListTable
              campaigns={campaigns}
              onCampaignSelect={openCampaign}
            />
          </Box>
        )}
      </div>
    );
  }

  if (
    !Object.keys(user_profile).length ||
    user_profile.scopes?.crm === "hide"
  ) {
    return null;
  }
  return (
    <PanelContainer>
      <Panel2
        lg
        flexAuto
        content={getPanel2Content()}
        isPanel3Open={isTabletView ? false : !!detailsPanel}
        hidden={isTabletView && !!detailsPanel}
      />
      {(!isTabletView || !!detailsPanel) && (
        <Panel3
          sm
          rounded={isTabletView}
          content={getPanel3Content()}
          hidden={!detailsPanel}
          disableMaxWidth={isTabletView}
        />
      )}
    </PanelContainer>
  );
}
