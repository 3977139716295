import React, {useState} from "react";
import {useSelector} from "react-redux";
// UI
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import RightArrowIcon from "@material-ui/icons/ArrowForwardIos";
// Custom
import CustomMenu from "core/menus/CustomMenu";
import ExpandButton from "core/buttons/ExpandButton";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
// Utils
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  saveBtn: {
    textTransform: "none",
    height: "fit-content",
  },
  expandMenuItem: {
    paddingRight: 0,
    width: "100%",
  },
  listItemIcon: {
    minWidth: "auto",
    padding: `0 8px`,
  },
  arrowIcon: {
    color: theme.palette.grey[300],
    fontSize: 14,
  },
}));

export default function SaveContentButton({
  disabled,
  label = "Save",
  modalType,
  fields = [],
  className,
  onSave,
  disableFieldConfirmation,
}) {
  const classes = useStyles();
  const listingGroups = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [groupsAnchorEl, setGroupsAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const listingGroupsKeys = React.useMemo(() => {
    return Object.keys(listingGroups).filter((lg) => lg !== "ALL");
  }, [listingGroups]);
  const selectedOptionLabel = React.useMemo(() => {
    if (selectedOption === null) {
      return "";
    } else if (selectedOption === "all") {
      return "all listings";
    } else {
      return `'${listingGroups[selectedOption].name}' listing group`;
    }
  }, [selectedOption]);

  const handleCloseMenu = () => {
    setMenuAnchorEl((prev) => null);
  };
  const handleCloseGroupsMenu = () => {
    setGroupsAnchorEl((prev) => null);
  };
  const handleCloseConfirmation = () => {
    setSelectedOption((prev) => null);
  };

  const handleSave = (option) => () => {
    handleCloseMenu();
    handleCloseGroupsMenu();
    onSave(option);
  };

  const handleOpenConfirmation = (option) => () => {
    handleCloseMenu();
    handleCloseGroupsMenu();
    setSelectedOption((prev) => option);
  };

  function getMenuContent() {
    return (
      <MenuList id="saving-options-menu">
        <MenuItem button disableRipple onClick={handleSave("listing")}>
          {`${label} for this listing`}
        </MenuItem>
        <MenuItem
          button
          disableRipple
          onClick={
            disableFieldConfirmation
              ? handleSave("all")
              : handleOpenConfirmation("all")
          }
        >
          {`${label} for all listings`}
        </MenuItem>
        <MenuItem
          button
          disableRipple
          disabled={!listingGroupsKeys.length}
          className={classes.expandMenuItem}
          onClick={(e) => setGroupsAnchorEl(e.currentTarget)}
        >
          <ListItemText>
            {`${label === "Delete" ? "Delete from " : "Save for "}a listing group`}
          </ListItemText>
          <ListItemIcon className={classes.listItemIcon}>
            <RightArrowIcon className={classes.arrowIcon} />
          </ListItemIcon>
        </MenuItem>
      </MenuList>
    );
  }

  function getGroupsMenuContent() {
    return (
      <MenuList id="listing-groups-menu">
        {listingGroupsKeys.map((lg) => (
          <MenuItem
            key={lg}
            value={lg}
            onClick={
              disableFieldConfirmation
                ? handleSave(lg)
                : handleOpenConfirmation(lg)
            }
          >
            {listingGroups[lg].name}
          </MenuItem>
        ))}
      </MenuList>
    );
  }

  const confirmSaveModal = selectedOption !== null && (
    <ConfirmDialog
      open
      disableDismiss
      onClose={handleCloseConfirmation}
      title={`Save in ${selectedOptionLabel}?`}
      message={
        !!modalType ? (
          <>
            {`This ${modalType} will be saved in ${selectedOption !== "all" ? "all listings of the " : ""}`}
            <strong>{`${selectedOptionLabel}`}</strong>
          </>
        ) : (
          <>
            {`The following fields will be saved in ${selectedOption !== "all" ? "all listings of the " : ""}`}
            <strong>{`${selectedOptionLabel}:`}</strong>
            <List dense className="mt-3">
              {fields.map((f) => (
                <ListItem key={f} disableGutters>
                  • {f}
                </ListItem>
              ))}
            </List>
          </>
        )
      }
      confirmLabel="Save"
      confirmAction={handleSave(selectedOption)}
      cancelLabel="Cancel"
      cancelAction={handleCloseConfirmation}
    />
  );

  return (
    <>
      {confirmSaveModal}
      <ExpandButton
        label={label}
        size="small"
        variant="contained"
        disabled={disabled}
        className={clsx(classes.saveBtn, {[className]: !!className})}
        onClick={(e) => setMenuAnchorEl(e.currentTarget)}
      />
      <CustomMenu
        overflowAuto
        zIndex={1300}
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        onClose={() => handleCloseMenu(false)}
        timeout={100}
        content={getMenuContent()}
      />
      <CustomMenu
        overflowAuto
        zIndex={1300}
        modifiers={{
          flip: {enabled: true},
          preventOverflow: {
            enabled: true,
            boundariesElement: "viewport",
          },
        }}
        open={Boolean(groupsAnchorEl)}
        anchorEl={groupsAnchorEl}
        placement="right-start"
        timeout={100}
        onClose={handleCloseGroupsMenu}
        content={getGroupsMenuContent()}
      />
    </>
  );
}
