import {makeStyles} from "@material-ui/core";
import {THEME} from "configuration/settings";

const useFilterMenuStyles = makeStyles((theme) => ({
  menuList: {padding: theme.spacing(3) + "px !important"},
  filterMenuItem: {
    border: "1px solid #F0F0F0",
    borderRadius: 5,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  menuItemSelect: {
    paddingRight: theme.spacing(1),
    width: "100%",
  },
  menuItemText: {paddingRight: theme.spacing(2)},
  listItemIcon: {
    minWidth: 0,
    padding: 0,
  },
  listItemFlag: {
    minWidth: "auto",
    paddingRight: theme.spacing(3),
  },
  listItemAvatar: {
    minWidth: 0,
    width: 28,
    height: 28,
    marginRight: theme.spacing(2),
    "& .-avatar": {
      width: 28,
      height: 28,
    },
  },
  arrowIcon: {
    color: theme.palette.grey[300],
    fontSize: 14,
  },
  filterMenuPlacement: {
    top: "-4px !important",
    left: "16px !important",
  },
  optionMenuPlacement: {left: "20px !important"},
  disabledMenuItem: {
    ...theme.typography.subtitle2,
    color: THEME.subdued,
    padding: 0,
    "&.-section": {marginTop: theme.spacing(4)},
    "&.-pb": {paddingBottom: theme.spacing(3)},
  },
  menuItem: {
    padding: theme.spacing(2),
    "&:not(:nth-child(-n + 2))": {borderTop: "1px solid #F0F0F0"},
  },
  listingListContainer: {
    borderRadius: 10,
    width: "100vw",
    height: "100vh",
    maxWidth: 300,
    maxHeight: (props) => props.maxListingsHeight ?? 510,
    minHeight: 40,
    padding: theme.spacing(3),
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  languageListContainer: {
    borderRadius: 10,
    width: "100vw",
    height: "100vh",
    maxWidth: 200,
    maxHeight: (props) => props.maxLangsHeight ?? 510,
    minHeight: 40,
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
  },
  optionListContainer: {padding: theme.spacing(3)},
  languageMenuItem: {
    padding: theme.spacing(2),
    "&:not(:nth-child(-n + 3))": {borderTop: "1px solid #F0F0F0"},
  },
  search: {
    zIndex: 2,
    position: "sticky",
    top: 0,
    backgroundColor: "#FFF !important",
    padding: theme.spacing(3, 0, 1),
  },
  searchInputIcon: {
    color: "rgba(60, 60, 67, 0.6)",
    position: "absolute",
    bottom: 12,
    left: 7,
    width: 18,
    height: 18,
  },
  searchInput: {
    border: "none",
    borderRadius: 10,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "20px",
    padding: theme.spacing(2, 3),
    paddingLeft: 28,
    outline: "none",
    width: "100%",
    backgroundColor: "#F5F5F5",
  },
  noResultsMessage: {padding: theme.spacing(2, 3)},
  listItem: {
    height: "100%",
    padding: theme.spacing(2),
    borderTop: "1px solid #F0F0F0",
    "& .-list-item-text": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  seeMoreBtn: {
    padding: theme.spacing(3, 0, 2),
    backgroundColor: "#FFF !important",
    "& .-label-container": {
      margin: 0,
      backgroundColor: theme.palette.secondary.main + "0d",
      borderRadius: 5,
      padding: theme.spacing(1),
    },
    "& .-label": {
      fontWeight: 500,
      color: theme.palette.secondary.main,
    },
  },
  // Rating
  unsatisfied: {color: THEME.satisfaction.unsatisfied.color},
  somewhat_satisfied: {color: THEME.satisfaction.somewhat_satisfied.color},
  neutral: {color: THEME.satisfaction.neutral.color},
  satisfied: {color: THEME.satisfaction.satisfied.color},
  highly_satisfied: {color: THEME.satisfaction.highly_satisfied.color},
}));

export default useFilterMenuStyles;
