import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
// UI
import {
  Button,
  Typography,
  IconButton,
  InputBase,
  Chip,
} from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";
import CloseIcon from "@material-ui/icons/Close";
// Actions
import {
  sendManagedEmail,
  sendManagedEmailVerification,
  getManagedEmailSetupStatus,
  deleteManagedEmail,
} from "redux/actions/accountsActions";
import CustomCardHeader from "core/cards/CustomCardHeader";
import {useTranslation} from "react-i18next";
import {
  closeGeneralSuccessAlert,
  openGeneralSuccessAlert,
} from "redux/actions/settingsActions";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    alignItems: "center",
  },
  form: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    "& > *": {
      marginBottom: theme.spacing(4),
    },
  },
  root: {
    padding: theme.spacing(5, 4),
    overflow: "auto",
    flex: 1,
  },
  cardHeader: {padding: theme.spacing(0, 0, 4)},
  "@keyframes rotate": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  },
  rotatingAnimation: {
    animation: "$rotate 2s linear infinite reverse",
  },
}));

export default function ConnectedEmailsAddEditCard({
  handlePanel3Open,
  emailState,
}) {
  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const [email, setEmail] = emailState;
  const [loading, setLoading] = useState(new Set());
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const forwardingAddress = `fwdver_${current_user}@ensoconnectmail.com`;

  function isEditMode(email) {
    return !!managedEmail(email);
  }

  function managedEmail(email) {
    return user_profile?.managed_emails?.find((e) => e.managed_email === email);
  }

  function setLoadingElement(key, value) {
    setLoading((prev) => {
      let result = new Set(prev);

      if (value) {
        result.add(key);
      } else {
        result.delete(key);
      }

      return result;
    });
  }

  function handleSubmit() {
    setLoadingElement("submit", true);
    const body = {managed_email: email.trim(), enso_key: current_user};
    dispatch(sendManagedEmail(body)).finally(() => {
      setLoadingElement("submit", false);
    });
  }

  function handleSuccessAlertClose() {
    dispatch(closeGeneralSuccessAlert());
  }

  function successResponseAlert(message) {
    dispatch(
      openGeneralSuccessAlert({
        message,
        open: true,
        onClose: () => handleSuccessAlertClose(),
      }),
    );
  }

  function handleDelete(e) {
    setLoadingElement("delete", true);
    dispatch(deleteManagedEmail(e.trim())).finally(() => {
      setEmail("");
      setLoadingElement("delete", false);
      setConfirmDeleteOpen(false);
      handlePanel3Open(false);
    });
  }

  return (
    <div className={classes.root}>
      <div style={{display: "flex", justifyContent: "space-between"}}>
        <CustomCardHeader
          title={`${isEditMode(email) ? "Edit" : "Add"} email`}
          className={classes.cardHeader}
        />
        {isEditMode(email) ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            style={{padding: "0"}}
            onClick={() => handlePanel3Open(false)}
          >
            <CloseIcon />
          </IconButton>
        ) : (
          <div>
            <Button onClick={() => handlePanel3Open(false)}>Cancel</Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                loading.has("submit") || isEditMode(email) ? false : !email
              }
              onClick={() => {
                if (!isEditMode(email)) {
                  handleSubmit();
                }
              }}
            >
              Save
            </Button>
          </div>
        )}
      </div>
      <Typography style={{whiteSpace: "pre-line"}}>
        {t("add-edit-email-info")}
      </Typography>
      <div
        className={classes.outlinedContainer}
        style={{
          backgroundColor: "#F8F8F9",
          padding: ".5rem",
          borderRadius: ".5rem",
          marginTop: "1.5rem",
        }}
      >
        <Typography variant="caption" className={classes.sectionLabel}>
          {"Email"}
        </Typography>
        <InputBase
          fullWidth
          multiline
          maxRows={1}
          value={email}
          className={classes.notesInput}
          placeholder="john.doe@example.com"
          onChange={(e) => setEmail(e.target.value)}
          disabled={isEditMode(email)}
        />
      </div>
      {isEditMode(email) && (
        <>
          <hr />
          <b
            className="mt-1"
            style={{fontSize: "1rem", fontWeight: 600, lineHeight: "1.5rem"}}
          >
            Email Verification
          </b>
          <Typography className="mt-2" style={{whiteSpace: "pre-line"}}>
            {t("email-verification-info")}
          </Typography>
          <div
            className="mt-4"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{maxWidth: "70%"}}>
              <div style={{display: "flex"}}>
                <b
                  style={{
                    fontSize: "1rem",
                    fontWeight: 510,
                    lineHeight: "1.4rem",
                  }}
                >
                  Sending
                </b>
                {managedEmail(email)?.capabilities?.includes("send") ? (
                  <Button
                    variant="contained"
                    className="ml-2"
                    style={{
                      borderRadius: "5px",
                      background: "rgba(221, 240, 227, 1)",
                      color: "rgba(74, 164, 77, 1)",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "1rem",
                      maxHeight: "1.5rem",
                    }}
                  >
                    <div
                      style={{
                        height: ".5rem",
                        width: ".5rem",
                        borderRadius: "22.5rem",
                        background: "rgba(74, 164, 77, 1)",
                      }}
                    ></div>
                    <span className="ml-1">Connected</span>
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className="ml-2"
                    style={{
                      borderRadius: "5px",
                      background: "rgba(255, 238, 237, 1)",
                      color: "rgba(252, 99, 91, 1)",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "1rem",
                      maxHeight: "1.5rem",
                    }}
                  >
                    <div
                      style={{
                        height: ".5rem",
                        width: ".5rem",
                        borderRadius: "22.5rem",
                        background: "#FC635B",
                      }}
                    ></div>
                    <span className="ml-1">Not connected</span>
                  </Button>
                )}
                <CachedIcon
                  className={`ml-2 ${loading.has("send-status-check") ? classes.rotatingAnimation : ""}`}
                  style={{color: "rgba(47, 122, 251, 1)", cursor: "pointer"}}
                  onClick={() => {
                    setLoadingElement("send-status-check", true);
                    dispatch(
                      getManagedEmailSetupStatus({managed_email: email}),
                    ).finally(() => {
                      setLoadingElement("send-status-check", false);
                    });
                  }}
                />
              </div>
              <Typography className="mt-2" style={{whiteSpace: "pre-line"}}>
                {t("email-verification-sending-info")}
              </Typography>
            </div>
            {!managedEmail(email)?.capabilities?.includes("send") && (
              <Button
                variant="contained"
                color="primary"
                disabled={loading.has("send-send-verification")}
                onClick={() => {
                  setLoadingElement("send-send-verification", true);
                  dispatch(
                    sendManagedEmailVerification({
                      managed_email: email,
                      capability: "send",
                    }),
                  ).finally(() => {
                    setLoadingElement("send-send-verification", false);
                  });
                }}
                style={{maxHeight: "1.6rem"}}
              >
                Send Verification
              </Button>
            )}
          </div>
          <div
            className="mt-4"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{maxWidth: "70%"}}>
              <div style={{display: "flex"}}>
                <b
                  style={{
                    fontSize: "1rem",
                    fontWeight: 510,
                    lineHeight: "1.4rem",
                  }}
                >
                  Receiving
                </b>
                {managedEmail(email)?.capabilities?.includes("receive") ? (
                  <Button
                    variant="contained"
                    className="ml-2"
                    style={{
                      borderRadius: "5px",
                      background: "rgba(221, 240, 227, 1)",
                      color: "rgba(74, 164, 77, 1)",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "1rem",
                      maxHeight: "1.5rem",
                    }}
                  >
                    <div
                      style={{
                        height: ".5rem",
                        width: ".5rem",
                        borderRadius: "22.5rem",
                        background: "rgba(74, 164, 77, 1)",
                      }}
                    ></div>
                    <span className="ml-1">Connected</span>
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className="ml-2"
                    style={{
                      borderRadius: "5px",
                      background: "rgba(255, 238, 237, 1)",
                      color: "rgba(252, 99, 91, 1)",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "1rem",
                      maxHeight: "1.5rem",
                    }}
                  >
                    <div
                      style={{
                        height: ".5rem",
                        width: ".5rem",
                        borderRadius: "22.5rem",
                        background: "#FC635B",
                      }}
                    ></div>
                    <span className="ml-1">Not connected</span>
                  </Button>
                )}
                <CachedIcon
                  className={`ml-2 ${loading.has("receive-status-check") ? classes.rotatingAnimation : ""}`}
                  style={{color: "rgba(47, 122, 251, 1)", cursor: "pointer"}}
                  onClick={() => {
                    setLoadingElement("receive-status-check", true);
                    dispatch(
                      getManagedEmailSetupStatus({managed_email: email}),
                    ).finally(() => {
                      setLoadingElement("receive-status-check", false);
                    });
                  }}
                />
              </div>
              <Typography
                className="mt-2 mb-2"
                style={{whiteSpace: "pre-line"}}
              >
                {t("email-verification-receiving-info")}
              </Typography>
            </div>
            {!managedEmail(email)?.capabilities?.includes("receive") && (
              <Button
                variant="contained"
                color="primary"
                disabled={
                  loading.has("receive-send-verification") ||
                  !managedEmail(email)?.capabilities?.includes("send")
                }
                onClick={() => {
                  setLoadingElement("receive-send-verification", true);
                  dispatch(
                    sendManagedEmailVerification({
                      managed_email: email,
                      capability: "receive",
                    }),
                  ).finally(() => {
                    setLoadingElement("receive-send-verification", false);
                  });
                }}
                style={{maxHeight: "1.6rem"}}
              >
                Send Verification
              </Button>
            )}
          </div>
          <Typography style={{whiteSpace: "pre-line"}}>
            {t("email-verification-receiving-info-step-1")}
          </Typography>
          <Typography style={{whiteSpace: "pre-line"}}>
            {t("email-verification-receiving-info-step-2")}
          </Typography>
          <Typography style={{whiteSpace: "pre-line"}}>
            {t("email-verification-receiving-info-step-3")}
          </Typography>
          <div
            className={classes.outlinedContainer}
            style={{
              backgroundColor: "#F8F8F9",
              padding: ".5rem",
              borderRadius: ".5rem",
              marginTop: "1.5rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Typography variant="caption" className={classes.sectionLabel}>
                {"FORWARDING ADDRESS"}
              </Typography>
              <InputBase
                style={{color: "rgba(47, 122, 251, 1)"}}
                fullWidth
                maxRows={1}
                value={forwardingAddress}
                disabled
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                navigator.clipboard.writeText(forwardingAddress);
                successResponseAlert("Copied to clipboard");
              }}
              style={{maxHeight: "1.6rem"}}
            >
              Copy
            </Button>
          </div>
          <Chip
            onClick={() => setConfirmDeleteOpen(true)}
            style={{
              backgroundColor: "darkred",
              color: "white",
              marginTop: "1.5rem",
            }}
            clickable={true}
            avatar={<DeleteIcon style={{color: "white"}} />}
            size="small"
            label={"Permanently delete email"}
          />
          <ConfirmDialog
            disableDismiss
            open={confirmDeleteOpen}
            onClose={() => setConfirmDeleteOpen(false)}
            title={`Permanently delete your managed email?`}
            message={
              <span style={{whiteSpace: "pre-wrap"}}>
                "<b>{email}</b>" will be permanently deleted and this cannot be
                undone
              </span>
            }
            confirmLabel="Permanently delete"
            confirmAction={() => handleDelete(email)}
            cancelLabel="Cancel"
            confirmDisabled={loading.has("delete")}
            cancelAction={() => setConfirmDeleteOpen(false)}
          />
        </>
      )}
    </div>
  );
}
