import React from "react";
import {useDispatch, useSelector} from "react-redux";
// UI
import {CountryDropdown} from "react-country-region-selector";
import {Col, Row} from "reactstrap";
import {makeStyles} from "@material-ui/core/styles";
import {Button, IconButton, Typography} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
// Custom
import AddressAutocomplete from "components/TextFields/AddressAutocomplete";
import {EmptyListingAddress} from "components/Helpers/EmptyPanels";
import CustomCardHeader from "core/cards/CustomCardHeader";
import SaveContentButton from "../../MultiOption/SaveContentButton";
import ListingContentList from "../../Lists/ListingContentList";
import BackButton from "core/buttons/BackButton";
import InputField from "core/inputs/InputField";
// Actions
import {updateListing} from "redux/actions/listingsActions";
// Utilities
import {THEME} from "configuration/settings.js";
import clsx from "clsx";
import _ from "lodash";

const fields = {
  formatted: "Full Address",
  street: "Street",
  apt_number: "Apartment",
  city: "City",
  state: "Region",
  country: "Country",
  zip_code: "Zip/Postal Code",
  lat: "Latitude",
  lng: "Longitude",
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    overflowX: "hidden",
  },
  createRoot: {padding: theme.spacing(0, 5)},
  label: {color: "rgba(109, 113, 117, 1)"},
  dropdownLabel: {
    position: "absolute",
    top: 0,
    left: 0,
    color: "rgba(0, 0, 0, 0.6)",
    transform: "translate(27px, 9px) scale(0.75)",
  },
  dropdown: {
    color: THEME.text,
    minHeight: 62,
    backgroundColor: THEME.inputBg,
    borderRadius: 5,
    padding: "27px 8px 8px 8px",
    width: "100%",
    cursor: "pointer",
    fontSize: theme.typography.body1.fontSize,
    border: 0,
    outline: "none",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  dropdownViewOnly: {
    ...theme.typography.body1,
    minHeight: 0,
    cursor: "default",
    opacity: 1,
    transition: "none",
    appearance: "none",
    padding: 0,
    backgroundColor: "transparent",
  },
  header: {
    padding: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.primary.main + "0D"}`,
  },
  cancelBtn: {
    color: "#A8A8A8",
    fontSize: 14,
  },
  content: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden",
    overflowY: "auto",
    padding: theme.spacing(0, 4, 4),
  },
  closeBtn: {
    padding: 2,
    marginLeft: theme.spacing(3),
    marginRight: -theme.spacing(2),
  },
  closeBtnIcon: {
    fontSize: 18,
    color: "#AAA",
  },
  editBtn: {
    backgroundColor: "#FFF !important",
    fontWeight: 400,
    padding: 0,
  },
  editInfoLabel: {
    color: "rgba(67, 84, 96, 0.75)",
    fontWeight: 400,
  },
}));

export default function AddressPanel({
  data,
  outsideView,
  editData,
  listingId,
  disableEdit,
  onClose,
  goBack,
  isParentLoading,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const updatedFields = React.useRef([]);
  // Selectors
  const houses = useSelector((state) => state.defaultReducer.house_data_dict);
  const loadingContent = useSelector(
    (state) => state.defaultReducer.loading,
  ).listing_content;
  const isMobileView =
    useSelector((state) => state.defaultReducer.deviceType) === "mobile";
  // General
  const isHouseContentView = !!listingId;
  const listing = React.useMemo(
    () => (listingId ? houses[listingId] || {} : null),
    [houses, listingId],
  );
  // State
  const [disabledSave, setDisabledSave] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [autocompleteAddress, setAutocompleteAddress] = React.useState("");
  const [address, setAddress] = React.useState({
    apt_number: "",
    city: "",
    country: "",
    state: "",
    street: "",
    zip_code: "",
    formatted: "",
    lat: "",
    lng: "",
  });
  const dropdownViewOnly = isHouseContentView && !edit;

  React.useEffect(() => {
    if (!!edit) {
      return;
    }
    resetData(isHouseContentView ? listing?.address : data);
  }, [listing, data]);

  React.useEffect(() => {
    if (isHouseContentView && !outsideView) {
      const validFields =
        !!address.street?.trim() &&
        !!address.city?.trim() &&
        !!address.state?.trim() &&
        !!address.country?.trim() &&
        !!address.zip_code?.trim();
      if (validFields === disabledSave) setDisabledSave(!validFields);
    } else {
      editData && editData(address);
    }
  }, [address]);

  const resetData = (info) => {
    if (!info || _.isEqual(info, address)) {
      return;
    }
    setAddress(info);
    setAutocompleteAddress(info.formatted);
  };

  const addUpdatedField = (f) => {
    if (!updatedFields.current.includes(f)) {
      updatedFields.current.push(f);
    }
  };

  const handleSave = (option) => {
    const body = {address: null};
    if (updatedFields.current.includes("formatted")) {
      body.address = {formatted: address.formatted};
    }
    if (updatedFields.current.includes("street")) {
      body.address = {...body.address, street: address.street};
    }
    if (updatedFields.current.includes("apt_number")) {
      body.address = {...body.address, apt_number: address.apt_number};
    }
    if (updatedFields.current.includes("city")) {
      body.address = {...body.address, city: address.city};
    }
    if (updatedFields.current.includes("state")) {
      body.address = {...body.address, state: address.state};
    }
    if (updatedFields.current.includes("country")) {
      body.address = {...body.address, country: address.country};
    }
    if (updatedFields.current.includes("zip_code")) {
      body.address = {...body.address, zip_code: address.zip_code};
    }
    if (updatedFields.current.includes("lat")) {
      body.address = {...body.address, lat: address.lat};
    }
    if (updatedFields.current.includes("lng")) {
      body.address = {...body.address, lng: address.lng};
    }

    if (!body.address) {
      return;
    }
    setEdit((prev) => false);
    dispatch(updateListing({body, listing, option}));
    updatedFields.current = [];
  };

  const handleCancel = () => {
    setEdit((prev) => false);
    updatedFields.current = [];
    resetData(isHouseContentView ? listing?.address : data);
  };

  const handleAutocompletePlaceSelected = (place, inputRef) => {
    const newAddress = {
      apt_number: "",
      city: "",
      country: "",
      state: "",
      street: "",
      zip_code: "",
      formatted: inputRef.value,
      lat: place.geometry?.location.lat() || "",
      lng: place.geometry?.location.lng() || "",
    };

    addUpdatedField("formatted");
    _.each(place.address_components || [], (addrComp) => {
      if (addrComp.types.includes("street_number")) {
        addUpdatedField("street");
        newAddress.street += `${addrComp.long_name}`;
      }
      if (addrComp.types.includes("route")) {
        addUpdatedField("street");
        newAddress.street = !!newAddress.street
          ? `${newAddress.street} ${addrComp.long_name}`
          : addrComp.long_name;
      }
      if (addrComp.types.includes("locality")) {
        addUpdatedField("city");
        newAddress.city = addrComp.long_name;
      }
      if (
        !newAddress.city &&
        addrComp.types.includes("administrative_area_level_2")
      ) {
        addUpdatedField("city");
        newAddress.city = addrComp.long_name;
      }
      if (addrComp.types.includes("country")) {
        addUpdatedField("country");
        newAddress.country = addrComp.short_name;
      }
      if (addrComp.types.includes("postal_code")) {
        addUpdatedField("zip_code");
        newAddress.zip_code = addrComp.long_name;
      }
      if (addrComp.types.includes("administrative_area_level_1")) {
        addUpdatedField("state");
        newAddress.state = addrComp.long_name;
      }
    });
    addUpdatedField("lat");
    addUpdatedField("lng");

    setAddress((prev) => newAddress);
    setAutocompleteAddress((prev) => inputRef.value);
  };

  function handleAddresFieldsChange(field, value) {
    addUpdatedField(field);
    setAddress((prev) => ({...prev, [field]: value}));
  }

  function renderTextField(label, data, placeholder, field, optional) {
    const isViewOnly = isHouseContentView && !edit;
    return (
      <InputField
        fullWidth
        editOnly={!isViewOnly}
        disableEdit={disableEdit || isViewOnly}
        value={data || (isViewOnly ? "None" : "")}
        placeholder={placeholder}
        label={isViewOnly ? label : `${label}${optional ? " (Optional)" : ""}`}
        onChange={(e) => handleAddresFieldsChange(field, e.target.value)}
      />
    );
  }

  function getEditButton() {
    return (
      <Button
        color="secondary"
        disableElevation
        disableRipple
        className={classes.editBtn}
        onClick={() => setEdit((prev) => true)}
        disabled={isParentLoading || (isHouseContentView && loadingContent)}
      >
        Edit
      </Button>
    );
  }

  const outsideTitleRow = (
    <ListingContentList
      listingId={listing?.listing_id}
      onlyItems={["address"]}
      disableBackground
    />
  );

  return (
    <div
      className={clsx(classes.root, {
        [classes.createRoot]: !isHouseContentView && !outsideView,
      })}
    >
      {isHouseContentView ? (
        <CustomCardHeader
          title={
            !!goBack ? (
              <BackButton
                header={!isMobileView}
                color={!!isMobileView ? "secondary" : "primary"}
                goBack={goBack}
              />
            ) : (
              "Address"
            )
          }
          className={classes.header}
          action={
            edit ? (
              <>
                <Button
                  className="mr-2"
                  classes={{label: classes.cancelBtn}}
                  size="small"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <SaveContentButton
                  disabled={disabledSave || !updatedFields.current.length}
                  fields={updatedFields.current.map((f) => fields[f])}
                  onSave={handleSave}
                />
              </>
            ) : !!disableEdit ? null : (
              <>
                {getEditButton()}
                {!goBack && (
                  <IconButton className={classes.closeBtn} onClick={onClose}>
                    <CloseIcon className={classes.closeBtnIcon} />
                  </IconButton>
                )}
              </>
            )
          }
        />
      ) : !outsideView ? (
        <Typography className="mb-2 mt-3" variant="h1">
          Address
        </Typography>
      ) : null}
      {!!outsideView && outsideTitleRow}
      <div className={clsx({[classes.content]: isHouseContentView})}>
        {isParentLoading || (isHouseContentView && loadingContent) ? (
          <EmptyListingAddress loading />
        ) : (
          <>
            {(!isHouseContentView || edit) && (
              <Row style={{marginTop: 24}}>
                <Col xs={12} className="mb-3">
                  <AddressAutocomplete
                    label="Full address"
                    placeholder="Enter the property address"
                    onPlaceSelected={handleAutocompletePlaceSelected}
                    textFieldProps={{
                      value: address.formatted,
                      onChange: (e) =>
                        handleAddresFieldsChange("formatted", e.target.value),
                    }}
                  />
                </Col>
              </Row>
            )}
            {(edit || (!isHouseContentView && !!autocompleteAddress)) && (
              <Typography
                variant="h1"
                className={clsx("mb-2 mt-3", classes.editInfoLabel)}
              >
                {"You can edit any field that is not correct"}
              </Typography>
            )}
            {(!!isHouseContentView ||
              (!isHouseContentView && !!autocompleteAddress)) && (
              <>
                <Row style={{marginTop: edit ? 0 : 24}}>
                  <Col xs={12} md={6} className="mb-3">
                    {renderTextField(
                      "Street",
                      address.street,
                      "e.g: 123 Main Street",
                      "street",
                    )}
                  </Col>
                  <Col xs={12} md={6} className="mb-3">
                    {renderTextField(
                      "Apartment",
                      address.apt_number,
                      "Apt., suite, unit, etc.",
                      "apt_number",
                      true,
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} className="mb-3">
                    {renderTextField(
                      "City",
                      address.city,
                      "e.g: Toronto",
                      "city",
                    )}
                  </Col>
                  <Col xs={12} md={6} className="mb-3">
                    {renderTextField(
                      "Region",
                      address.state,
                      "e.g: Ontario",
                      "state",
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} className="mb-3">
                    <Typography
                      className={
                        dropdownViewOnly ? classes.label : classes.dropdownLabel
                      }
                      variant="caption"
                    >
                      {"Country"}
                    </Typography>
                    <CountryDropdown
                      classes={clsx(classes.dropdown, {
                        [classes.dropdownViewOnly]: dropdownViewOnly,
                      })}
                      priorityOptions={["CA", "US"]}
                      value={address.country}
                      valueType="short"
                      disabled={isHouseContentView && !edit}
                      onChange={(val) =>
                        handleAddresFieldsChange("country", val)
                      }
                    />
                  </Col>
                  <Col xs={12} md={6} className="mb-3">
                    {renderTextField(
                      "Zip / Postal Code",
                      address.zip_code,
                      "e.g: 12345",
                      "zip_code",
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} className="mb-3">
                    {renderTextField(
                      "Latitude",
                      address.lat,
                      "e.g: -33.712206",
                      "lat",
                    )}
                  </Col>
                  <Col xs={12} md={6} className="mb-3">
                    {renderTextField(
                      "Longitude",
                      address.lng,
                      "e.g: 150.311941",
                      "lng",
                    )}
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
