import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Box, Checkbox, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import CustomCellElement from "components/Helpers/CustomCellElement";
import VirtualizedTable from "ui/base/table/VirtualizedTable";
import FilterMenus from "components/Dialogs/FilterMenus";
import {updateGuest} from "redux/actions/guestsActions";
import {guestFilters, integrations} from "configuration/constants.js";
import {fieldParams, metricsParams} from "configuration/specs.js";
import {getNestedObject} from "utilities/helperFunctions";
import {isFirefox} from "react-device-detect";
import _ from "lodash";
import {EmptyListPanel} from "../../../../components/Helpers/EmptyPanels";
import GuestListTableHeader from "./GuestListTableHeader";
import CrmColumnsMenu from "../panel/CrmColumnsMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
  },
  columnsMenu: {
    maxHeight: 300,
    overflowY: "auto",
  },
  columnsMenuItem: {
    display: "flex",
    alignItems: "center",
  },
  tableSection: {
    flexGrow: 1,
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  column: (props) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: props.isMoz ? "pre-wrap" : "nowrap",
  }),
}));

export default function GuestListTable({
  create,
  filters,
  setFilters,
  exportButton,
  searchText,
  setSearchText,
  loadNextPage,
  previewData,
  hideCheckboxColumn,
  setSelectedIds,
  openGuestPanel,
  disableFilters,
  loadingData,
}) {
  const classes = useStyles({isMoz: isFirefox});
  const dispatch = useDispatch();
  const customFieldsMap = React.useRef({});
  const guests = useSelector((state) => state.defaultReducer.guests_dict);
  const groups = useSelector((state) => state.defaultReducer.listing_groups);
  const totals = useSelector((state) => state.defaultReducer.totals);
  const [filtersAnchorEl, setFiltersAnchorEl] = React.useState(null);
  const [selectedGuests, setSelectedGuests] = React.useState([]);
  const [writeFilter, setWriteFilter] = React.useState(null);
  const [guestListHits, setGuestListHits] = React.useState([]);
  const [sortParams, setSortParams] = React.useState({
    sortBy: "",
    sortDirection: "ASC",
  });
  const [columnsMenuAnchorEl, setColumnsMenuAnchorEl] = React.useState(null);
  const [allColumns, setAllColumns] = React.useState([]);
  const [selectedColumns, setSelectedColumns] = React.useState([
    ...(metricsParams["guests"]?.table_fields || []),
  ]);

  const tableFields = metricsParams["guests"]?.table_fields || [];
  const hasFilters = !!metricsParams["guests"]?.filters.length;
  const hasNextPage = previewData[0]?.start_key;
  const rowCount = hasNextPage
    ? guestListHits.length + 1
    : guestListHits.length;
  const loadingTable = !previewData.length;

  const allFilters = React.useMemo(() => {
    let newFilters = [...guestFilters.filterKeys];
    if (groups.length < 2)
      newFilters = newFilters.filter((f) => f !== "guest_group_id");
    return newFilters;
  }, [groups]);

  const toggleSelectAll = (e) => {
    const val = e.target.checked;
    setSelectedGuests(
      val ? [...guestListHits.map((g) => g.guest_id), "all"] : [],
    );
  };

  const onRowCheck = (guestId) => {
    if (selectedGuests.includes("all")) {
      setSelectedGuests((prev) =>
        prev.filter((g) => !(g === guestId || g === "all")),
      );
    } else {
      setSelectedGuests((prev) =>
        selectedGuests.includes(guestId)
          ? prev.filter((g) => g !== guestId)
          : [...prev, guestId],
      );
    }
  };

  const removeFilter = (selectedFilter) => {
    const newFilters = [...filters];
    const filterIndex = filters.findIndex(
      (f) => f.path === selectedFilter.path && f.value === selectedFilter.value,
    );
    if (filterIndex > -1) {
      newFilters.splice(filterIndex, 1);
      setFilters(newFilters);
    }
  };

  const searchBarProps = !disableFilters
    ? {
        enableFilters: true,
        filters,
        disableSelectedFilters: true,
        filterKeys: allFilters,
        handleDeleteFilter: removeFilter,
        setFilters,
      }
    : {};

  const columns = React.useMemo(() => {
    const newColumns = [];
    if (!hideCheckboxColumn) {
      newColumns.push({
        width: 1,
        label: (
          <Checkbox
            size="small"
            color="secondary"
            checked={selectedGuests.includes("all")}
            onChange={toggleSelectAll}
            style={{marginLeft: "4px"}}
          />
        ),
        field: "checkbox",
        dataKey: "checkbox",
        flexGrow: 0.5,
        customCell: true,
      });
    }
    newColumns.push({
      width: 1,
      label: "dot",
      field: "dot",
      dataKey: "dot",
      flexGrow: 0.2,
      customCell: true,
      hideLabel: true,
    });
    _.each(tableFields, (field) => {
      if (!selectedColumns.includes(field)) return;
      newColumns.push({
        field,
        width: 1,
        label: fieldParams[field].name,
        dataKey: fieldParams[field].path.slice(1).join("."),
        flexGrow: 1,
        customCell: true,
        sort: true,
        numeric: false,
      });
    });
    if (
      previewData?.length &&
      previewData[0]?.hits?.length &&
      previewData[0]?.hits[0]?.custom_fields
    ) {
      Object.keys(customFieldsMap.current).forEach((key, i) => {
        if (!selectedColumns.includes(key)) return;
        newColumns.push({
          field: `custom_field_${i}`,
          width: 1,
          label: fieldParams[key]?.name || key,
          dataKey: customFieldsMap.current[key],
          flexGrow: 1,
          customCell: true,
          sort: true,
          numeric: false,
        });
      });
    }
    return newColumns;
  }, [guestListHits, selectedGuests, selectedColumns, hideCheckboxColumn]);

  React.useEffect(
    () => setSelectedIds(selectedGuests.filter((g) => g !== "all")),
    [selectedGuests],
  );

  React.useEffect(() => setSelectedGuests([]), [filters]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setGuestListHits((prev) =>
        prev.map((g) =>
          guests[g.guest_id] ? {...g, ...guests[g.guest_id]} : g,
        ),
      );
    });
    return () => clearTimeout(timer);
  }, [guests]);

  React.useEffect(() => {
    const newHits = previewData?.length ? previewData[0].hits : [];
    if (sortParams.sortBy) sort(newHits);
    else {
      setSortParams({sortBy: "", sortDirection: "ASC"});
      setGuestListHits(newHits);
    }
    let newCustomFieldsMap = {};
    if (
      previewData?.length &&
      previewData[0]?.hits?.length &&
      previewData[0]?.hits[0]?.custom_fields
    ) {
      previewData[0].hits.forEach((hit) => {
        if (hit.custom_fields) {
          Object.keys(hit.custom_fields).forEach((key) => {
            newCustomFieldsMap[key] = `custom_fields.${key}`;
          });
        }
      });
    }
    customFieldsMap.current = newCustomFieldsMap;
    setAllColumns([
      ...new Set([...tableFields, ...Object.keys(newCustomFieldsMap)]),
    ]);
  }, [previewData, create]);

  React.useEffect(() => {
    if (sortParams.sortBy) {
      const timer = setTimeout(sort);
      return () => clearTimeout(timer);
    }
  }, [sortParams]);

  React.useEffect(() => setSelectedColumns([...tableFields]), []);

  React.useEffect(() => {
    if (hideCheckboxColumn && selectedGuests.length) setSelectedGuests([]);
  }, [hideCheckboxColumn]);

  const handleSearchInput = (val) => setSearchText(val),
    closeFiltersMenu = () => setFiltersAnchorEl(null),
    closeCoumnsMenu = () => setColumnsMenuAnchorEl(null),
    closeWriteFilter = () => setWriteFilter(null),
    isRowLoaded = ({index}) => !hasNextPage || index < guestListHits.length,
    clearAll = () => {
      handleSearchInput("");
      setSelectedGuests([]);
      setFilters([]);
    },
    viewProfile = (index) => {
      const selectedGuest = guestListHits[index];
      if (selectedGuest?.unread_messages) {
        dispatch(
          updateGuest({
            guest_id: selectedGuest.guest_id,
            field: "unread_messages",
            val: false,
            disableAlert: true,
          }),
        );
      }
      openGuestPanel(selectedGuest);
    },
    sort = (newHits) => {
      let sortKey = [sortParams.sortBy];
      const directions = [sortParams.sortDirection.toLowerCase()];
      const hits = newHits || guestListHits;
      if (sortParams.sortBy === "total") sortKey = ["amount"];
      const sortedData = _.orderBy(hits, sortKey, directions);
      setGuestListHits(sortedData);
    },
    onFilterSelect = (selectedFilter) => {
      const existingFilter = filters.find(
        (f) =>
          f.path === selectedFilter.path && f.value === selectedFilter.value,
      );
      if (!existingFilter) {
        if (!selectedFilter.value)
          setWriteFilter({...selectedFilter, value: ""});
        else setFilters([...filters, selectedFilter]);
      }
      closeFiltersMenu();
    },
    saveWriteFilter = () => {
      setFilters([...filters, writeFilter]);
      setWriteFilter(null);
    },
    onWriteFilterKeyDown = (event) => {
      if (event.key === "Escape") {
        closeWriteFilter();
        return false;
      } else if (!["Enter", "Tab"].includes(event.key)) {
        return false;
      }
      saveWriteFilter();
    },
    onWriteFilterChange = (e) => {
      const val = e.target.value;
      setWriteFilter((prev) => ({...prev, value: val}));
    },
    getCustomCell = (columnConfig, cellData, rowIndex) => {
      const columnDataKey = columnConfig.dataKey,
        cellValue = columnDataKey.includes(".")
          ? getNestedObject(guestListHits[rowIndex], columnDataKey.split("."))
          : cellData,
        field = columnDataKey.includes(".")
          ? columnDataKey.split(".").pop()
          : columnConfig.field,
        obj = columnDataKey.includes(".")
          ? {...guestListHits[rowIndex], [field]: cellValue}
          : guestListHits[rowIndex];
      return (
        <div className={classes.column}>
          <CustomCellElement
            data={cellValue}
            customClass={
              columnConfig.field === "checkbox"
                ? `guest-checkbox-${rowIndex}`
                : null
            }
            rowIndex={rowIndex}
            obj={obj}
            field={field}
            simpleBkgDate={columnDataKey.includes(".")}
            selectedValues={selectedGuests}
            viewProfile={viewProfile}
            onCheck={columnConfig.dataKey === "checkbox" ? onRowCheck : null}
          />
        </div>
      );
    },
    handleSort = ({sortBy, sortDirection}) =>
      setSortParams({sortBy, sortDirection}),
    rowGetter = ({index}) => {
      const data = guestListHits[index];
      return {
        ...data,
        integration: integrations[data.integration],
        name: data.name || "Unknown",
        email: data.email,
        checkbox: data.guest_id,
      };
    };

  return (
    <div className={classes.root}>
      <CrmColumnsMenu
        allColumns={allColumns}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        fieldParams={fieldParams}
        columnsMenuAnchorEl={columnsMenuAnchorEl}
        closeColumnsMenu={closeCoumnsMenu}
      />
      <FilterMenus
        filterList={hasFilters ? metricsParams["guests"].filters : []}
        anchorEl={filtersAnchorEl}
        onClose={closeFiltersMenu}
        onFilterSelect={onFilterSelect}
      />
      <GuestListTableHeader
        filters={filters}
        disableFilters={disableFilters}
        removeFilter={removeFilter}
        clearAll={clearAll}
        setColumnsMenuAnchorEl={setColumnsMenuAnchorEl}
        exportButton={exportButton}
        searchText={searchText}
        handleSearchInput={handleSearchInput}
        totals={totals}
        searchBarProps={searchBarProps}
        writeFilter={writeFilter}
        closeWriteFilter={closeWriteFilter}
        onWriteFilterChange={onWriteFilterChange}
        onWriteFilterKeyDown={onWriteFilterKeyDown}
        fieldParams={fieldParams}
      />
      <div className={classes.tableSection}>
        {loadingData || loadingTable ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{width: "100%", height: "100%", paddingTop: "20px"}}>
              <EmptyListPanel loading noTitle noSearchbar list="guest table" />
            </Box>
          </div>
        ) : !!guestListHits.length && !!selectedColumns.length ? (
          <Box height={"100%"} minWidth={columns.length * 150}>
            <VirtualizedTable
              rowCount={guestListHits.length}
              loaderRowCount={rowCount}
              rowGetter={rowGetter}
              columns={columns}
              onRowClick={({index, event}) => {
                if (event.clientX > 138 && hideCheckboxColumn) {
                  viewProfile(index);
                } else if (event.clientX > 138) {
                  onRowCheck(guestListHits[index].guest_id);
                }
              }}
              rowHeight={56}
              getCustomCell={getCustomCell}
              useInfiniteLoader
              isRowLoaded={isRowLoaded}
              loadMoreRows={loadNextPage}
              sort={handleSort}
              sortBy={sortParams.sortBy}
              sortDirection={sortParams.sortDirection}
              disableAutoHeaderHeight
            />
          </Box>
        ) : (
          <Typography
            variant="h2"
            align="center"
            className="mt-3"
            color="textSecondary"
          >
            {"No data found"}
          </Typography>
        )}
      </div>
    </div>
  );
}
