import React, {useContext} from "react";
import {useDispatch, useSelector} from "react-redux";
// UI
import useGuestPanelStyles from "styles/useGuestPanelStyles";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import TagList from "components/Lists/TagList";
import {
  Box,
  Button,
  IconButton,
  InputBase,
  SvgIcon,
  isWidthDown,
  isWidthUp,
  withWidth,
} from "@material-ui/core";
import {useFlags, withLDConsumer} from "launchdarkly-react-client-sdk";
import {ReactComponent as MessagesIcon} from "assets/icons/Icon_Messages.svg";
import "react-phone-input-2/lib/material.css";
// Custom
import InputField from "core/inputs/InputField";
import ChipSelect from "core/selects/ChipSelect";
import Rating from "components/TextFields/Rating";
import PrimaryButton from "core/buttons/PrimaryButton";
import CarouselDialog from "core/dialogs/CarouselDialog";
import LanguageSelector from "components/MultiOption/LanguageSelector";
import EnsoAILabel from "components/Misc/EnsoAILabel";
import GuestTypeSelector from "components/MultiOption/GuestTypeSelector";
// Actions
import {updateGuest as updGuest} from "redux/actions/guestsActions";
// Helpers & Utilities
import {
  validateEmail,
  getGuestContactInfo,
  getAffectedInboxArray,
} from "utilities/helperFunctions";
import {getProfile} from "redux/actions/accountsActions";
import {MessagesContext} from "routes/Messages";
import usePrevious from "hooks/usePrevious";
import {format} from "date-fns";
import clsx from "clsx";

function GuestPanel({guestId, handleRedirectClick = null, width}) {
  const classes = useGuestPanelStyles();
  const dispatch = useDispatch();
  const {currentInbox, setModifiedData} = useContext(MessagesContext);
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const guests = useSelector((state) => state.defaultReducer.guests_dict);
  const flags = useFlags();
  // State
  const [modalOpen, setModalOpen] = React.useState(false);
  const [description, setDescription] = React.useState(null);
  const [metadata, setMetadata] = React.useState(null);
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState(null);
  const [language, setLanguage] = React.useState("");
  const [validPhone, setValidPhone] = React.useState(false);
  const [validEmail, setValidEmail] = React.useState(false);
  const [didMount, setDidMount] = React.useState(false);
  const [assignedTo, setAssignedTo] = React.useState(null);
  const [selectedRating, setSelectedRating] = React.useState(null);
  const [guestInfoOpen, setGuestInfoOpen] = React.useState(false);
  const [guest, setGuest] = React.useState(guests[guestId]);

  // General
  const users = React.useMemo(() => {
    if (!user_profile?.enso_users) {
      return [];
    }
    return user_profile.enso_users.map((u) => ({
      ...u,
      picture: u.picture ?? u.profile_picture,
    }));
  }, [user_profile]);
  const IDImages = React.useMemo(() => {
    if (!!metadata?.identity_docs) {
      const newImages = {};
      metadata.identity_docs.forEach((id, ind) => {
        newImages[id.url ?? id.document_type] = {
          id: `${id.url ?? id.document_type}-${ind}`,
          img: id.url,
          title: id.document_type,
        };
      });
      return newImages;
    } else {
      return {};
    }
  }, [metadata]);
  const assignee = React.useMemo(() => {
    const euId = assignedTo?.enso_user_id || "";
    return users.find((u) => u.enso_user_id === euId) ?? {};
  }, [assignedTo, users]);
  const {guest_email, guest_phone} = React.useMemo(
    () => getGuestContactInfo(guest),
    [guest],
  );
  const extraInfoLabel = React.useMemo(() => {
    if (!metadata) {
      return "3 more fields";
    }
    const extraFields = [];
    if (!!metadata?.address) {
      extraFields.push("Address");
    }
    if (!!metadata?.identity_docs?.length) {
      extraFields.push("Collected ID");
    }
    if (!!metadata?.date_of_birth) {
      extraFields.push("Birthday");
    }
    extraFields.push(...["Response Time", "Language", "Notes"]);

    if (extraFields.length > 3) {
      return `${extraFields[0]}, ${extraFields[1]} & ${extraFields.length - 3} more fields`;
    } else {
      return `${extraFields.join(" & ")} fields`;
    }
  }, [metadata]);
  const prevGuest = usePrevious(guest);

  React.useEffect(() => setDidMount(true), []);
  React.useEffect(() => setGuestInfoOpen((prev) => false), [guestId]);

  React.useEffect(() => {
    if (!guests[guestId] && prevGuest?.guest_id === guestId) {
      return;
    } else {
      setGuest((prev) => guests[guestId]);
    }
  }, [guests, guestId]);

  React.useEffect(() => {
    if (!current_user || !!user_profile?.enso_users[0]?.scopes) {
      return;
    }
    const params = `enso_key=${current_user}`;
    dispatch(getProfile(params, true));
  }, [current_user]);

  React.useEffect(() => {
    if (
      !prevGuest ||
      prevGuest.guest_id !== guest.guest_id ||
      prevGuest.bills != guest.bills
    ) {
      setSelectedRating((prev) => guest.rating);
      resetPhone();
      resetEmail();
      resetLang();
      setDescription(guest?.description);
      setAssignedTo({enso_user_id: guest.assigned_to});
    }
    setMetadata((prev) => guest.metadata);
  }, [guest]);

  const handleTagsChange = (newTags) => {
    let currentTags = guest.tags || [];
    let isAddingInquiry =
      !currentTags.includes("inquiry") && newTags.includes("inquiry");
    let isAddingArchived =
      !currentTags.includes("archived") && newTags.includes("archived");
    let isAddingPriority =
      !currentTags.includes("priority") && newTags.includes("priority");
    let isRemovingInquiry =
      currentTags.includes("inquiry") && !newTags.includes("inquiry");
    let isRemovingArchived =
      currentTags.includes("archived") && !newTags.includes("archived");
    let isRemovingPriority =
      currentTags.includes("priority") && !newTags.includes("priority");
    let currInbox = null;
    if (isAddingInquiry || isRemovingInquiry) {
      currInbox = currentInbox === "inquiry" ? null : currentInbox;
      setModifiedData({
        addToInbox: isAddingInquiry ? "inquiry" : currInbox,
        guest: guestId,
        subtractFromInbox: isAddingInquiry ? currInbox : "inquiry",
      });
    } else if (isAddingArchived || isRemovingArchived) {
      currInbox = getAffectedInboxArray(guest, currentInbox, "all", true);
      setModifiedData({
        addToInbox: isAddingArchived ? "archived" : currInbox,
        guest: guestId,
        subtractFromInbox: isAddingArchived ? currInbox : "archived",
      });
    } else if (isAddingPriority || isRemovingPriority) {
      currInbox = currentInbox === "priority" ? null : currentInbox;
      setModifiedData({
        addToInbox: isAddingPriority ? "priority" : currInbox,
        guest: guestId,
        subtractFromInbox: isAddingPriority ? currInbox : "priority",
      });
    }
    updateGuest("tags", newTags, true);
  };

  React.useEffect(() => {
    setValidEmail(email === "" || validateEmail(email));
  }, [email]);

  React.useEffect(() => {
    if (!didMount) return;
    const timer = setTimeout(() => {
      updateGuest(
        "description",
        description,
        description !== guest.description,
      );
    }, 250);
    return () => clearTimeout(timer);
  }, [description]);

  const resetPhone = () => setPhone((prev) => guest_phone);
  const resetEmail = () => setEmail(guest_email);
  const resetLang = () => setLanguage(guest.language);
  const handleCancelEditingPhone = () => resetPhone();
  const handleCancelEditingEmail = () => resetEmail();

  const updateGuest = (field, val, update) => {
    update && dispatch(updGuest({guest_id: guest.guest_id, field, val}));
  };

  const savePhone = () => {
    if (validPhone) {
      updateGuest("phone", "+" + phone, true);
    }
  };

  const saveEmail = () => {
    let val = "";
    val = email || " ";
    if (val !== guest_email) {
      updateGuest("email", val, true);
    }
  };

  const saveLang = (newLang) => {
    setLanguage((prev) => newLang);
    updateGuest("language", newLang, true);
  };

  const onRatingChange = (val) => {
    setSelectedRating((prev) => val);
    updateGuest("rating", val, true);
  };

  const handleGuestTypeChange = (newGuestType) => {
    let currentGuestType = guest.guest_type ?? "guest";
    if (newGuestType !== currentGuestType) {
      setModifiedData({
        addToInbox: newGuestType,
        guest: guestId,
        subtractFromInbox: currentGuestType,
      });
    }
    updateGuest("guest_type", newGuestType, true);
  };

  const IDImagesModal = (
    <CarouselDialog
      open={!!modalOpen}
      images={!!modalOpen ? [IDImages[modalOpen.split("id-")[1]]] : []}
      header="ID photo"
      onClose={() => setModalOpen(false)}
    />
  );

  const emailInput = (
    <InputField
      fullWidth
      useButtons
      label="Email"
      value={email}
      emptyLabel="No email"
      isInvalid={!validEmail}
      disablePartialContentLayer
      placeholder="guest.email@example.com"
      onChange={(e) => setEmail(e.target.value)}
      onCancel={handleCancelEditingEmail}
      onSave={saveEmail}
    />
  );

  const phoneInput = (
    <InputField
      fullWidth
      useButtons
      type="phone"
      label="Phone"
      value={phone}
      minWidth={isWidthDown("sm", width) ? 200 : undefined}
      disablePartialContentLayer
      onChange={setPhone}
      onSave={savePhone}
      onCancel={handleCancelEditingPhone}
      isInvalid={!validPhone}
      phoneInputProps={{
        allowEmpty: true,
        setValidPhone: setValidPhone,
      }}
    />
  );

  return (
    <div className={classes.container}>
      {IDImagesModal}
      <div className={classes.nameRow}>
        {!!handleRedirectClick && (
          <IconButton className={classes.iconBtn} onClick={handleRedirectClick}>
            <SvgIcon
              fontSize="large"
              viewBox="0 0 35 35"
              className={classes.icon}
              component={MessagesIcon}
            />
          </IconButton>
        )}
        <Typography className={classes.guestName} variant="h4">
          {guest.name}
        </Typography>
        <Box flex={1} textAlign={"right"}>
          <GuestTypeSelector
            selected={guest.guest_type ?? "guest"}
            onClick={handleGuestTypeChange}
          />
        </Box>
      </div>
      <div className={classes.row}>
        <Box>
          <Rating
            edit
            filled
            useIcon
            value={selectedRating}
            onValueChange={onRatingChange}
          />
        </Box>
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Box>
          <ChipSelect
            useAvatar
            options={users}
            value={assignee}
            emptyOption="Unassigned"
            objKeys={{
              value: "enso_user_id",
              alt: "name",
              src: "picture",
              text: "name",
            }}
            onChange={(newValue) => {
              if (!!newValue) {
                const user = users.find((u) => u.enso_user_id === newValue);
                const new_user = user
                  ? {name: user.name, enso_user_id: user.enso_user_id}
                  : assignedTo;
                setAssignedTo(new_user);
                updateGuest("assigned_to", new_user.enso_user_id, true);
              } else {
                setAssignedTo(null);
                updateGuest("assigned_to", null, true);
              }
            }}
          />
        </Box>
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <TagList
          lgTag
          objectId={guestId}
          flexItem
          tags={guest.tags || []}
          onChange={handleTagsChange}
        />
      </div>
      {isWidthDown("xs", width) && (
        <Box width={"100%"} className={classes.mobileCol}>
          <Box flex={1}>{emailInput}</Box>
          <Box flex={1}>{phoneInput}</Box>
        </Box>
      )}
      <div className={classes.columnContainer}>
        {/* Column 1 */}
        <div className={classes.col}>
          {isWidthUp("sm", width) && <Box width="100%">{emailInput}</Box>}
          {!!guestInfoOpen && !!metadata?.date_of_birth && (
            <InputField
              fullWidth
              disableEdit
              label="Birthday"
              disablePartialContentLayer
              value={format(metadata.date_of_birth, "MMMM d, y")}
            />
          )}
          {!!guestInfoOpen && (
            <InputField
              fullWidth
              disableEdit
              disablePartialContentLayer
              label="Average response time"
              value={`${Math.round(guest.response_time / 6) / 10}min`}
            />
          )}
        </div>
        {/* Column 2 */}
        <div className={classes.col}>
          {isWidthUp("sm", width) && <Box width="100%">{phoneInput}</Box>}
          {!!guestInfoOpen && !!metadata?.address && (
            <InputField
              fullWidth
              disableEdit
              label="Address"
              disablePartialContentLayer
              value={metadata.address.formatted}
            />
          )}
          {!!guestInfoOpen && (
            <LanguageSelector
              plain
              preventOverflow
              selectedLanguage={language}
              setSelectedLang={saveLang}
            />
          )}
          {!!guestInfoOpen && !!metadata?.identity_docs?.length && (
            <Box width={"100%"}>
              <Typography variant="caption" className={classes.sectionLabel}>
                {"Collected ID"}
              </Typography>
              {metadata.identity_docs.map((idDoc, i) => (
                <div
                  key={`${idDoc.document_type}-${i}`}
                  className={clsx(classes.textButtonRow, {
                    "-last": i === metadata.identity_docs.length,
                  })}
                >
                  <Typography>
                    {idDoc.document_type.includes("Front")
                      ? "Front ID"
                      : idDoc.document_type.includes("Back")
                        ? "Back ID"
                        : idDoc.document_type}
                  </Typography>
                  <PrimaryButton
                    label="view"
                    variant="outlined"
                    color="secondary"
                    className={classes.viewButton}
                    onClick={() =>
                      setModalOpen(`id-${idDoc.url ?? idDoc.document_type}`)
                    }
                  />
                </div>
              ))}
            </Box>
          )}
        </div>
      </div>
      {!!metadata?.ai_summary && flags.advancedAi && (
        <div className={classes.textButtonRow}>
          <Box width="100%">
            <Typography variant="caption" className={classes.sectionLabel}>
              {"Conversation Summary"}{" "}
              <EnsoAILabel text="Conversation summaries are powered by EnsoAI" />
            </Typography>
            <Typography>{metadata?.ai_summary}</Typography>
          </Box>
        </div>
      )}
      {!!guestInfoOpen && (
        <>
          {flags.advancedAi && (
            <>
              <Typography variant="caption" className={classes.sectionLabel}>
                {"Message Topics"}{" "}
                <EnsoAILabel text="Message topics are powered by EnsoAI" />
              </Typography>
              <Typography>
                {(metadata?.message_types || []).join(", ")}
              </Typography>
            </>
          )}
          <div className={classes.outlinedContainer}>
            <Typography variant="caption" className={classes.sectionLabel}>
              {"Notes"}
            </Typography>
            <InputBase
              fullWidth
              multiline
              maxRows={5}
              value={description || ""}
              className={classes.notesInput}
              placeholder="Enter your notes here."
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </>
      )}
      <Button
        variant="text"
        color="secondary"
        fullWidth
        disableRipple
        disableElevation
        className={classes.blueButton}
        onClick={() => setGuestInfoOpen((prev) => !prev)}
      >
        <Typography color="secondary" style={{fontWeight: 500}}>
          {!!guestInfoOpen ? "See less" : `See ${extraInfoLabel}`}
        </Typography>
      </Button>
    </div>
  );
}

export default withWidth()(withLDConsumer()(GuestPanel));
