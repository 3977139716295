import React, {useState} from "react";
import {MenuItem, Checkbox, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import SearchBar from "../../../../core/bars/SearchBar";
import CustomMenu from "core/menus/CustomMenu";

const useStyles = makeStyles((theme) => ({
  columnsMenu: {
    maxHeight: 300,
    overflowY: "auto",
  },
  columnsMenuItem: {
    display: "flex",
    alignItems: "center",
  },
}));

const CrmColumnsMenu = ({
  allColumns,
  selectedColumns,
  setSelectedColumns,
  fieldParams,
  columnsMenuAnchorEl,
  closeColumnsMenu,
}) => {
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState("");

  const filteredColumns = allColumns.filter((field) => {
    const fieldName = fieldParams[field]?.name || field;
    return fieldName.toLowerCase().includes(searchQuery.toLowerCase());
  });

  return (
    <CustomMenu
      open={Boolean(columnsMenuAnchorEl)}
      anchorEl={columnsMenuAnchorEl}
      placement="bottom"
      width="200px"
      hideScrollbar
      onClose={closeColumnsMenu}
      content={
        <div id="columns-menu" className={classes.columnsMenu}>
          <SearchBar
            style={{marginLeft: "0px", marginBottom: "10px"}}
            handleSearchInput={(val) => {
              setSearchQuery(val);
            }}
          />
          {filteredColumns.map((field) => (
            <MenuItem
              key={field}
              className={classes.columnsMenuItem}
              onClick={() =>
                setSelectedColumns((prev) =>
                  selectedColumns.includes(field)
                    ? prev.filter((f) => f !== field)
                    : [...new Set([...prev, field])],
                )
              }
            >
              <Checkbox checked={selectedColumns.includes(field)} />
              <Typography
                style={{
                  maxWidth: "300px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {fieldParams[field]?.name || field}
              </Typography>
            </MenuItem>
          ))}
        </div>
      }
    />
  );
};

export default CrmColumnsMenu;
