import React from "react";
import {useSelector} from "react-redux";
import {isMobile} from "react-device-detect";
// UI
import {
  DatePickerCalendar,
  DateRangePickerCalendar,
  START_DATE,
  END_DATE,
} from "react-nice-dates";
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from "@material-ui/core";
import RightArrowIcon from "@material-ui/icons/ArrowForwardIos";
import useCalendarStyles from "styles/useCalendarStyles";
import useFilterMenuStyles from "styles/useFilterMenuStyles";
import SearchIcon from "@material-ui/icons/Search";
import "react-nice-dates/build/style.css";
// Custom
import ListingList from "components/Lists/ListingList";
import CustomMenu from "core/menus/CustomMenu";
import {CustomCountryFlag} from "../Helpers/filterHelpers";
import {ChannelIcon} from "../Helpers/filterHelpers";
import {StageIcon} from "../Helpers/filterHelpers";
import SearchBar from "core/bars/SearchBar";
// Utilities
import {
  bookingChannelLabels,
  bookingStatusLabels,
  bookingTagsLabels,
  countries,
  guestIntegrationLabels,
  guestStageLabels,
  languages,
  messageChannelLabels,
  messageSenderLabels,
  questionTypeLabels,
  rangeFilters,
  specialFilters,
} from "configuration/constants";
import {currencyEnum} from "configuration/enums";
import {actionParams, fieldParams} from "configuration/specs";
import {enCA} from "date-fns/locale";
import {capitalize} from "utilities/helperFunctions";
import clsx from "clsx";
import _ from "lodash";

const languageKeys = Object.keys(languages);
const filtersWithSearchbar = ["booking_tags", "guest_tags", "listing_tags"];

function OptionItem({filter, data, customFieldOptions, onClick}) {
  const classes = useFilterMenuStyles();
  const userProfile = useSelector((state) => state.defaultReducer.user_profile);
  const guests = useSelector((state) => state.defaultReducer.guests_dict);
  const users = userProfile.enso_users;
  const user =
    filter === "assigned_to"
      ? (users.find((u) => u.enso_user_id === data) ?? {name: "Unassigned"})
      : null;
  const img = user?.profile_picture ?? user?.picture;

  const getData = () => {
    let content = "";
    switch (filter) {
      case "booking_action":
        content = actionParams[data].name;
        break;
      case "guest_status":
      case "booking_status":
        content = bookingStatusLabels[data];
        break;
      case "booking_tags":
        content = bookingTagsLabels[data];
        break;
      case "question_type":
        content = questionTypeLabels[data];
        break;
      case "message_channel":
        content = messageChannelLabels[data];
        break;
      case "message_sender":
        content = messageSenderLabels[data];
        break;
      case "guest_tags":
        content = customFieldOptions["guest_tags"][data];
        break;
      case "guest_language":
        content = languages[data];
        break;
      case "guest_stage":
        content = <StageIcon stage={data} textOnly />;
        break;
      case "guest_id":
        content = guests[data]?.name ?? data;
        break;
      case "listing_active":
        content = !!data ? "Yes" : "No";
        break;
      case "listing_tags":
        content = customFieldOptions["listing_tags"][data];
        break;
      case "rating":
        const type = data.toLocaleLowerCase().replaceAll(" ", "_");
        content = (
          <ListItemText
            primary={data}
            primaryTypographyProps={{className: classes[type]}}
          />
        );
        break;
      case "assigned_to":
        content = (
          <>
            <ListItemAvatar className={classes.listItemAvatar}>
              <Avatar
                alt={user.name}
                className="-avatar"
                src={!!img && img !== "False" ? img : null}
              />
            </ListItemAvatar>
            <ListItemText primary={user.name} />
          </>
        );
        break;
      case "guest_channel":
      case "guest_integration":
      case "booking_channel":
        content = (
          <>
            <ListItemAvatar className={classes.listItemIcon}>
              <ChannelIcon channel={data} />
            </ListItemAvatar>
            <ListItemText primary={data} />
          </>
        );
        break;
      case "guest_country":
      case "country":
        content = (
          <>
            <ListItemAvatar className={classes.listItemFlag}>
              <CustomCountryFlag countryCode={data} />
            </ListItemAvatar>
            <ListItemText primary={countries[data]} />
          </>
        );
        break;

      default:
        content = data;
        break;
    }
    return content;
  };

  return (
    <MenuItem
      button
      disableRipple
      onClick={onClick}
      className={classes.menuItem}
    >
      {getData()}
    </MenuItem>
  );
}

export default function FilterMenus({
  filterList,
  anchorEl,
  onFilterSelect,
  onClose,
  fixedWidth,
  preferredFilters,
  customFieldData,
  isInsideModal,
}) {
  const calendarStyles = useCalendarStyles();
  const filterClicked = React.useRef(false);
  const guests = useSelector((state) => state.defaultReducer.guests_dict);
  const listings = useSelector((state) => state.defaultReducer.house_data);
  const userProfile = useSelector((state) => state.defaultReducer.user_profile);
  const listingGroups = useSelector(
    (state) => state.defaultReducer.listing_groups,
  );
  // State
  const [optionsAnchorEl, setOptionsAnchorEl] = React.useState(null);
  const [selectedFilter, setSelectedFilter] = React.useState(null);
  const [availableLangs, setAvailableLangs] = React.useState([]);
  const [langSearch, setLangSearch] = React.useState("");
  const [showAllOptions, setShowAllOptions] = React.useState(
    !preferredFilters?.length,
  );
  const [calendarFocus, setCalendarFocus] = React.useState(START_DATE);
  const [rangeDates, setRangeDates] = React.useState({start: null, end: null});
  const [searchText, setSearchText] = React.useState("");
  // General
  const users = userProfile.enso_users;
  const classes = useFilterMenuStyles({
    maxListingsHeight:
      listings.length > 12 ? 500 : listings.length * 40 + 53 + 44, // ((# of listings * item height) + searchbar container height + (modal title height & modal padding))
    maxLangsHeight:
      availableLangs.length > 12
        ? 500
        : !availableLangs.length
          ? 130
          : availableLangs.length * 33 + 100,
  });
  const filterMenuStyle =
    !!fixedWidth && !isInsideModal ? {width: fixedWidth} : undefined;
  const extraFilterMenuClass = !!fixedWidth
    ? classes.filterMenuPlacement
    : undefined;
  const extraOptionMenuClass = classes.optionMenuPlacement;
  const filtersMenuProps = isMobile
    ? {
        disablePortal: true,
        modifiers: {
          flip: {enabled: true},
          preventOverflow: {
            enabled: true,
            boundariesElement: "scrollParent",
          },
        },
        fitAnchorElHeight: true,
        bottomPadding: 110,
      }
    : {
        modifiers: {
          flip: {enabled: true},
          preventOverflow: {
            enabled: true,
            boundariesElement: "viewport",
          },
        },
      };
  const mobileOptionsMenuProps = isMobile
    ? {
        disablePortal: true,
        bottomPadding: 200,
      }
    : null;

  const customFieldOptions = React.useMemo(() => {
    let customOptions = {};
    const customFieldOptionsToGenerate = [
      "guest_tags",
      "booking_channel",
      "listing_tags",
    ];
    const optionsGenerator = function (data) {
      let result = {};
      _.each(data, (element) => {
        const formattedEl = capitalize(element, "_");
        result[element] = formattedEl;
      });
      return result;
    };
    for (let field of customFieldOptionsToGenerate) {
      let objectName = capitalize(field.split("_")[0]);
      let data = customFieldData?.[objectName]?.find((f) => f.field == field)[
        "data"
      ];
      customOptions[field] = optionsGenerator(data);
    }
    return customOptions;
  }, [customFieldData]);

  const filterOptions = React.useMemo(() => {
    const filters = !!preferredFilters?.length
      ? [...preferredFilters, ...filterList]
      : filterList;
    const newOptions = {};

    // Enso User
    if (filters.includes("assigned_to")) {
      newOptions.assigned_to = [
        "Unassigned",
        ...(users?.map((u) => u.enso_user_id) || []),
      ];
    }
    // Guest
    if (filters.includes("guest_id")) {
      newOptions.guest_id = Object.keys(guests);
    }
    if (filters.includes("guest_tags")) {
      newOptions.guest_tags = Object.keys(customFieldOptions["guest_tags"]);
    }
    if (filters.includes("booking_tags")) {
      newOptions.booking_tags = Object.keys(bookingTagsLabels);
    }
    if (filters.includes("guest_stage")) {
      newOptions.guest_stage = Object.keys(guestStageLabels);
    }
    if (filters.includes("guest_status")) {
      newOptions.guest_status = Object.keys(bookingStatusLabels);
    }
    if (filters.includes("guest_channel")) {
      newOptions.guest_channel = userProfile?.channels;
    }
    if (filters.includes("guest_integration")) {
      newOptions.guest_integration = Object.keys(guestIntegrationLabels);
    }
    if (filters.includes("guest_language")) {
      newOptions.guest_language = "language";
    }
    if (filters.includes("guest_country")) {
      newOptions.guest_country = ["US", "CA", "FR"];
    }
    if (filters.includes("guest_listing")) {
      newOptions.guest_listing = [];
    }
    if (filters.includes("rating")) {
      newOptions.rating = rangeFilters.rating.values;
    }
    if (filters.includes("total_rev")) {
      newOptions.total_rev = rangeFilters.total_rev.values;
    }
    if (filters.includes("num_listings")) {
      newOptions.num_listings = rangeFilters.num_listings.values;
    }
    if (filters.includes("guest_inquiry")) {
      newOptions.guest_inquiry = rangeFilters.guest_inquiry.values;
    }
    if (filters.includes("response_time")) {
      newOptions.response_time = rangeFilters.response_time.values;
    }
    if (filters.includes("guest_bookings")) {
      newOptions.guest_bookings = rangeFilters.guest_bookings.values;
    }
    if (filters.includes("booking_action")) {
      newOptions.booking_action = [
        "pending_checkin",
        "pending_checkout",
        "pending_upsell",
        "verify_guest",
      ];
    }
    if (filters.includes("guest_group_id")) {
      newOptions.guest_group_id = [
        {group_id: null, name: "None"},
        ...listingGroups.filter((g) => g.group_id !== "ALL"),
      ];
    }
    // Listing
    if (filters.includes("listing_id")) {
      newOptions.listing_id = [];
    }
    if (filters.includes("country")) {
      newOptions.country = ["US", "CA", "FR"];
    }
    if (filters.includes("listing_active")) {
      newOptions.listing_active = [true, false];
    }
    if (filters.includes("listing_tags")) {
      newOptions.listing_tags = Object.keys(customFieldOptions["listing_tags"]);
    }
    // Message
    if (filters.includes("message_channel")) {
      newOptions.message_channel = Object.keys(messageChannelLabels);
    }
    if (filters.includes("message_sender")) {
      newOptions.message_sender = Object.keys(messageSenderLabels);
    }
    if (filters.includes("question_type")) {
      newOptions.question_type = Object.keys(questionTypeLabels);
    }
    // Booking
    if (filters.includes("booking_channel")) {
      newOptions.booking_channel = Object.keys(bookingChannelLabels).concat(
        Object.keys(customFieldOptions["booking_channel"]),
      );
    }
    if (filters.includes("booking_payment_currency")) {
      newOptions.booking_payment_currency = Object.values(currencyEnum);
    }
    if (filters.includes("total_guest_paid")) {
      newOptions.total_guest_paid = rangeFilters.total_guest_paid.values;
    }
    if (filters.includes("booking_payout")) {
      newOptions.booking_payout = rangeFilters.booking_payout.values;
    }
    if (filters.includes("checkin_date")) {
      newOptions.checkin_date = "range";
    }
    if (filters.includes("checkout_date")) {
      newOptions.checkout_date = "range";
    }
    if (filters.includes("total_people_booked")) {
      newOptions.total_people_booked = rangeFilters.total_people_booked.values;
    }
    if (filters.includes("booking_listing")) {
      newOptions.booking_listing = [];
    }
    // Root
    if (filters.includes("booking_status")) {
      newOptions.booking_status = Object.keys(bookingStatusLabels).filter(
        (k) => k !== "inquiry",
      );
    }

    return newOptions;
  }, [filterList, preferredFilters, userProfile, guests, customFieldData]);

  React.useEffect(() => {
    if (!filterClicked.current) {
      return;
    }
    filterClicked.current = false;
  }, [filterClicked.current]);

  React.useEffect(() => {
    if (!anchorEl) {
      setLangSearch((prev) => "");
      setShowAllOptions((prev) => !preferredFilters?.length);
    }
  }, [anchorEl]);

  React.useEffect(() => {
    let timer = null;
    if (!langSearch) {
      setAvailableLangs((prev) => languageKeys);
    } else if (!!langSearch) {
      timer = setTimeout(() => {
        setAvailableLangs((prev) =>
          languageKeys.filter((l) => {
            if (
              languages[l].toLowerCase().indexOf(langSearch.toLowerCase()) !==
              -1
            ) {
              return true;
            } else {
              return false;
            }
          }),
        );
      }, 50);
    }

    return () => clearTimeout(timer);
  }, [langSearch]);

  const handleFiltersClose = (force) => {
    if (
      !force &&
      (["date", "range", "language"].includes(filterOptions[selectedFilter]) ||
        ["guest_listing", "booking_listing"].includes(selectedFilter) ||
        filtersWithSearchbar.includes(selectedFilter))
    ) {
      return;
    }
    setSearchText((prev) => "");
    onClose();
    setOptionsAnchorEl((prev) => null);
  };

  const handleOptionsClose = () => {
    if (filterClicked.current) {
      return;
    }
    setOptionsAnchorEl((prev) => null);
    setSelectedFilter((prev) => null);
    handleFiltersClose(true);
  };

  const handleDateSelect = (newDate) => {
    handleOptionSelect(newDate.getTime());
  };

  const handleRangeSelect = (newDate, isCheckout) => {
    if (!isCheckout) {
      setRangeDates((prev) => ({...prev, start: newDate}));
      setCalendarFocus((prev) => END_DATE);
    } else {
      setRangeDates((prev) => ({...prev, end: newDate}));
      setCalendarFocus((prev) => START_DATE);
      handleOptionSelect([rangeDates.start.getTime(), newDate.getTime()], true);
    }
  };

  const handleOptionSelect = (filter, isRange) => {
    setOptionsAnchorEl((prev) => null);
    setSelectedFilter((prev) => null);
    onClose();
    if (isRange) {
      setRangeDates((prev) => ({start: null, end: null}));
    }
    onFilterSelect(
      {
        id: selectedFilter,
        path: selectedFilter,
        value: filter,
        type: "select",
      },
      !!isRange,
    );
  };

  const handleFilterSelect = (e, filter, type, isSpecialFilter = false) => {
    const el = e.currentTarget;
    if (type === "select") {
      filterClicked.current = true;
      setOptionsAnchorEl((prev) => el);
      setSelectedFilter((prev) => filter);
    } else {
      filterClicked.current = false;
      let isRange = false;
      onFilterSelect(
        {
          id: filter,
          path: filter,
          type: isSpecialFilter ? specialFilters[filter].type : "write",
        },
        isRange,
        isSpecialFilter,
      );
      handleOptionsClose();
    }
  };

  function getListingItem(house, waypoint) {
    return (
      <>
        {!!waypoint && waypoint}
        <ListItem
          button
          disableRipple
          className={classes.listItem}
          onClick={() => handleOptionSelect(house.listing_id)}
        >
          <ListItemText
            primary={house.nickname || house.name}
            primaryTypographyProps={{className: "-list-item-text"}}
          />
        </ListItem>
      </>
    );
  }

  function getFiltersContent() {
    return (
      <MenuList id="filters-menu" className={classes.menuList}>
        {!!preferredFilters?.length && (
          <MenuItem disabled className={classes.disabledMenuItem}>
            Most Used
          </MenuItem>
        )}
        {!!preferredFilters?.length &&
          preferredFilters.map((f) => {
            if (!fieldParams[f] && !specialFilters[f]) {
              return null;
            }
            return (
              <MenuItem
                key={f}
                button
                disableRipple
                className={clsx(classes.filterMenuItem, {
                  [classes.menuItemSelect]: !!filterOptions[f],
                })}
                onClick={(e) =>
                  handleFilterSelect(
                    e,
                    f,
                    !!filterOptions[f]
                      ? "select"
                      : (specialFilters[f]?.type ?? "write"),
                    !!specialFilters[f],
                  )
                }
              >
                <ListItemText
                  primary={fieldParams[f]?.name ?? specialFilters[f]?.name}
                />
                {!!filterOptions[f] && (
                  <ListItemIcon className={classes.listItemIcon}>
                    <RightArrowIcon className={classes.arrowIcon} />
                  </ListItemIcon>
                )}
              </MenuItem>
            );
          })}
        {!showAllOptions && (
          <MenuItem
            button
            disableRipple
            className={classes.seeMoreBtn}
            onClick={() => {
              filterClicked.current = true;
              setShowAllOptions((prev) => true);
            }}
          >
            <ListItemText
              primary={"See All Filters"}
              className="-label-container"
              primaryTypographyProps={{className: "-label", align: "center"}}
            />
          </MenuItem>
        )}
        {!!preferredFilters?.length && !!showAllOptions && (
          <MenuItem
            disabled
            className={clsx(classes.disabledMenuItem, "-section")}
          >
            {"All Filters"}
          </MenuItem>
        )}
        {!preferredFilters && (
          <MenuItem disabled className={classes.disabledMenuItem}>
            {"Filters"}
          </MenuItem>
        )}
        {!!showAllOptions &&
          filterList.map((f) => {
            if (!fieldParams[f] && !specialFilters[f]) {
              return null;
            }
            return (
              <MenuItem
                key={f}
                button
                disableRipple
                className={clsx(classes.filterMenuItem, {
                  [classes.menuItemSelect]: !!filterOptions[f],
                })}
                onClick={(e) =>
                  handleFilterSelect(
                    e,
                    f,
                    !!filterOptions[f]
                      ? "select"
                      : (specialFilters[f]?.type ?? "write"),
                    !!specialFilters[f],
                  )
                }
              >
                <ListItemText
                  primary={fieldParams[f]?.name ?? specialFilters[f]?.name}
                  primaryTypographyProps={{
                    className: clsx({
                      [classes.menuItemText]: !!filterOptions[f],
                    }),
                  }}
                />
                {!!filterOptions[f] && (
                  <ListItemIcon className={classes.listItemIcon}>
                    <RightArrowIcon className={classes.arrowIcon} />
                  </ListItemIcon>
                )}
              </MenuItem>
            );
          })}
      </MenuList>
    );
  }

  function getOptionsContent() {
    if (
      selectedFilter === "guest_listing" ||
      selectedFilter === "booking_listing"
    ) {
      return (
        <div className={classes.listingListContainer}>
          <MenuItem disabled className={classes.disabledMenuItem}>
            {fieldParams[selectedFilter].name}
          </MenuItem>
          <ListingList
            hideTitle
            disableSearch={false}
            itemSize={40}
            customActions={false}
            customItem={getListingItem}
          />
        </div>
      );
    } else if (filterOptions[selectedFilter] === "date") {
      return (
        <div
          className={clsx(
            calendarStyles.calendar,
            calendarStyles.menuContainer,
          )}
        >
          <MenuItem disabled className={classes.disabledMenuItem}>
            {fieldParams[selectedFilter]?.name ??
              specialFilters[selectedFilter]?.name}{" "}
            {"(In your timezone)"}
          </MenuItem>
          <DatePickerCalendar
            date={null}
            onDateChange={handleDateSelect}
            locale={enCA}
          />
        </div>
      );
    } else if (filterOptions[selectedFilter] === "range") {
      return (
        <div className={calendarStyles.menuContainer}>
          <MenuItem disabled className={classes.disabledMenuItem}>
            {fieldParams[selectedFilter]?.name ??
              specialFilters[selectedFilter]?.name}{" "}
            {"(In your timezone)"}
          </MenuItem>
          <div className={clsx(calendarStyles.calendar, "subcalendar")}>
            <DateRangePickerCalendar
              startDate={rangeDates.start}
              endDate={rangeDates.end}
              focus={calendarFocus}
              touchDragEnabled={false}
              onStartDateChange={(newDate) => handleRangeSelect(newDate)}
              onEndDateChange={(newDate) => handleRangeSelect(newDate, true)}
              locale={enCA}
            />
          </div>
        </div>
      );
    } else if (filterOptions[selectedFilter] === "language") {
      return (
        <div className={classes.languageListContainer}>
          <MenuList id="language-menu">
            <MenuItem disabled className={classes.disabledMenuItem}>
              {fieldParams[selectedFilter].name}
            </MenuItem>
            <MenuItem
              className={classes.search}
              disableRipple
              onKeyDown={(e) => e.stopPropagation()}
            >
              <SearchIcon className={classes.searchInputIcon} />
              <input
                className={classes.searchInput}
                autoFocus
                value={langSearch}
                type="search"
                placeholder="search"
                autoComplete="off"
                onChange={(e) => setLangSearch(e.target.value)}
              />
            </MenuItem>
            {!!langSearch && !availableLangs.length && (
              <MenuItem className={classes.noResultsMessage} disabled>
                {"No results to show"}
              </MenuItem>
            )}
            {_.map(availableLangs, (lang) => (
              <MenuItem
                key={lang}
                disableRipple
                className={classes.languageMenuItem}
                onClick={() => handleOptionSelect(lang)}
              >
                {languages[lang]}
              </MenuItem>
            ))}
          </MenuList>
        </div>
      );
    } else if (selectedFilter === "guest_group_id") {
      return (
        <div className={classes.optionListContainer}>
          <MenuList id="filters-menu">
            <MenuItem
              disabled
              className={clsx(classes.disabledMenuItem, "-pb")}
            >
              {fieldParams[selectedFilter].name}
            </MenuItem>
            <></>
            {filterOptions[selectedFilter]?.map((opt, i) => (
              <OptionItem
                key={`f-${selectedFilter}-${i}`}
                data={opt.name}
                filter={selectedFilter}
                customFieldOptions={customFieldOptions}
                onClick={() => handleOptionSelect(opt.group_id)}
              />
            ))}
          </MenuList>
        </div>
      );
    } else if (filtersWithSearchbar.includes(selectedFilter)) {
      let newOptions = (filterOptions[selectedFilter] || []).filter((opt) =>
        opt.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()),
      );
      return (
        <div className={classes.optionListContainer}>
          <MenuList id="filters-menu">
            <MenuItem
              disabled
              className={clsx(classes.disabledMenuItem, "-pb")}
            >
              {fieldParams[selectedFilter]?.name ??
                specialFilters[selectedFilter]?.name ??
                selectedFilter}
            </MenuItem>
            <SearchBar
              disableGutters
              stopPropagation
              searchInput={searchText}
              handleSearchInput={(val) => setSearchText((prev) => val)}
            />
            {!newOptions.length && <MenuItem disabled>{"No results"}</MenuItem>}
            {newOptions.map((opt, i) => (
              <OptionItem
                key={`f-${selectedFilter}-${i}`}
                data={opt}
                filter={selectedFilter}
                customFieldOptions={customFieldOptions}
                onClick={() => handleOptionSelect(opt)}
              />
            ))}
          </MenuList>
        </div>
      );
    } else {
      return (
        <div className={classes.optionListContainer}>
          <MenuList id="filters-menu">
            <MenuItem
              disabled
              className={clsx(classes.disabledMenuItem, "-pb")}
            >
              {fieldParams[selectedFilter]?.name ??
                specialFilters[selectedFilter]?.name ??
                selectedFilter}
            </MenuItem>
            {filterOptions[selectedFilter]?.map((opt, i) => (
              <OptionItem
                key={`f-${selectedFilter}-${i}`}
                data={opt}
                filter={selectedFilter}
                customFieldOptions={customFieldOptions}
                onClick={() => handleOptionSelect(opt)}
              />
            ))}
          </MenuList>
        </div>
      );
    }
  }

  if (!filterList.length) return null;
  return (
    <>
      <CustomMenu
        overflowAuto
        zIndex={isInsideModal ? 1300 : 3}
        hideScrollbar
        maxHeight={510}
        placement="right-start"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          filterClicked.current = false;
          handleFiltersClose(false);
        }}
        style={filterMenuStyle}
        extraClass={extraFilterMenuClass}
        timeout={100}
        content={getFiltersContent()}
        {...filtersMenuProps}
      />
      <CustomMenu
        overflowAuto
        zIndex={isInsideModal ? 1301 : 4}
        maxHeight={510}
        hideScrollbar
        extraClass={extraOptionMenuClass}
        modifiers={{
          flip: {enabled: true},
          preventOverflow: {
            enabled: true,
            boundariesElement: isMobile ? "scrollParent" : "viewport",
          },
        }}
        open={Boolean(optionsAnchorEl)}
        anchorEl={optionsAnchorEl}
        placement="right-start"
        timeout={100}
        onClose={handleOptionsClose}
        content={getOptionsContent()}
        {...mobileOptionsMenuProps}
      />
    </>
  );
}
