import React from "react";
import {Checkbox} from "@material-ui/core";
import DateComponent from "components/TextFields/Date";
import Stay from "components/TextFields/Stay";
import Tags from "components/TextFields/Tags";
import GuestDot from "components/Misc/GuestDot";
import Policy from "components/TextFields/Policy";
import Status from "components/TextFields/Status";
import Rating from "components/TextFields/Rating";
import MsgTags from "components/TextFields/MsgTags";
import Country from "components/TextFields/Country";
import Currency from "components/TextFields/Currency";
import Channels from "components/TextFields/Channels";
import Assignee from "components/TextFields/Assignee";
import Month from "components/TextFields/Month";
import ElapsedTime from "components/TextFields/ElapsedTime";
import NumberField from "components/TextFields/NumberField";
import SimpleField from "components/TextFields/SimpleField";
import ViewProfile from "components/TextFields/ViewProfile";
import BooleanField from "components/TextFields/BooleanField";
import TravelReason from "components/TextFields/TravelReason";
import Communication from "components/TextFields/Communication";
import NumberBookings from "components/TextFields/NumberBookings";
import GuestDetailDropdown from "components/TextFields/GuestDetailDropdown";
import BookingPaymentStatus from "components/TextFields/BookingPaymentStatus";
import ProductTriggerSelector from "components/MultiOption/ProductTriggerSelector";
import {PropertyTypesEnum, collectFieldTypeEnum} from "configuration/enums";
import {fieldParams} from "configuration/specs";
import {countries, notificationTypeLabels} from "configuration/constants";

export default function CustomCellElement({
  data,
  obj,
  field,
  selectedValues,
  rowIndex,
  onCheck,
  viewProfile,
  value,
  edit,
  onValueChange,
  defaultType,
  customClass,
  customFieldData,
  enableTagSearch,
  simpleBkgDate,
}) {
  const otherProps = !!edit ? {edit, value, onValueChange, defaultType} : {};

  const getBookingDate = (ischeckout) => {
    if (ischeckout) {
      if (!!obj?.checkout_date && typeof obj.checkout_date === "number") {
        let objDate = new Date(obj.checkout_date).toDateString();
        let objTime = obj.checkout_time;
        return new Date(`${objDate} ${objTime}`);
      } else if (!!obj?.checkout_date) {
        return new Date(obj.checkout_date);
      } else {
        return null;
      }
    } else {
      if (!!obj?.checkin_date && typeof obj.checkin_date === "number") {
        let objDate = new Date(obj.checkin_date).toDateString();
        let objTime = obj.checkin_time;
        return new Date(`${objDate} ${objTime}`);
      } else if (!!obj?.checkin_date) {
        return new Date(obj.checkin_date);
      } else {
        return null;
      }
    }
  };

  switch (field) {
    case "month":
      return <Month {...otherProps} />;
    case "booking_purchased_upsells":
      return (
        <ProductTriggerSelector
          type={"upsells"}
          value={{key: data, label: "Select an upsell"}}
          label={"Select an upsell type"}
          onChange={(e) => onValueChange(e.key)}
        />
      );
    case "booking_purchased_fees":
      return (
        <ProductTriggerSelector
          type={"fees"}
          value={{key: data, label: "Select a fee"}}
          label={"Select a fee type"}
          onChange={(e) => onValueChange(e.key)}
        />
      );
    case "rating":
      return <Rating value={data} {...otherProps} />;
    case "listing_tags":
      return (
        <Tags
          tags={data}
          enableSearch={enableTagSearch}
          customFieldData={customFieldData}
          {...otherProps}
        />
      );
    case "total_rev":
      return <Currency value={data} {...otherProps} />;
    case "pet_policy":
    case "smoking_policy":
      return <Policy value={data} {...otherProps} />;
    case "guest_tags":
      return (
        <Tags
          tags={data}
          enableSearch={enableTagSearch}
          type="guest"
          customFieldData={customFieldData}
          {...otherProps}
        />
      );
    case "booking_tags":
      return (
        <Tags
          tags={data}
          enableSearch={enableTagSearch}
          type="booking"
          {...otherProps}
        />
      );
    case "unanswered_messages":
      const labels = {checked: "Answered", unchecked: "Unanswered"};
      return <BooleanField value={data} labels={labels} {...otherProps} />;
    case "question_type":
    case "sentiment":
      return <MsgTags msg={obj} tag={field} {...otherProps} />;
    case "guest_channel":
    case "listing_channels":
      return <Channels channels={data} {...otherProps} />;
    case "booking_channel":
      return (
        <Channels
          channels={[data]}
          customFieldData={customFieldData}
          {...otherProps}
        />
      );
    case "booking_payment_status":
      return <BookingPaymentStatus {...otherProps} />;
    case "name":
    case "guest_name":
    case "listing_name":
      return <SimpleField value={data} bold {...otherProps} />;
    case "sent_at":
      return <DateComponent date={parseInt(data)} uppercase {...otherProps} />;
    case "guest_travel_reason":
      return <TravelReason value={data} {...otherProps} />;
    case "guest_stage":
      return <Status status={data} type="guest" {...otherProps} />;
    case "assigned_to":
      return <Assignee assignee={obj?.assigned_to} {...otherProps} />;
    case "viewed":
      return <SimpleField value={!!data ? "Yes" : "No"} {...otherProps} />;
    case "guest_status":
    case "booking_status":
      return (
        <Status xPadd={!edit} status={data} type="booking" {...otherProps} />
      );
    case "message_channel":
      return <SimpleField value={data} capitalize {...otherProps} />;
    case "listing_type":
      return <SimpleField value={PropertyTypesEnum[data]} {...otherProps} />;
    case "booking_listing":
    case "guest_listing":
      return <SimpleField value={obj?.listings?.length || 0} {...otherProps} />;
    case "num_adult":
      return (
        <Stay adults={obj?.num_adult} childs={obj?.num_child} {...otherProps} />
      );
    case "guest_destination":
      return <SimpleField value={obj?.destinations || "-"} {...otherProps} />;
    case "guest_phone":
    case "guest_email":
      return (
        <SimpleField value={data} useTooltip linesToShow={2} {...otherProps} />
      );
    case "notification_type":
      return (
        <SimpleField value={notificationTypeLabels[data]} {...otherProps} />
      );
    case "listing_active":
      return (
        <Status
          status={!!data ? "active" : "inactive"}
          type="listing"
          {...otherProps}
        />
      );
    case "num_child":
      return (
        <Stay
          adults={obj?.num_adult}
          childs={obj?.num_child}
          showEmptyChilds
          {...otherProps}
        />
      );
    case "country":
    case "guest_country":
      return <Country value={countries[data]} {...otherProps} />;
    case "guest_bookings":
      return (
        <NumberBookings
          bookings={obj?.bookings}
          inquiries={obj?.inquiries}
          {...otherProps}
        />
      );
    case "num_messages":
      return (
        <Communication
          numMessages={obj?.num_messages}
          responseTime={obj?.response_time}
          {...otherProps}
        />
      );
    case "checkin_raw":
    case "checkin_time":
    case "checkin_date":
      return (
        <DateComponent
          date={getBookingDate()}
          simpleDate={simpleBkgDate}
          showInfoText
          time={field === "checkin_time"}
          {...otherProps}
        />
      );
    case "guest_inquiry":
      return (
        <NumberBookings
          bookings={obj?.bookings}
          inquiries={obj?.inquiries}
          showEmptyInquiries
          {...otherProps}
        />
      );
    case "checkout_raw":
    case "checkout_time":
    case "checkout_date":
      return (
        <DateComponent
          date={getBookingDate(true)}
          simpleDate={simpleBkgDate}
          showInfoText
          time={field === "checkout_time"}
          {...otherProps}
        />
      );
    case "payment":
      return (
        <Currency
          value={obj?.booking_payment?.total_guest_paid}
          currency={obj?.booking_payment?.currency}
          {...otherProps}
        />
      );
    case "response_time":
      return (
        <Communication
          numMessages={obj?.num_messages}
          responseTime={obj?.response_time}
          showEmptyResponseTime
          {...otherProps}
        />
      );
    // Action columns
    case "view_profile":
      return <ViewProfile onClick={() => viewProfile(rowIndex)} />;
    case "checkbox":
      return (
        <Checkbox
          size="small"
          color="secondary"
          className={customClass}
          checked={selectedValues.includes(data)}
          onChange={(e) => {
            onCheck(data);
          }}
          style={{width: "fit-content"}}
        />
      );
    case "dot":
      return <GuestDot isUnread={!!obj?.unread_messages} />;
    default:
      let field_info = null;
      if (!!fieldParams[field]) {
        field_info = fieldParams[field];
      } else if (!!customFieldData) {
        for (
          var i = 0, len = Object.keys(customFieldData)?.length;
          i <= len;
          i++
        ) {
          field_info = customFieldData[Object.keys(customFieldData)[i]]?.find(
            (f) => f.field === field,
          );
          if (!!field_info) {
            break;
          }
        }
      } else {
        return <SimpleField value={data} {...otherProps} />;
      }
      switch (field_info?.field_type || field_info?.type) {
        case collectFieldTypeEnum.single_select:
        case collectFieldTypeEnum.multi_select:
          return (
            <GuestDetailDropdown options={field_info.data} {...otherProps} />
          );
        case collectFieldTypeEnum.time:
          return (
            <DateComponent
              date={new Date()}
              showInfoText
              time
              {...otherProps}
            />
          );
        case collectFieldTypeEnum.date:
          return (
            <DateComponent date={new Date()} showInfoText {...otherProps} />
          );
        case collectFieldTypeEnum.number:
          return <NumberField {...otherProps} />;
        case collectFieldTypeEnum.time_offset:
          return <ElapsedTime {...otherProps} />;
        default:
          return <SimpleField value={data} {...otherProps} />;
      }
  }
}
