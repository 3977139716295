import React from "react";
import {useSelector, useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {Storage} from "aws-amplify";
import {useHistory} from "react-router-dom";
// UI
import {
  Avatar,
  Box,
  Divider,
  FormHelperText,
  List,
  MenuItem,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import {ReactComponent as DescriptionIcon} from "assets/icons/description.svg";
import {makeStyles} from "@material-ui/core/styles";
// Custom
import Conditions from "components/Panels/Experience/ExperienceContent/Conditions/Conditions";
import DraggableLayout from "components/Grids/DraggableLayout";
import ImageTextField from "components/TextFields/ImageTextField";
import FilledTextField from "core/inputs/FilledTextField";
import DraggableItem from "core/listItems/DraggableItem";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import FilledSelect from "core/selects/FilledSelect";
import CustomDialog from "core/dialogs/CustomDialog";
import {EmptyCheckinSteps} from "components/Helpers/EmptyPanels";
import CreationButton from "components/MultiOption/CreationButton";
import EmptyContentText from "components/Misc/EmptyContentText";
import ConnectedToButton from "core/buttons/ConnectedToButton";
import CreateFromExistingList from "./CreateFromExistingList";
import PreviewBPPanel from "components/Panels/PreviewBPPanel";
import PrimaryButton from "core/buttons/PrimaryButton";
import InfoText from "components/Misc/InfoText";
// Actions
import {
  deleteListingResource,
  editHouse,
  saveListingResource,
  updateListingResource,
  updateListingResourceOrder,
} from "redux/actions/listingsActions";
// Utilities
import {getConnectionLabel} from "utilities/formatUtilities";
import {contentTypeEnum} from "configuration/enums";
import {languages} from "configuration/constants";
import {newUuid} from "utilities/helperFunctions";
import {THEME} from "configuration/settings";
import config from "configuration/settings.js";
import infoTexts from "assets/infoTexts";
import clsx from "clsx";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  field: {
    color: "#000",
    whiteSpace: "break-spaces",
  },
  fieldLabel: {
    color: THEME.grey,
    "&.breakSpaces": {whiteSpace: "break-spaces"},
  },
  none: {color: "rgba(0,0,0,0.25)"},
  itemTitle: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "fit-content",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-all",
    maxWidth: "70%",
    WebkitLineClamp: 1,
  },
  itemSelected: {fontWeight: 700},
  itemDisabled: {color: "rgba(60, 60, 67, 0.6)"},
  title: {margin: theme.spacing(4, 0, 1)},
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  content: {flexGrow: 1},
  avatar: {
    backgroundColor: "#D9D9D9",
    borderRadius: 5,
    width: 200,
    maxHeight: 225,
    height: "auto",
    alignSelf: "stretch",
    marginRight: theme.spacing(4),
    "&.-sm": {maxHeight: 160},
  },
  imageIcon: {
    width: "100%",
    height: "100%",
    color: "#f2f2f2",
  },
  selected: {fontWeight: 700},
  radio: {paddingRight: 7},
  radioGroup: {
    display: "flex",
    flexDirection: "row",
    "& > label": {marginBottom: 0},
  },
  radioLabel: {
    color: "rgba(0,0,0,0.6)",
    fontSize: theme.typography.button.fontSize,
    fontWeight: theme.typography.button.fontWeight,
    lineHeight: theme.typography.button.lineHeight,
    marginRight: theme.spacing(5),
    display: "flex",
    alignItems: "center",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingRight: theme.spacing(2),
  },
  connectedText: {
    fontSize: 12,
    fontWeight: 400,
    marginLeft: theme.spacing(1),
    color: theme.palette.text.disabled,
    flex: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-all",
    lineClamp: 1,
  },
  subheaderRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(4, 0),
    backgroundColor: "#F0F0F0",
  },
  inputsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    "& .expand-btn": {margin: theme.spacing(3, 0, 0)},
  },
  sectionTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(2),
    padding: theme.spacing(1, 0),
    marginBottom: theme.spacing(3),
    "& > .icon": {
      width: 16,
      height: 16,
    },
  },
  caption: {
    color: THEME.subdued,
    marginTop: -theme.spacing(2),
  },
  alternativeBtn: {
    fontSize: 12,
    lineHeight: "15px",
    padding: theme.spacing(0, 2),
    fontWeight: 400,
    minWidth: 0,
  },
  deviceLinkForm: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(3),
    marginTop: theme.spacing(4),
  },
}));

export default function CheckinStepList({
  listing,
  lang,
  newListingId,
  edit,
  data,
  isCheckout = false,
  onChange,
  isGroupView,
  listingGroupID,
  loading = false,
  updateGJE = () => {},
  setIsEditing = () => {},
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const history = useHistory();
  const isNewStep = React.useRef(false);
  const stepToUpdate = React.useRef(null);
  const stepToDelete = React.useRef(null);
  const prevStepsOrder = React.useRef(null);
  const prevSteps = React.useRef([]);
  const existingStepDescription = React.useRef(null);
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const houses = useSelector((state) => state.defaultReducer.house_data_dict);
  const listingGroups = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );
  const loadingResource = useSelector(
    (state) => state.defaultReducer.loading,
  ).listing_resource_action;
  //State
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [uploadedImage, setUploadedImage] = React.useState(null);
  const [modalContentType, setModalContentType] = React.useState(null);
  const [selectedStep, setSelectedStep] = React.useState({
    index: null,
    properties: {
      picture: "",
      picture_url: "",
      video: "",
      conditions: [],
      description: "",
      device_id: "",
      link_label: "",
      link_url: "",
    },
  });
  const [isContentValid, setIsContentValid] = React.useState(true);
  const [deviceFieldDisabled, setDeviceFieldDisabled] = React.useState(false);
  const [assetType, setAssetType] = React.useState("picture");
  const [deviceLinkModalOpen, setDeviceLinkModalOpen] = React.useState(false);
  const [deviceLinkTempData, setDeviceLinkTempData] = React.useState({
    label: "",
    link: "",
  });
  // General
  const steps = React.useMemo(() => {
    return data?.map((s) => (!!s.step_id ? s : {...s, step_id: newUuid()}));
  }, [data]);
  const AIListingData =
    listing ?? houses[listingGroups[listingGroupID]?.connected_to?.ids?.[0]];
  const descriptions = React.useMemo(() => {
    /*
     * {
     *  0: { 'en': 'step 1' },
     *  1: { 'en': 'step 2', 'fr': 'étape 2' },
     *  ...
     * }
     */
    const newDescs = {};
    _.each(steps, (s) => {
      newDescs[s.index] = {};
      _.each(s.properties.description || [], (desc) => {
        newDescs[s.index][desc.language] = desc.value;
      });
    });
    return newDescs;
  }, [steps]);
  const stepKeys = React.useMemo(() => steps.map((s) => s.step_id), [steps]);
  const hasDevices = !!listing?.listing_devices?.length;
  const stepDeviceName = hasDevices
    ? listing.listing_devices.find(
        (d) =>
          d.device_id === steps[selectedStep.index ?? 0]?.properties?.device_id,
      )?.name
    : null;
  let showDeviceLinkEdit =
    !(selectedStep.properties?.device_id || "") &&
    (!!deviceLinkTempData.label || !!deviceLinkTempData.link);
  let allowAddDeviceRedirection = !listing?.listing_devices?.length;

  React.useEffect(() => {
    if (selectedStep.index !== null) {
      setUploadedImage((prev) => steps[selectedStep.index]?.properties.picture);
    }
  }, [selectedStep.index]);

  React.useEffect(() => {
    if (["all", "group"].includes(selectedStep.connection_type)) {
      setDeviceFieldDisabled((prev) => true);
    } else {
      setDeviceFieldDisabled((prev) => false);
    }
  }, [selectedStep]);

  React.useEffect(() => {
    setIsEditing(
      selectedStep.index !== null || modalContentType === "existing_new",
    );
  }, [selectedStep.index, modalContentType]);

  const getUpdatedListing = (step, type) => {
    const newAccessMethod = {...listing?.listing_content?.access_method};
    switch (type) {
      case "add":
        if (!!isCheckout) {
          newAccessMethod.checkout_steps = [...steps, step];
        } else {
          newAccessMethod.steps = [...steps, step];
        }
        break;
      case "update":
        if (!!isCheckout) {
          newAccessMethod.checkout_steps = steps.map((s) =>
            s.id === step.id ? step : s,
          );
        } else {
          newAccessMethod.steps = steps.map((s) =>
            s.id === step.id ? step : s,
          );
        }
        break;
      case "reorder":
        if (!!isCheckout) {
          newAccessMethod.checkout_steps = step;
        } else {
          newAccessMethod.steps = step;
        }
        break;
      case "delete":
        if (!!isCheckout) {
          newAccessMethod.checkout_steps = steps.filter(
            (s) => s.id !== step.id,
          );
        } else {
          newAccessMethod.steps = steps.filter((s) => s.id !== step.id);
        }
        break;
    }

    return {
      ...listing,
      listing_content: {
        ...listing.listing_content,
        access_method: newAccessMethod,
      },
    };
  };

  const deleteStep = (step) => {
    const newListing = getUpdatedListing(step, "delete");
    if (isGroupView) {
      const newSteps = steps.filter((s) => s.resource_id !== step.resource_id);
      const updateAllListings = ["all", "group"].includes(step.connection_type);
      if (updateAllListings) {
        onChange(newSteps, {
          updateAllListings: true,
          step: stepToDelete.current.step,
          action: "delete",
        });
      } else {
        onChange(newSteps);
      }
    }
    dispatch(
      deleteListingResource({
        resource: step.resource,
        resource_id: step.resource_id,
        type: "step",
        listing: newListing,
        onSuccess: () => {
          updateGJE();
        },
      }),
    );
  };

  const closeDeleteModal = () => {
    stepToDelete.current = null;
    setDeleteModalOpen((prev) => false);
  };

  const getAssetTypeLabel = () => {
    switch (assetType) {
      case "picture":
        return "Photo";
      case "picture_url":
        return "Photo URL";
      case "video":
        return "Video";

      default:
        return "";
    }
  };

  const handleAssetChange = (link) => {
    setSelectedStep((prev) => ({
      ...prev,
      properties: {...prev.properties, [assetType]: link},
    }));
    if (assetType === "picture") {
      setUploadedImage((prev) => link);
    }
  };

  const handleInstructionsChange = (e) => {
    const val = e.target.value;
    setSelectedStep((prev) => ({
      ...prev,
      properties: {...prev.properties, description: val},
    }));
  };

  const handleContentValidity = (value) => {
    setIsContentValid((prev) => value);
  };

  const handleConditionsChange = (c) => {
    setSelectedStep((prev) => ({
      ...prev,
      properties: {...prev.properties, conditions: c},
    }));
  };

  const handleDeviceIdChange = (e) => {
    const val = e.target.value;
    setSelectedStep((prev) => ({
      ...prev,
      properties: {...prev.properties, device_id: val},
    }));
  };

  const handleDeviceLinkDataChange = (field) => (e) => {
    const val = e.target.value;
    setDeviceLinkTempData((prev) => ({...prev, [field]: val}));
  };

  const handleOpenStep = (index) => () => {
    existingStepDescription.current = null;
    let currentStep = steps[index];
    setSelectedStep((prev) => ({
      ...currentStep,
      index: index,
      properties: {
        ...currentStep.properties,
        picture_url: "",
        description: descriptions[index][lang] || "",
      },
    }));
    setDeviceLinkTempData((prev) => ({
      label: currentStep.properties.link_label || "",
      link: currentStep.properties.link_url || "",
    }));
    if (!!currentStep.properties.video) {
      setAssetType((prev) => "video");
    } else {
      setAssetType((prev) => "picture");
    }
    setModalContentType((prev) => "existing");
  };

  const handleAddStep = () => {
    existingStepDescription.current = null;
    isNewStep.current = true;
    let new_step = {
      index: `${steps.length}`,
      resource: isCheckout ? "co" : "ci",
      connected_ids: {listing: [listing?.id]},
      group_ids: [],
      connection_type: "ids",
      connected_object: "listing",
      properties: {
        picture: "",
        picture_url: "",
        video: "",
        description: "",
        device_id: "",
        conditions: [],
        link_label: "",
        link_url: "",
      },
    };
    setModalContentType((prev) => "scratch");
    setAssetType((prev) => "picture");
    setSelectedStep((prev) => new_step);
  };

  const handleRemoveStep = (index) => () => {
    existingStepDescription.current = null;
    stepToDelete.current = {
      step: {
        ...steps[index],
        description: steps[index].description,
      },
      index,
      saveOption: "listing",
    };
    setDeleteModalOpen((prev) => true);
  };

  const confirmRemoveStep = () => {
    const index = stepToDelete.current.step.index;
    stepToDelete.current = {
      ...stepToDelete.current,
      saveOption: "listing",
    };
    let newSteps = steps.filter((s) => s.index !== index);
    newSteps = newSteps.map((s, i) => ({...s, index: String(i)}));
    const updateAllListings = ["all", "group"].includes(
      stepToDelete.current.connection_type,
    );
    if (updateAllListings) {
      onChange(newSteps, {
        updateAllListings: true,
        step: stepToDelete.current.step,
        action: "delete",
      });
    } else {
      onChange(newSteps);
    }
    const step = stepToDelete.current.step;
    deleteStep(step);
    closeDeleteModal();
  };

  const handleCloseDeviceLinkModal = () => {
    setDeviceLinkModalOpen((prev) => false);
  };

  const handleSaveDeviceLinkData = () => {
    setSelectedStep((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        device_id: "",
        link_label: deviceLinkTempData.label,
        link_url: deviceLinkTempData.link,
      },
    }));
    handleCloseDeviceLinkModal();
  };

  const openDeviceLinkModal = () => {
    setDeviceLinkTempData((prev) => ({
      label: selectedStep.properties.link_label,
      link: selectedStep.properties.link_url,
    }));
    setDeviceLinkModalOpen((prev) => true);
  };

  const handleCloseModal = () => {
    isNewStep.current = false;
    setSelectedStep((prev) => ({
      index: null,
      properties: {
        picture: "",
        picture_url: "",
        video: "",
        conditions: [],
        description: "",
        device_id: "",
        link_label: "",
        link_url: "",
      },
    }));
    setDeviceLinkTempData((prev) => ({label: "", link: ""}));
    setAssetType((prev) => "picture");
    setModalContentType((prev) => null);
    handleCloseDeviceLinkModal();
  };

  const removeUnusedImg = () => {
    let originalImg = steps[selectedStep.index]?.properties.picture;
    if (!!originalImg && originalImg !== uploadedImage) {
      // Storage.remove(originalImg.substring(config.s3.URL.length))
    }
  };

  function handleUpdateStep(index, updatedStep) {
    let newSteps = [];
    if (index === null) {
      newSteps = [...steps, updatedStep];
    } else {
      newSteps = [...steps];
      newSteps[index] = updatedStep;
    }

    onChange(newSteps);
  }

  function getUpdatedContent() {
    const index = selectedStep.index;
    let newDesc = [];
    let assetFields = {picture: "", video: ""};
    if (!isNewStep.current) {
      newDesc = steps[index].properties.description.map((d) => {
        if (d.language === lang) {
          return {
            language: d.language,
            value: selectedStep.properties.description,
          };
        } else {
          return d;
        }
      });
    } else if (!!existingStepDescription.current) {
      newDesc = existingStepDescription.current.map((d) => {
        if (d.language === lang) {
          return {
            language: d.language,
            value: selectedStep.properties.description,
          };
        } else {
          return d;
        }
      });
    }
    if (
      !steps[index]?.properties?.description?.find(
        (d) => d.language === lang,
      ) &&
      !existingStepDescription.current
    ) {
      newDesc.push({
        language: lang,
        value: selectedStep.properties.description,
      });
    }

    switch (assetType) {
      case "picture":
        assetFields.picture = selectedStep.properties.picture;
        break;
      case "picture_url":
        assetFields.picture = selectedStep.properties.picture_url;
        break;
      case "video":
        assetFields.video = selectedStep.properties.video;
        break;

      default:
        break;
    }

    return {
      ...selectedStep,
      properties: {
        ...selectedStep.properties,
        ...assetFields,
        description: newDesc,
      },
    };
  }

  const handleSaveStep = () => {
    const index = selectedStep.index;
    const updatedStep = getUpdatedContent();
    // if (!!uploadedImage) { removeUnusedImg() }

    if (
      ["all", "group"].includes(updatedStep.connection_type) &&
      !!updatedStep.properties.device_id
    ) {
      updatedStep.properties.device_id = "";
    }

    const newListing = getUpdatedListing(
      updatedStep,
      isNewStep.current ? "add" : "update",
    );
    const updateAllListings = ["all", "group"].includes(
      updatedStep.connection_type,
    );
    prevSteps.current = [...steps];
    if (isNewStep.current) {
      let newSteps = [...steps, {...updatedStep, index: String(steps.length)}];
      if (updateAllListings) {
        onChange(newSteps, {
          updateAllListings: true,
          step: newSteps.slice(-1),
          action: "add",
        });
      } else {
        onChange(newSteps);
      }
      dispatch(
        saveListingResource({
          body: updatedStep,
          type: "step",
          listing: newListing,
          onSuccess: (r) => {
            handleUpdateStep(null, {...r, index: String(selectedStep.index)});
            updateGJE();
          },
          onError: () => {
            onChange(prevSteps.current);
          },
        }),
      );
    } else {
      let newSteps = [...steps];
      newSteps[index] = {...updatedStep, index: String(selectedStep.index)};
      if (updateAllListings) {
        onChange(newSteps, {
          updateAllListings: true,
          step: newSteps[index],
          action: "update",
        });
      } else {
        onChange(newSteps);
      }
      if (isGroupView) {
        handleUpdateStep(index, {
          ...updatedStep,
          index: String(selectedStep.index),
        });
      }
      dispatch(
        updateListingResource({
          body: updatedStep,
          listing: newListing,
          onSuccess: (r) => {
            handleUpdateStep(index, {...r, index: String(selectedStep.index)});
            updateGJE();
          },
          onError: () => {
            onChange(prevSteps.current);
          },
        }),
      );
    }
    handleCloseModal();
  };

  const handleStepsReordering = (newSteps) => {
    console.log("GOT NEW STEPS", newSteps);
    if (selectedStep.index !== null) {
      return;
    }
    const prevOrder = steps.map((s, i) => ({index: s.index, order: i}));
    const newOrder = newSteps.map((s, i) => ({index: s.index, order: i}));
    prevStepsOrder.current = steps;
    if (!_.isEqual(prevOrder, newOrder)) {
      confirmReordering(newSteps);
    }
  };

  const confirmReordering = (newSteps) => {
    existingStepDescription.current = null;
    let new_index = newSteps.findIndex(
      (s) => s.resource_id === stepToUpdate.current.resource_id,
    );
    const newListing = getUpdatedListing(newSteps, "reorder");
    const newStep = {...newSteps[new_index], index: new_index};
    onChange(newSteps.map((s, i) => ({...s, index: String(i)})));
    dispatch(
      updateListingResourceOrder({
        body: {
          resource_type: newStep.resource,
          resource_id: newStep.resource_id,
          index: new_index,
          listing_id: newListing.listing_id,
        },
        newListing,
        onSuccess: () => {
          prevStepsOrder.current = null;
          stepToUpdate.current = null;
        },
        onError: cancelReordering,
      }),
    );
  };

  const cancelReordering = () => {
    onChange(prevStepsOrder.current.map((s, i) => ({...s, index: String(i)})));
    const prevListing = getUpdatedListing(prevStepsOrder.current, "reorder");
    dispatch(editHouse(prevListing));
    stepToUpdate.current = null;
    prevStepsOrder.current = null;
  };

  const renderItem = (s, position) => {
    const i = Number(s.index);
    return (
      <DraggableItem
        key={s.properties.step_id}
        edit={edit}
        disabled={!edit || loadingResource}
        showEmptyPicture
        picture={s.properties.picture}
        title={
          <div className={classes.titleContainer}>
            <span className={classes.itemTitle}>{`Step ${position + 1}`}</span>
            <span className={classes.connectedText}>
              • {getConnectionLabel(s, houses, listingGroups)}
            </span>
          </div>
        }
        text={descriptions[s.index][lang] || ""}
        placeholder={
          !!descriptions[s.index][lang]
            ? null
            : `No ${languages[lang]} instructions`
        }
        onItemClick={
          !!edit
            ? () => {
                stepToUpdate.current = s;
              }
            : handleOpenStep(i)
        }
        onEdit={handleOpenStep(i)}
        onDelete={handleRemoveStep(i)}
      />
    );
  };

  const confirmDeleteModal = !!deleteModalOpen && (
    <ConfirmDialog
      open
      maxWidth="sm"
      disableDismiss
      onClose={closeDeleteModal}
      title={`Delete step ${stepToDelete.current?.index + 1 ?? ""}?`}
      message={<>{"Delete this step from the listings its connected to"}</>}
      confirmLabel="Delete"
      confirmAction={confirmRemoveStep}
      cancelLabel="Cancel"
      cancelAction={closeDeleteModal}
    />
  );

  const pictureField = (
    <ImageTextField
      picture={selectedStep.properties[assetType]}
      available_pictures={listing?.listing_content?.pictures}
      setPicture={handleAssetChange}
      label={`Header asset (${getAssetTypeLabel()})`}
      uploadedImage={uploadedImage}
      savePath={`${current_user}/listing-${listing?.listing_id ?? newListingId}/`}
      assetType={assetType}
      setAssetType={setAssetType}
    />
  );

  const instructionsField = (
    <FilledTextField
      fullWidth
      multiline
      useCustomFields
      id="instructions"
      label="Instructions"
      value={selectedStep.properties.description}
      onChange={handleInstructionsChange}
      AIprops={{
        type: isCheckout
          ? contentTypeEnum.checkout_step
          : contentTypeEnum.checkin_step,
        objects: {listing: AIListingData},
      }}
      onValidityCheck={handleContentValidity}
    />
  );

  const deviceField = (
    <>
      <FilledSelect
        fullWidth
        useConnectButton={!hasDevices}
        onConnectClick={
          allowAddDeviceRedirection
            ? () => {
                history.push("/admin/adddevices");
              }
            : null
        }
        disabled={!hasDevices || deviceFieldDisabled}
        value={selectedStep.properties?.device_id || ""}
        placeholder="Select a device"
        label="Device (Optional)"
        onChange={handleDeviceIdChange}
      >
        <MenuItem value="" className={classes.itemDisabled}>
          {"Select a device"}
        </MenuItem>
        {listing?.listing_devices?.map((d) => (
          <MenuItem
            key={d.device_id}
            value={d.device_id}
            selected={selectedStep.properties.device_id === d.device_id}
            classes={{selected: classes.itemSelected}}
          >
            {d.name}
          </MenuItem>
        ))}
      </FilledSelect>
      <FormHelperText>
        {showDeviceLinkEdit
          ? "You are using an external link."
          : "Devices can only be added to listing-level steps."}
        <PrimaryButton
          variant="text"
          color="secondary"
          className={classes.alternativeBtn}
          label={showDeviceLinkEdit ? "Edit" : "Need an alternative?"}
          onClick={openDeviceLinkModal}
        />
      </FormHelperText>
    </>
  );

  const handleCreateAction = (actionType) => () => {
    setModalContentType((prev) => actionType);
  };

  const selectExistingStep = (s) => {
    isNewStep.current = true;
    existingStepDescription.current = s.properties.description;
    setSelectedStep((prev) => ({
      ...s,
      id: undefined,
      resource_id: undefined,
      index: `${steps.length}`,
      resource: s.resource,
      connected_ids: {listing: [listing?.id]},
      group_ids: [],
      connection_type: "ids",
      connected_object: "listing",
      properties: {
        ...s.properties,
        picture_url: "",
        device_id: !!listing?.listing_devices?.find(
          (d) => d.device_id === s.properties.device_id,
        )
          ? s.properties.device_id
          : null,
        description:
          s.properties.description.find((d) => d.language === lang)?.value ||
          "",
        step_id: undefined,
      },
    }));
    setDeviceLinkTempData((prev) => ({
      label: s.properties.link_label || "",
      link: s.properties.link_url || "",
    }));
    if (!!s.properties.video) {
      setAssetType((prev) => "video");
    } else {
      setAssetType((prev) => "picture");
    }
    setModalContentType((prev) => "existing");
  };

  function getModalContent() {
    switch (modalContentType) {
      case "existing":
      case "scratch":
        return (
          <>
            {!!edit && (
              <ConnectedToButton
                listing={listing}
                isGroupView={isGroupView}
                listing_id={listing?.listing_id}
                group_id={listing?.group_id}
                data={selectedStep}
                showSectionTitle
                type={isCheckout ? "check-out step" : "check-in step"}
                disabledOptions={
                  !!selectedStep.properties.device_id ? ["all", "group"] : []
                }
                editData={setSelectedStep}
                disabled={!!selectedStep.properties.device_id}
              />
            )}
            <Divider className={classes.divider} />
            <Conditions
              standalone
              title="Conditions"
              disableEdit={!edit}
              label="Define what conditions must be met to display this step"
              data={selectedStep.properties.conditions}
              setData={handleConditionsChange}
            />
            <Divider className={classes.divider} />
            <div className={classes.sectionTitle}>
              <SvgIcon
                className="icon"
                viewBox="0 0 16 17"
                component={DescriptionIcon}
              />
              <Typography className="title" variant="h1">
                {"Description"}
              </Typography>
            </div>
            <div className={classes.container}>
              <Avatar
                alt="Step Image"
                src={selectedStep.properties[assetType]}
                className={clsx(classes.avatar, {"-sm": !hasDevices})}
              >
                <ImageIcon className={classes.imageIcon} />
              </Avatar>
              <div className={classes.content}>
                {edit ? (
                  <div className={classes.inputsContainer}>
                    <div>{pictureField}</div>
                    <Typography variant="caption" className={classes.caption}>
                      {t("check_step_asset_field_description")}
                    </Typography>
                    <div>{instructionsField}</div>
                    <div>{deviceField}</div>
                  </div>
                ) : (
                  <div>
                    <Typography className={classes.fieldLabel}>
                      {"Header asset"}
                    </Typography>
                    <Typography
                      className={clsx("mb-4", {
                        [classes.field]: !!selectedStep.properties.picture,
                        [classes.none]: !selectedStep.properties.picture,
                      })}
                    >
                      {selectedStep.properties.picture || "None"}
                    </Typography>
                    <Typography className={classes.fieldLabel}>
                      {"Instructions"}
                    </Typography>
                    <Typography
                      className={clsx("mb-3", {
                        [classes.field]: !!selectedStep.properties.description,
                        [classes.none]: !selectedStep.properties.description,
                      })}
                    >
                      {selectedStep.properties.description || "None"}
                    </Typography>
                    {hasDevices && (
                      <>
                        <Typography className={classes.fieldLabel}>
                          {"Device"}
                        </Typography>
                        <Typography
                          className={clsx("mb-3", {
                            [classes.field]: !!stepDeviceName,
                            [classes.none]: !stepDeviceName,
                          })}
                        >
                          {stepDeviceName || "None"}
                        </Typography>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </>
        );
      case "existing_new":
        return (
          <Box height="100%">
            <CreateFromExistingList
              lang={lang}
              resource={isCheckout ? "checkout_step" : "checkin_step"}
              onSelect={selectExistingStep}
            />
          </Box>
        );

      default:
        return null;
    }
  }

  function getDeviceLinkModalContent() {
    return (
      <>
        <Typography className={clsx(classes.fieldLabel, "breakSpaces")}>
          {t("device_link_modal_content")}
        </Typography>
        <Box className={classes.deviceLinkForm}>
          <Box flex={1}>
            <FilledTextField
              fullWidth
              id="link_label"
              label="Link Label"
              value={deviceLinkTempData.label}
              onChange={handleDeviceLinkDataChange("label")}
            />
          </Box>
          <Box flex={1}>
            <FilledTextField
              fullWidth
              id="link_url"
              label="Link URL"
              value={deviceLinkTempData.link}
              onChange={handleDeviceLinkDataChange("link")}
            />
          </Box>
        </Box>
      </>
    );
  }

  function getPreviewContent() {
    if (selectedStep.index === null) {
      return null;
    }
    return (
      <PreviewBPPanel
        type={isCheckout ? "checkout" : "checkin"}
        content={getUpdatedContent()}
        listingId={
          isGroupView
            ? listingGroups[listingGroupID].connected_to.ids[0]
            : listing?.listing_id
        }
      />
    );
  }

  const stepModal = (
    <CustomDialog
      fullWidth
      maxWidth="lg"
      disableEnforceFocus
      disableExit={!!edit}
      disableActions={!edit}
      customHeight={modalContentType === "existing_new" ? "100%" : null}
      open={selectedStep.index !== null || modalContentType === "existing_new"}
      titleVariant="header"
      transitionDuration={0}
      title={
        modalContentType === "existing_new"
          ? "New step"
          : `Step ${parseInt(selectedStep.index) + 1}`
      }
      onClose={handleCloseModal}
      labelCancel="Cancel"
      actionCancel={handleCloseModal}
      labelConfirm="Save"
      actionConfirm={handleSaveStep}
      disableConfirm={
        !selectedStep?.properties?.description?.trim() ||
        loadingResource ||
        !isContentValid
      }
      content={getModalContent()}
      previewContent={getPreviewContent()}
    />
  );

  const externalLinkModal = (
    <CustomDialog
      fullWidth
      disableExit
      maxWidth="sm"
      disableEnforceFocus
      open={deviceLinkModalOpen}
      titleVariant="header"
      transitionDuration={0}
      title={"Add device link"}
      onClose={handleCloseDeviceLinkModal}
      labelCancel="Cancel"
      actionCancel={handleCloseDeviceLinkModal}
      labelConfirm="Save"
      actionConfirm={handleSaveDeviceLinkData}
      content={getDeviceLinkModalContent()}
    />
  );

  return (
    <div>
      {stepModal}
      {externalLinkModal}
      {confirmDeleteModal}
      <div className={classes.row}>
        <div className={classes.subheaderRow}>
          <Box display="flex" flexDirection="row" alignItems="baseline">
            <Typography variant="h1">
              {isCheckout ? "Checkout information" : "Check-in information"}
            </Typography>
            <InfoText
              text={infoTexts[isCheckout ? "checkoutSteps" : "checkinSteps"]}
            />
          </Box>
          <CreationButton
            type="step"
            disabled={!edit || loadingResource}
            onScratch={handleAddStep}
            onExisting={handleCreateAction("existing_new")}
          />
        </div>
      </div>
      <List component="nav" className={classes.list}>
        {!!loading ? (
          <EmptyCheckinSteps />
        ) : (
          <>
            {!steps.length && (
              <Box mt={2}>
                <EmptyContentText label="No steps" />
              </Box>
            )}
            <DraggableLayout
              autoSize
              fullWidth
              items={steps}
              itemKey="step_id"
              disablePadding
              sort={stepKeys}
              disableDrag={!edit}
              renderItem={renderItem}
              setNewOrder={handleStepsReordering}
            />
          </>
        )}
      </List>
    </div>
  );
}
