import React, {Fragment, useRef, useState} from "react";
import {
  Button,
  Typography,
  Link,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  makeStyles,
} from "@material-ui/core";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import Camera, {FACING_MODES, IMAGE_TYPES} from "react-html5-camera-photo";
import ArrowBackIosNewIcon from "@material-ui/icons/ArrowBackIos";
import VerifyItem from "./VerifyItem";
import "react-html5-camera-photo/build/css/index.css";

const VERIFICATION_LABELS = {
  govt_id_front: "Front of ID",
  govt_id_back: "Back of ID",
  selfie: "Selfie",
};

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
  previewImg: {width: "100%"},
  faceImage: {
    color: theme.palette.primary.light,
  },
  camera_container: {
    border: `3px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    width: "100%",
    height: "100%",
    position: "relative",
    overflow: "hidden",
    "& .react-html5-camera-photo>.display-error": {width: "100%"},
    "& .react-html5-camera-photo>video": {width: "100%"},
    "&.disabled": {
      "& #outer-circle": {pointerEvents: "none"},
    },
  },
  previewUploaded: {
    maxWidth: 800,
    maxHeight: 600,
    objectFit: "contain",
    width: "100%",
  },
  container: {
    width: "100%",
    maxWidth: 800,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  content: {
    width: "calc(100% - 40px)",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
  submitBtn: {
    borderRadius: "30px",
    padding: "4px 22px",
    color: theme.palette.primary.contrast + "!important",
    backgroundColor: theme.palette.primary.main + "!important",
    boxShadow: "none !important",
    textTransform: "none",
  },
}));

const ViewImage = ({dataUri}) => {
  const classes = useStyles();
  return <img className={classes.previewImg} src={dataUri} />;
};

const CameraView = ({
  dataUri,
  cameraLoading,
  cameraError,
  selectedIDType,
  handleTakePhotoAnimationDone,
  setCameraError,
  handleCameraStart,
}) => {
  const classes = useStyles();
  return (
    <Fragment>
      {!dataUri && (
        <Typography
          style={{marginBottom: 15}}
          variant="h3"
        >{`${VERIFICATION_LABELS[selectedIDType]}`}</Typography>
      )}
      <div>
        {dataUri ? (
          <ViewImage dataUri={dataUri} />
        ) : (
          <div
            className={[
              classes.camera_container,
              cameraLoading || !!cameraError ? "disabled" : "",
            ].join(" ")}
          >
            <Camera
              onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
              idealFacingMode={
                selectedIDType === "selfie"
                  ? FACING_MODES.USER
                  : FACING_MODES.ENVIRONMENT
              }
              imageType={IMAGE_TYPES.JPG}
              isImageMirror={false}
              onCameraError={(err) => setCameraError((prev) => err)}
              onCameraStart={(stream) => {
                handleCameraStart(stream);
              }}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

function CustomIDScanner({components, closePage}) {
  const classes = useStyles();
  const inputRef = useRef(null);
  const [cameraOpen, setCameraOpen] = useState(false);
  const [filePreview, setFilePreview] = useState(false);
  const [file, setFile] = useState(null);
  const [selectedIDType, setSelectedIDType] = useState(false);
  const [dataUri, setDataUri] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [cameraLoading, setCameraLoading] = useState(true);
  const [cameraError, setCameraError] = useState(null);
  let id_upload = components.id_upload ?? {};

  const uploadFailedText = "ID Upload - Failed, please contact host";

  React.useEffect(() => {
    if (!cameraOpen) {
      setCameraError((prev) => null);
    }
  }, [cameraOpen]);

  const openFileExplorer = () => {
    setAnchorEl((prev) => null);
    inputRef.current.value = null;
    inputRef.current.click();
    setSubmitDisabled(false);
  };

  const handleClosePreview = () => {
    setSubmitDisabled(false);
    setFile((prev) => null);
    setFilePreview((prev) => false);
  };

  const handleCloseCamera = () => {
    setSubmitDisabled(false);
    setCameraOpen(false);
  };

  const handleTakePhotoAnimationDone = (dataUri) => {
    if (cameraLoading || dataUri?.length === 6) {
      return;
    }
    setDataUri(dataUri);
  };

  const handleCapture = ({target}) => {
    const reader = new FileReader();
    reader.readAsDataURL(target.files[0]);
    reader.onload = function (e) {
      setFile(e.target.result);
      setFilePreview(true);
    };
  };

  function handleSubmitImage(data) {}

  function handleCameraStart(stream) {
    setCameraLoading((prev) => false);
    if (!!cameraError) {
      setCameraError((prev) => null);
    }
  }

  function handleSelectID(e, id_type) {
    setSelectedIDType(id_type);
    setCameraOpen(true);
    setDataUri("");
  }

  function SelectUpload() {
    return (
      <Fragment>
        <div style={{height: 10}} />
        <Typography className={classes.title} variant="h3">
          {"Upload your ID"}
        </Typography>
        <div style={{height: 20}} />
        <Typography variant="body1" component="div">
          {"Accepted forms of ID"}:
          <ul>
            <li>{"Driver's License"}</li>
            <li>{"Passport"}</li>
            <li>{"Other Government Photo ID"}</li>
          </ul>
        </Typography>
        <div style={{height: 20}} />
        <Popover
          id="desktop-source-select"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          style={{position: "absolute"}}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <List disablePadding>
            <ListItem
              button
              disablePadding
              onClick={(e) => {
                setCameraOpen(true);
                setAnchorEl(null);
              }}
            >
              <ListItemIcon style={{minWidth: 0, marginRight: 15}}>
                <PhotoCameraIcon sx={{color: "primary.main"}} />
              </ListItemIcon>
              <ListItemText>{"Take Photo"}</ListItemText>
            </ListItem>
            <ListItem button disablePadding onClick={openFileExplorer}>
              <ListItemIcon style={{minWidth: 0, marginRight: 15}}>
                <PhotoLibraryIcon sx={{color: "primary.main"}} />
              </ListItemIcon>
              <ListItemText>{"Upload Photo"}</ListItemText>
            </ListItem>
          </List>
        </Popover>
        {id_upload.govt_id_front !== undefined && (
          <VerifyItem
            disabled={Boolean(anchorEl) && selectedIDType === "govt_id_front"}
            verified={id_upload.govt_id_front}
            margin={20}
            component_type="govt_id_front"
            setView={(e) => handleSelectID(e, "govt_id_front")}
          />
        )}
        {id_upload.govt_id_back !== undefined && (
          <VerifyItem
            disabled={Boolean(anchorEl) && selectedIDType === "govt_id_back"}
            verified={id_upload.govt_id_back}
            margin={20}
            component_type="govt_id_back"
            setView={(e) => handleSelectID(e, "govt_id_back")}
          />
        )}
        {id_upload.selfie !== undefined && (
          <VerifyItem
            disabled={Boolean(anchorEl) && selectedIDType === "selfie"}
            verified={id_upload.selfie}
            margin={20}
            component_type="selfie"
            setView={(e) => handleSelectID(e, "selfie")}
          />
        )}
      </Fragment>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <input
          accept="image/*"
          ref={inputRef}
          className={classes.input}
          id="imageInput"
          type="file"
          onChange={handleCapture}
        />
        {cameraOpen ? (
          <div>
            <div style={{marginTop: 30, marginBottom: 10}}>
              <Button
                style={{padding: 0}}
                onClick={handleCloseCamera}
                startIcon={<ArrowBackIosNewIcon style={{marginTop: -8}} />}
              >
                {"Back"}
              </Button>
            </div>
            <CameraView
              dataUri={dataUri}
              cameraLoading={cameraLoading}
              cameraError={cameraError}
              selectedIDType={selectedIDType}
              handleTakePhotoAnimationDone={handleTakePhotoAnimationDone}
              setCameraError={setCameraError}
              handleCameraStart={handleCameraStart}
            />
            {dataUri && (
              <div
                className="footer"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  marginTop: 10,
                }}
              >
                <div style={{width: "100%"}}>
                  <Button
                    onClick={() => handleSubmitImage(dataUri)}
                    fullWidth
                    size="large"
                    variant="contained"
                    disabled={submitDisabled}
                    className={classes.submitBtn}
                  >
                    {submitDisabled ? uploadFailedText : "Submit ID"}
                  </Button>
                </div>
                <Link
                  component="button"
                  style={{marginTop: 10}}
                  onClick={() => {
                    setDataUri(null);
                    setSubmitDisabled(false);
                  }}
                >
                  {"Retake Photo"}
                </Link>
              </div>
            )}
          </div>
        ) : filePreview ? (
          <div>
            <div style={{marginTop: 30, marginBottom: 10}}>
              <Button
                style={{padding: 0}}
                onClick={handleClosePreview}
                startIcon={<ArrowBackIosNewIcon style={{marginTop: -8}} />}
              >
                {"Back"}
              </Button>
            </div>
            <img
              alt="Selected photo"
              src={file}
              className={classes.previewUploaded}
            />
            <div
              className="footer"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                marginTop: 10,
              }}
            >
              <div style={{width: "100%"}}>
                <Button
                  onClick={() => handleSubmitImage(file)}
                  fullWidth
                  size="large"
                  variant="contained"
                  id="submitPhoto"
                  disabled={submitDisabled}
                  style={{borderRadius: 30}}
                >
                  {submitDisabled ? uploadFailedText : "Submit Photo"}
                </Button>
              </div>
              <Link
                component="button"
                style={{marginTop: 10}}
                onClick={openFileExplorer}
              >
                {"Change Photo"}
              </Link>
            </div>
          </div>
        ) : (
          <Fragment>
            <main>
              <Box>
                <div style={{height: 30}} />
                <Button
                  style={{padding: 0}}
                  onClick={closePage}
                  startIcon={<ArrowBackIosNewIcon style={{marginTop: -8}} />}
                >
                  {"Back"}
                </Button>
                <div style={{height: 10}} />
                {SelectUpload()}
              </Box>
            </main>
          </Fragment>
        )}
      </div>
    </div>
  );
}

export default CustomIDScanner;
