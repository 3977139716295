import React, {useState} from "react";
import {useSelector} from "react-redux";
import Panel2 from "core/panels/Panel2.jsx";
import ConnectedEmailsCard from "components/Cards/ConnectedEmailsCard.jsx";
import ConnectedPhonesCardNew from "components/Cards/ConnectedPhonesCardNew.jsx";
import {Typography, makeStyles} from "@material-ui/core";
import CustomCardHeader from "core/cards/CustomCardHeader";
import Panel3 from "core/panels/Panel3";
import ConnectedEmailsAddEditCard from "components/Cards/ConnectedEmailsAddEditCard";
import ConnectedPhonesAddEditCard from "components/Cards/ConnectedPhonesAddEditCard";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "row",
  },
  messagingContent: {
    flex: 1,
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    padding: theme.spacing(4),
  },
  divider: {
    height: 24,
  },
  cardHeader: {padding: theme.spacing(0, 0, 4)},
}));

export default function MessagingSettings() {
  const classes = useStyles();
  const isTabletView =
    useSelector((state) => state.defaultReducer.deviceType) === "tablet";
  const [isPanel3Open, setIsPanel3Open] = useState(true);
  const [panel3type, setPanel3Type] = useState("");
  const emailState = useState("");
  const phoneState = useState("");

  function handlePanel3Open(open, type) {
    setPanel3Type(open ? type : "");
    setIsPanel3Open(open);
  }

  function panel3Content() {
    if (panel3type === "phone") {
      return (
        <ConnectedPhonesAddEditCard
          phoneState={phoneState}
          handlePanel3Open={(open) => handlePanel3Open(open, "phone")}
        />
      );
    }

    if (panel3type === "email") {
      return (
        <ConnectedEmailsAddEditCard
          emailState={emailState}
          handlePanel3Open={(open) => handlePanel3Open(open, "email")}
        />
      );
    }

    return null;
  }

  const panel2 = (
    <div className={classes.messagingContent}>
      <CustomCardHeader title="Messaging" className={classes.cardHeader} />
      <Typography className="mb-3">
        Add in the contact details that you will be using for your accounts
        messaging.
      </Typography>
      <ConnectedEmailsCard
        handlePanel3Open={(open) => handlePanel3Open(open, "email")}
        emailState={emailState}
      />
      <div className={classes.divider} />
      <ConnectedPhonesCardNew
        handlePanel3Open={(open) => handlePanel3Open(open, "phone")}
        phoneState={phoneState}
      />
    </div>
  );

  return (
    <div className={classes.root}>
      <Panel2
        flexAuto
        content={panel2}
        isPanel3Open={
          isTabletView
            ? false
            : isPanel3Open && ["email", "phone"].includes(panel3type)
        }
        hidden={isTabletView && !!panel3type}
      />
      {(!isTabletView ||
        (isPanel3Open && ["email", "phone"].includes(panel3type))) && (
        <Panel3
          content={panel3Content()}
          hidden={!isPanel3Open || !["email", "phone"].includes(panel3type)}
          maintainWidth={!isTabletView}
          disableMaxWidth={isTabletView}
        />
      )}
    </div>
  );
}
