import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
  Card,
  CardActions,
  CardContent,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Typography,
} from "@material-ui/core";
import disabledOverlayImg from "assets/img/disabled_overlay.png";
import dashedCircle from "assets/img/dashed_circle.png";
import emptyCircle from "assets/img/empty_circle.png";
import checkCircle from "assets/img/check_circle.png";
import ButtonLabelLoader from "core/loaders/ButtonLabelLoader";
import PrimaryButton from "core/buttons/PrimaryButton";
import ExpandButton from "core/buttons/ExpandButton";
import CustomMenu from "core/menus/CustomMenu";
import {THEME} from "configuration/settings";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  card: {
    flex: 1,
    maxWidth: 90,
    minWidth: 70,
    minHeight: 52,
    width: "100%",
    borderRadius: 5,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: theme.spacing(2),
    padding: 0,
    border: "1px solid transparent",
    boxShadow: "none",
    cursor: "pointer",
    position: "relative",
    transition: "0.15s",
    "&:hover": {border: `1px solid ${theme.palette.secondary.main}`},
    "&.-disabled": {},
    "&.-selected": {border: `1px solid ${theme.palette.secondary.main}`},
  },
  disabledOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: 52,
    opacity: 0,
    backgroundImage: `url(${disabledOverlayImg})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    "&.-visible": {opacity: 0.03},
  },
  cardContent: {
    padding: theme.spacing(0, 1) + "!important",
    width: "100%",
    height: "fit-content",
  },
  title: {
    fontSize: 10,
    lineHeight: "12px",
    fontWeight: 600,
    textTransform: "uppercase",
    color: THEME.subdued,
    "&.-completed": {color: theme.palette.secondary.main},
    "&.-disabled": {color: "#8C9196"},
  },
  cardActions: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
    padding: theme.spacing(2, 1, 1),
    flexDirection: "column",
    gap: theme.spacing(1),
    zIndex: 1,
  },
  img: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    width: 12,
    height: 12,
  },
  button: {
    width: "100%",
    minWidth: 0,
    padding: theme.spacing(1, 0),
    fontSize: 12,
    lineHeight: "16px",
    fontWeight: 400,
    margin: "0 !important",
    height: 24,
  },
  menuItem: {padding: theme.spacing(2, 4)},
}));

export default function VerificationStepCard({
  label = "",
  disabled = false,
  completed = false,
  dropdown = false,
  buttons = [],
}) {
  const classes = useStyles();
  const [selected, setSelected] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedOptions, setSelectedOptions] = React.useState(null);

  function getCircleIcon(status) {
    switch (status) {
      case "completed":
        return <img src={checkCircle} className={classes.img} />;
      case "disabled":
        return <img src={dashedCircle} className={classes.img} />;
      case "active":
        return <img src={emptyCircle} className={classes.img} />;

      default:
        return null;
    }
  }

  function getMenuContent() {
    if (!selectedOptions) {
      return null;
    }
    return (
      <MenuList>
        {selectedOptions.map((opt) => (
          <MenuItem
            button
            disableRipple
            key={opt.label}
            className={classes.menuItem}
            onClick={handleOptionSelect(opt)}
          >
            {opt.label}
          </MenuItem>
        ))}
      </MenuList>
    );
  }

  const handleOptionSelect = (opt) => () => {
    handleCloseMenu();
    opt.onClick();
  };

  const handleCloseMenu = () => {
    setAnchorEl((prev) => null);
    setSelectedOptions((prev) => null);
  };

  const handleUnselectCard = () => {
    handleCloseMenu();
    setSelected((prev) => false);
  };

  const handleOpenMenu = (options) => (e) => {
    setAnchorEl(e.currentTarget);
    setSelectedOptions((prev) => options);
  };

  const dropdownMenu = !!selectedOptions && (
    <CustomMenu
      overflowAuto
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleCloseMenu}
      content={getMenuContent()}
    />
  );

  return (
    <ClickAwayListener onClickAway={handleUnselectCard}>
      <Card
        className={clsx(classes.card, {
          "-selected": !!selected,
          "-disabled": !!disabled,
        })}
        onClick={() => setSelected((prev) => true)}
      >
        <div
          className={clsx(classes.disabledOverlay, {"-visible": !!disabled})}
        />
        {getCircleIcon(
          disabled ? "disabled" : completed ? "completed" : "active",
        )}
        <CardContent className={classes.cardContent}>
          <Typography
            className={clsx(classes.title, {
              "-completed": !!completed,
              "-disabled": !!disabled,
            })}
          >
            {label}
          </Typography>
        </CardContent>
        {!!selected && !!buttons.length && (
          <CardActions className={classes.cardActions}>
            {dropdownMenu}
            {buttons.map((btn) => {
              if (!!dropdown) {
                return (
                  <React.Fragment key={`${label}-${btn.label}`}>
                    {btn.loading ? (
                      <PrimaryButton
                        key={`loading-${btn.label}`}
                        disabled={btn.disabled}
                        color="secondary"
                        className={classes.button}
                        label={<ButtonLabelLoader variant="white" />}
                      />
                    ) : (
                      <ExpandButton
                        label={btn.label}
                        color="secondary"
                        disabled={btn.disabled}
                        variant="contained"
                        className={classes.button}
                        onClick={handleOpenMenu(btn.options)}
                      />
                    )}
                  </React.Fragment>
                );
              } else {
                return (
                  <PrimaryButton
                    key={`${label}-${btn.label}`}
                    color="secondary"
                    disabled={btn.disabled}
                    onClick={btn.onClick}
                    className={classes.button}
                    label={
                      btn.loading ? (
                        <ButtonLabelLoader variant="white" />
                      ) : (
                        btn.label
                      )
                    }
                  />
                );
              }
            })}
          </CardActions>
        )}
      </Card>
    </ClickAwayListener>
  );
}
