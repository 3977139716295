import React from "react";
import {useDispatch, useSelector} from "react-redux";
// UI
import {Button, Collapse, MenuItem, Paper, Typography} from "@material-ui/core";
import useExperienceActionsStyles from "styles/useExperienceActionsStyles";
// Custom
import CustomFieldDropdown from "components/MultiOption/CustomFieldDropdown";
import CustomCellElement from "components/Helpers/CustomCellElement";
import PrimaryButton from "core/buttons/PrimaryButton";
import FilledSelect from "core/selects/FilledSelect";
import DeleteIconButton from "core/buttons/DeleteIconButton";
import EditIconButton from "core/buttons/EditIconButton";
// Actions
import {getCustomFields} from "redux/actions/settingsActions";
// Utilities
import {
  bookingStatusLabels,
  countries,
  operators,
  satisfaction,
} from "configuration/constants";
import {fieldParams} from "configuration/specs";
import {
  GuestTravelReasonEnum,
  collectFieldTypeEnum,
  recommendedConditions,
} from "configuration/enums";
import {format, isValid} from "date-fns";
import clsx from "clsx";
import _ from "lodash";

const millisecXMinute = 60000;
const operatorOptions = {
  text: ["is", "is_not", "exists", "not_exists"],
  array: ["contains", "not_contains", "exists", "not_exists"],
  number: [
    "is",
    "is_not",
    "is_greater_than",
    "is_less_than",
    "exists",
    "not_exists",
  ],
  time: ["is_greater_than", "is_less_than"],
  boolean: ["is", "is_not", "exists", "not_exists"],
};

export default function CustomCondition({
  data,
  edit,
  disableEdit,
  viewOnly,
  saveBtnProps,
  onEditChange,
  onSave,
  onCancel,
  onDelete,
  index = 0,
  additionalObjectsVariables = [],
}) {
  const dispatch = useDispatch();
  const styleProps = {filled: true};
  const classes = useExperienceActionsStyles(styleProps);
  const userProfile = useSelector((state) => state.defaultReducer.user_profile);
  const [info, setInfo] = React.useState({field: "", operator: "", val: ""});
  const [customFields, setCustomFields] = React.useState({});
  const users = userProfile.enso_users || [];

  const saveDisabled = React.useMemo(() => {
    const isOperatorEmpty = !info.operator;
    const isValueEmpty = ["exists", "not_exists"].includes(info.operator)
      ? false
      : typeof info.val === "string"
        ? !info.val.trim()
        : false;
    const isDateInvalid =
      recommendedConditions[info.field] === "time"
        ? !info.val || !isValid(info.val)
        : false;
    return isValueEmpty || isOperatorEmpty || isDateInvalid;
  }, [info]);

  React.useEffect(() => {
    resetData();
  }, [data]);

  const updateCustomFields = () => {
    let isMounted = true;
    dispatch(
      getCustomFields({
        objects: additionalObjectsVariables,
        onSuccess: (response) =>
          !isMounted ? null : setCustomFields((prev) => response ?? {}),
      }),
    );

    return () => {
      isMounted = false;
    };
  };
  React.useEffect(updateCustomFields, []);

  const resetData = () => {
    const operatorKey = Object.entries(operators).find(
      (op) => op[1].symbol === data.operator,
    )?.[0];
    setInfo((prev) => ({
      field: !!data.field ? data.field : "",
      operator: operatorKey ?? "",
      val: data.val,
    }));
  };

  const handleFieldChange = (val) => {
    let defaultValue = recommendedConditions[val] === "time" ? new Date() : "";
    setInfo((prev) => ({...prev, field: val, operator: "", val: defaultValue}));
  };

  const handleOperatorChange = (e) => {
    const val = e.target.value;
    setInfo((prev) => ({...prev, operator: val}));
  };

  const handleValueChange = (newVal) =>
    setInfo((prev) => ({...prev, val: newVal}));

  const handleSave = () => {
    const newSymbol = operators[info.operator].symbol;
    const newCondition = {
      c_type: "bool",
      field: info.field,
      operator: newSymbol,
      val: newSymbol === "?" ? "" : info.val,
    };
    onSave(newCondition);
    onEditChange(false);
  };

  const handleCancel = () => {
    resetData();
    onCancel();
  };

  const onEdit = () => onEditChange(true);

  function getElapsedTimeLabel(value) {
    const minutes = value / millisecXMinute;
    let num = 0;
    let unit = "";
    if (minutes === 0) {
      num = 0;
      unit = "minutes";
    } else if (minutes % 1440 === 0) {
      num = minutes / 1440;
      unit = `day${num === 1 ? "" : "s"}`;
    } else if (minutes % 60 === 0) {
      num = minutes / 60;
      unit = `hour${num === 1 ? "" : "s"}`;
    } else {
      num = minutes;
      unit = `minute${num === 1 ? "" : "s"}`;
    }

    return `${num} ${unit}`;
  }

  function getMediumText(text, disableMargin = false) {
    return (
      <span className={clsx({"ml-1": !disableMargin}, classes.medium)}>
        {text}
      </span>
    );
  }

  function getFormattedValue(value) {
    switch (info.field) {
      case "checkin_date":
      case "checkout_date":
        return isValid(value) ? format(value, "PP") : "not specified";
      case "checkin_time":
      case "checkout_time":
        return value;
      case "booking_status":
        return (bookingStatusLabels[value] ?? value).toLowerCase();
      case "country":
        return countries[value] ?? value;
      case "guest_country":
        return countries[value] ?? value;
      case "assigned_to":
        return users.find((u) => u.enso_user_id === value)?.name || value;
      case "guest_travel_reason":
        return (GuestTravelReasonEnum[value] || value).toLowerCase();
      case "unanswered_messages":
        return !!value ? "true" : "false";
      case "booking_lead_time":
        return getElapsedTimeLabel(Number(value));
      case "sentiment":
      case "rating":
        return (
          Object.values(satisfaction).find((s) => s.value === value)?.label ||
          value
        ).toLowerCase();
      default:
        return value?.toString() ?? "";
    }
  }

  function getOperatorType(field) {
    let operator = null;
    if (!!fieldParams[field]) {
      operator = fieldParams[field].field_type || fieldParams[field].type;
    } else {
      for (var i = 0, len = Object.keys(customFields)?.length; i <= len; i++) {
        operator = customFields[Object.keys(customFields)[i]]?.find(
          (f) => f.field == field,
        )?.type;
        if (!!operator) {
          break;
        }
      }
    }
    switch (operator) {
      case collectFieldTypeEnum.multi_select:
        return "array";
      case collectFieldTypeEnum.single_select:
        return "text";
      case collectFieldTypeEnum.date:
      case collectFieldTypeEnum.time:
        return "time";
      case collectFieldTypeEnum.number:
        return "number";
      case collectFieldTypeEnum.image:
        return "text";
      case collectFieldTypeEnum.time_offset:
        return "time";
      case "keyword":
        return "text";
      default:
        return operator || "text";
    }
  }

  let operator_type = getOperatorType(info?.field || "");
  return (
    <>
      <Collapse
        in={edit}
        timeout={{appear: 0, enter: 150, exit: 150}}
        onClick={(e) => updateCustomFields()}
      >
        <Paper elevation={0} className={classes.paper}>
          <div className={classes.card}>
            <div className={clsx(classes.row, classes.content)}>
              <div className="selector">
                <CustomFieldDropdown
                  isField
                  selected={info.field}
                  customFields={customFields}
                  onSelect={handleFieldChange}
                />
              </div>
              <div className="selector">
                <FilledSelect
                  fullHeight
                  fullWidth
                  displayEmpty
                  disabled={!info.field}
                  label="Comparison"
                  className={classes.select}
                  value={info.operator}
                  onChange={handleOperatorChange}
                >
                  <MenuItem value="" disabled>
                    Select an operator
                  </MenuItem>
                  {!!info.field &&
                    operatorOptions[operator_type].map((operator) => (
                      <MenuItem key={operator} value={operator}>
                        {operators[operator].label}
                      </MenuItem>
                    ))}
                </FilledSelect>
              </div>
              {!!info.field &&
                !["?", "!"].includes(operators[info.operator]?.symbol) && (
                  <div className="field" style={{flex: 3}}>
                    <CustomCellElement
                      edit
                      enableTagSearch
                      defaultType={operator_type}
                      field={info.field === "sentiment" ? "rating" : info.field}
                      value={info.val}
                      onValueChange={handleValueChange}
                      customFieldData={customFields}
                    />
                  </div>
                )}
            </div>
            <div className={classes.actions}>
              <Button
                className="mr-2"
                classes={{label: classes.cancelBtn}}
                size="small"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <PrimaryButton
                size="small"
                color={saveBtnProps.color}
                label={saveBtnProps.label}
                disabled={saveDisabled || disableEdit}
                className="mr-2"
                onClick={handleSave}
              />
            </div>
          </div>
        </Paper>
      </Collapse>
      <Collapse in={!edit} timeout={{appear: 0, enter: 150, exit: 50}}>
        <Paper elevation={0} className={classes.paper}>
          <div
            className={classes.selectedOption}
            onClick={onEdit}
            style={{cursor: "pointer"}}
          >
            <Typography
              className={clsx(
                classes.title,
                classes.flexGrow,
                classes.ellipsis,
              )}
              component="span"
            >
              {getMediumText(index > 0 ? "and if " : "If ", true)}
              {info.field.split("_").join(" ")}
              {getMediumText(`${operators[info.operator]?.label} `)}
              {getFormattedValue(info.val)}
            </Typography>
            {!viewOnly && (
              <>
                <EditIconButton disablePadding onClick={onEdit} />
                <DeleteIconButton
                  disabled={disableEdit}
                  disablePadding
                  marginLeft={2}
                  onClick={onDelete}
                />
              </>
            )}
          </div>
        </Paper>
      </Collapse>
    </>
  );
}
