import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
// UI
import {Box, Divider, Tooltip, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
// Custom
import ButtonLabelLoader from "core/loaders/ButtonLabelLoader";
import CurrencyText from "components/TextFields/CurrencyText";
import PrimaryButton from "core/buttons/PrimaryButton";
import DotIcon from "core/icons/DotIcon";
// Actions
import {createAction} from "redux/actions/experiencesActions";
import {editBooking} from "redux/actions/bookingsActions";
// Utils
import {capitalize, getDateFromTimeStamp} from "utilities/helperFunctions";
import {THEME} from "configuration/settings.js";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  paymentContainer: {
    padding: theme.spacing(2, 3),
    gap: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  subtitle: {
    fontSize: 9,
    lineHeight: "11px",
    fontWeight: 600,
    color: THEME.text,
    "&.-disabled": {color: "#8C9196"},
  },
  status: {
    lineHeight: "14px",
    fontWeight: 700,
    color: THEME.text,
  },
  timestamp: {
    fontSize: 12,
    lineHeight: "16px",
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: THEME.text,
    "&.-full-width": {width: "100%"},
  },
  bold: {fontWeight: 700},
  divider: {
    backgroundColor: "#F0F0F0",
    width: "100%",
  },
  textDisabled: {
    color: "rgba(60, 60, 67, 0.6)",
    padding: theme.spacing(3),
  },
  button: {
    color: theme.palette.common.white,
    backgroundColor: THEME.text,
    "&:hover": {backgroundColor: THEME.text + "cc"},
  },
  taxText: {
    lineHeight: "16px",
    cursor: "pointer",
  },
  priceSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
  },
}));

export default function Payment({
  booking,
  bill,
  sku,
  metadata,
  updateBill,
  hideSymbol = false,
  actionDisabled,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [actionLoading, setActionLoading] = React.useState(null);
  const isBill = !!bill;
  const isProduct = !!sku;
  const hasMetadata =
    !!metadata?.stripe_customer_id || !!metadata?.stripe_payment_id;

  useEffect(() => {
    if (!booking || !booking.payment) return;
  }, [booking]);

  const getLine = (text, value, currency, helperText) => {
    return (
      <div key={text} className={clsx(classes.row, "-full-width")}>
        <Typography variant="caption">{text}</Typography>
        <div className={classes.priceSection}>
          <CurrencyText
            hideSymbol={hideSymbol}
            disableFixedDecimal={Number.isInteger(value)}
            value={value}
            currency={currency}
            sm
          />
          {!!helperText && helperText}
        </div>
      </div>
    );
  };

  function getRows(array, currency) {
    if (!array.length) {
      return null;
    }
    return array.map((element) => {
      const key = element.description ?? element.name;
      return getLine(key, element.value, currency);
    });
  }

  function getTaxHelperText() {
    return (
      <Tooltip
        arrow
        title={
          "Taxes are collected automatically through your Stripe Account. Click to learn how to set up taxes."
        }
      >
        <Typography
          className={classes.taxText}
          onClick={() => window.open("https://stripe.com/docs/tax/set-up")}
        >
          {"+ tax"}
        </Typography>
      </Tooltip>
    );
  }

  function handleBillingAction(action) {
    setActionLoading((prev) => action.action_id);
    dispatch(
      createAction({
        bookingId: bill.booking_id,
        guestId: bill.guest_id,
        action,
        onSuccess: (r) => {
          setActionLoading((prev) => null);
          if (!!r.guest_bill) {
            updateBill(r.guest_bill);
          }
          if (!!r.booking) {
            dispatch(editBooking(r.booking));
          }
        },
        onError: () => setActionLoading((prev) => null),
      }),
    );
  }

  function getActionButton() {
    let action = null;
    if (["deposit", "flex"].includes(bill.product_type)) {
      if (!bill.properties.deposit_collected && bill.status === "on_hold") {
        action = {
          label: "Collect Deposit",
          action_id: "collect_deposit",
          confirm: true,
          sku_id: bill.sku_id,
        };
        return (
          <PrimaryButton
            sm
            disabled={actionDisabled}
            label={
              actionLoading === action.action_id ? (
                <ButtonLabelLoader variant="white" />
              ) : (
                action.label
              )
            }
            className={classes.button}
            onClick={() => handleBillingAction(action)}
          />
        );
      }
    } else if (bill.status === "paid" && bill.total > 0) {
      action = {
        label: "Refund",
        action_id: "refund",
        sku_id: bill.sku_id,
      };
      return (
        <PrimaryButton
          sm
          disabled={actionDisabled}
          label={
            actionLoading === action.action_id ? (
              <ButtonLabelLoader variant="white" />
            ) : (
              action.label
            )
          }
          className={classes.button}
          onClick={() => handleBillingAction(action)}
        />
      );
    } else {
      return null;
    }
  }

  function getPaymentContent() {
    if (isBill) {
      return (
        <>
          <div className={clsx(classes.row, "-full-width")}>
            <div className={classes.row} style={{gap: THEME.spacing.sm}}>
              <Typography className={classes.status} variant="subtitle1">
                {capitalize(bill.status, "_").toUpperCase()}
              </Typography>
              <DotIcon disableGutters />
              <Typography className={classes.timestamp}>
                {getDateFromTimeStamp(bill.created_at).full}
              </Typography>
            </div>
            {getActionButton()}
          </div>
          {getRows(bill.items, bill.currency)}
          <Divider className={classes.divider} />
          <div className={clsx(classes.row, "-full-width")}>
            <Typography variant="caption" className={clsx(classes.bold)}>
              {"Total payout"}
            </Typography>
            <CurrencyText
              hideSymbol={hideSymbol}
              disableFixedDecimal={Number.isInteger(bill.total)}
              value={bill.total}
              currency={bill.currency}
              bold
              sm
            />
          </div>
        </>
      );
    } else if (isProduct) {
      return (
        <>
          {getLine("Guest pays", sku.value, sku.currency, getTaxHelperText())}
          {sku.flex_product_id === "fee" &&
            getLine("Enso Processing Fee", sku.value * 0.07, sku.currency)}
          {!sku.is_fee &&
            getLine("Enso Processing Fee", sku.value * 0.05, sku.currency)}
          {getLine("Stripe Fee Estimate", sku.value * -0.03, sku.currency)}
          <Divider className={classes.divider} />
          <div className={clsx(classes.row, "-full-width")}>
            <Typography variant="caption" className={clsx(classes.bold)}>
              {"Your payout"}
            </Typography>
            <CurrencyText
              hideSymbol={hideSymbol}
              disableFixedDecimal={Number.isInteger(sku.value * 0.97)}
              value={sku.value * 0.97}
              currency={sku.currency}
              bold
              sm
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <Box mb={3}>
            <Typography variant="subtitle2">Payment Summary</Typography>
          </Box>
          <div className={clsx(classes.row, "-full-width")}>
            <Box display="flex" style={{gap: THEME.spacing.xs}}>
              <CurrencyText
                hideSymbol={hideSymbol}
                disableFixedDecimal={Number.isInteger(
                  booking.payment.nightly_rate,
                )}
                value={booking.payment.nightly_rate}
                currency={booking.payment.currency}
                noSuffix
                sm
              />
              <Typography variant="caption">
                {`x ${booking.num_nights} night${booking.num_nights > 1 ? "s" : ""}`}
              </Typography>
            </Box>
            <CurrencyText
              hideSymbol={hideSymbol}
              disableFixedDecimal={Number.isInteger(
                booking.payment.nightly_rate * booking.num_nights,
              )}
              value={booking.payment.nightly_rate * booking.num_nights}
              currency={booking.payment.currency}
              sm
            />
          </div>
          {getRows(booking.payment.fees, booking.payment.currency)}
          {getRows(booking.payment.taxes, booking.payment.currency)}
          {getRows(booking.payment.commission_fees, booking.payment.currency)}
          <Divider className={classes.divider} />
          <div className={clsx(classes.row, "-full-width")}>
            <Typography variant="caption" className={clsx(classes.bold)}>
              Total
            </Typography>
            <CurrencyText
              hideSymbol={hideSymbol}
              disableFixedDecimal={Number.isInteger(
                booking.payment.total_guest_paid,
              )}
              value={booking.payment.total_guest_paid}
              currency={booking.payment.currency}
              bold
              sm
            />
          </div>
        </>
      );
    }
  }

  if (!isBill && !isProduct && (!booking || !booking.payment)) {
    return (
      <Typography variant="h1" className={classes.textDisabled} align="center">
        {"No payment info"}
      </Typography>
    );
  }

  return (
    <div className={classes.paymentContainer}>
      {getPaymentContent()}
      {!!hasMetadata && (
        <>
          <Divider className={classes.divider} />
          <div className={clsx(classes.row, "-full-width")}>
            {!!metadata?.stripe_payment_id && (
              <Box width="50%">
                <Typography className={clsx(classes.subtitle, "-disabled")}>
                  {"STRIPE PRODUCT ID"}
                </Typography>
                <Typography className={clsx(classes.subtitle, "-disabled")}>
                  {metadata.stripe_payment_id}
                </Typography>
              </Box>
            )}
            {!!metadata?.stripe_customer_id && (
              <Box width="50%">
                <Typography className={clsx(classes.subtitle, "-disabled")}>
                  {"STRIPE CLIENT ID"}
                </Typography>
                <Typography className={clsx(classes.subtitle, "-disabled")}>
                  {metadata.stripe_customer_id}
                </Typography>
              </Box>
            )}
          </div>
        </>
      )}
    </div>
  );
}
